<template>
  <div :id="event.id" style="display: flex">
    <div
      class="shine"
      :class="{
        red: checkUrgent,
        yellow: !checkUrgent && event.matched === 1,
        gray: event.matched === 0
      }"
    ></div>
    <div
      class="eventcard-wrap"
      :class="{
        'active-red': chosen && checkUrgent,
        'active-yellow': chosen && !checkUrgent
      }"
      @click.left="openDetailCard"
      @click.right="handlePopUpMenu($event)"
    >
      <div v-if="isSosEvent" class="container-img">
        <div class="snapshot">
          <img v-lazy="sosSnapshotObj">
        </div>
      </div>
      <div v-else class="container-img">
        <div class="photo">
          <img v-lazy="event.photoUrl">
        </div>
        <div class="snapshot">
          <img v-lazy="event.snapshotUrl">
          <img
            v-if="event.channelId === 0"
            class="camera"
            src="@/assets/icons/camera1-m.svg"
            alt=""
          />
          <img
            v-else-if="event.channelId === 1"
            class="camera"
            src="@/assets/icons/camera2-m.svg"
            alt=""
          />
        </div>
      </div>
      <div class="container-info">
        <div v-if="isSosChasing" class="sos-chasing">
          <img src="@/assets/icons/chasing.svg" alt="" />
          <div v-if="isSosEvent" class="sos-chasing-title">SOS</div>
          <div v-else-if="event.chasing === 1" class="sos-chasing-title">
            {{ $t('event_chasing') }}
          </div>
        </div>
        <div v-if="isSosEvent">
          <div class="time" :class="{ active: chosen }">
            <div>{{ sosStartTime }}</div>
          </div>
          <div class="info">
            <div class="icon">
              <img src="@/assets/icons/Camera.svg" alt="" />
            </div>
            {{ getEventTitleId(event.userAccount) }}
          </div>
          <div v-if="event.linkedUserName" class="info">
            <div class="icon">
              <img src="@/assets/icons/user.svg" alt="" />
            </div>
            {{ `${event.linkedUserName}(${event.linkedUserAccount})` }}
          </div>
        </div>
        <template v-else>
          <div class="plate">
            <div class="plate-icon">
              <img
                v-if="event.triggered[0].classification === 3"
                src="@/assets/icons/Car_L.svg"
                alt=""
              />
              <img
                v-else-if="event.triggered[0].classification === 4"
                src="@/assets/icons/Motor_L.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/CarPlate.svg" alt="" />
            </div>
            <div class="plate-code">{{ event.triggered[0].content }}</div>
          </div>
          <div class="time" :class="{ active: chosen }">
            <div>{{ detectTime }}</div>
          </div>
          <div class="info video-title">
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="$t('history_device') /*辨識設備*/"
              placement="left"
            >
              <img src="@/assets/icons/Camera.svg" alt="" />
            </el-tooltip>
            <el-tooltip
              popper-class="el-tooltip"
              :disabled="!isShowTooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="getEventTitleId(eventUserId)"
              placement="left"
            >
              <span ref="videoTitle" @mouseover="onMouseOver('videoTitle')">{{
                getEventTitleId(eventUserId)
              }}</span>
            </el-tooltip>
          </div>
          <div
            v-if="event.triggered && event.triggered[0].tag.length > 0"
            class="info tag"
          >
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="$t('history_tag') /*車輛標記*/"
              placement="left"
            >
              <img src="@/assets/icons/tag-s.svg" alt="" />
            </el-tooltip>
            <el-tooltip
              popper-class="el-tooltip"
              :disabled="!isShowTooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="getTag(event.triggered[0].tag)"
              placement="bottom"
            >
              <div ref="tags" class="tags" @mousemove="onMouseOver('tags')">
                <span
                  v-for="(tag, index) in getTagNameList(event.triggered[0].tag)"
                  :key="index"
                  >{{ tag }}</span
                >
              </div>
            </el-tooltip>
          </div>
          <div v-else-if="isDemo" class="info tag">
            <el-tooltip
              popper-class="el-tooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="$t('history_tag') /*車輛標記*/"
              placement="left"
            >
              <img src="@/assets/icons/tag-s.svg" alt="" />
            </el-tooltip>
            <el-tooltip
              popper-class="el-tooltip"
              :disabled="!isShowTooltip"
              effect="dark"
              v-delTabIndex
              :visible-arrow="false"
              :content="getTag(event.triggered[0].tag)"
              placement="bottom"
            >
              <div ref="tags" class="tags" @mousemove="onMouseOver('tags')">
                <span>{{ $t('no_subscription') }}</span>
              </div>
            </el-tooltip>
          </div>
          <div
            v-if="checkUrgent || (codeBooks.mission && event.missionCode > 0)"
            class="separater"
          ></div>
          <div v-if="checkUrgent" class="info urgent">
            <img src="@/assets/icons/urgent-s.svg" alt="" />
            {{ $t('event_chasing') /*圍捕*/ }}
          </div>
          <div
            v-if="codeBooks.mission && event.missionCode > 0"
            class="push-message"
          >
            <div class="message">
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :visible-arrow="false"
                :content="$t('history_mission') /*任務*/"
                placement="left"
              >
                <img :src="missionIcon" alt="" />
              </el-tooltip>
              {{ codeBooks.mission[event.missionCode]
              }}<span v-if="event.missioned === 3">{{
                $t('finish') /*完成*/
              }}</span>
            </div>

            <div class="info push-content">
              <el-tooltip
                popper-class="el-tooltip"
                effect="dark"
                v-delTabIndex
                :visible-arrow="false"
                :content="$t('history_push_message') /*推送訊息*/"
                placement="left"
              >
                <img src="@/assets/icons/note.svg" alt="" />
              </el-tooltip>
              <el-tooltip
                popper-class="el-tooltip"
                :disabled="!isShowTooltip"
                effect="dark"
                v-delTabIndex
                :visible-arrow="false"
                :content="event.missionNotice"
                placement="left"
              >
                <span
                  ref="missionNotice"
                  @mouseover="onMouseOver('missionNotice')"
                  >{{ event.missionNotice }}</span
                >
              </el-tooltip>
            </div>
          </div>
        </template>
      </div>
      <div class="container-action">
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('more') /*更多*/"
          placement="bottom"
        >
          <div>
            <i
              class="el-icon-more info-icon"
              @click="handlePopUpMenu($event)"
            />
          </div>
        </el-tooltip>
        <el-tooltip
          popper-class="el-tooltip"
          effect="dark"
          v-delTabIndex
          :visible-arrow="false"
          :content="$t('search_misjudgment') /*誤判*/"
          placement="top"
        >
          <div
            v-if="!isSosEvent"
            class="mis-judgment"
            @click.stop="setEventMisjudged(event)"
          >
            <img
              v-if="event.misjudged === 0"
              src="@/assets/icons/misjudged-disable.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/misjudged-yellow.svg" alt="" />
          </div>
        </el-tooltip>
      </div>
      <div class="pop-up-menu" v-if="popupOpen">
        <div
          class="option location"
          @click="showLocation($event)"
          @click.right="preventRightClickClose($event)"
        >
          <div><img src="@/assets/icons/userAccount.svg" alt="" /></div>
          <div>{{ $t('event_show_position') }}</div>
        </div>
        <div
          v-if="permissionV2.lprEventAccess > 0"
          class="option historyRecord"
          @click="showHistoryRecord($event)"
          @click.right="preventRightClickClose($event)"
        >
          <div><img src="@/assets/icons/clock.svg" alt="" /></div>
          <div>{{ $t('evnet_show_history') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { apiEditBoviaLprEvent } from '@/api/index.js'

export default {
  name: 'EventCard',
  data() {
    return {
      popupOpen: false,
      chosen: false,
      clicks: 0,
      clickTimer: null,
      isShowTooltip: false
    }
  },
  props: {
    event: Object
  },
  created() {
    this.$bus.$on('closePopUpMenu', () => {
      this.popupOpen = false
    })
  },
  mounted() {
    this.checkCardChosenSts()
  },
  beforeDestroy() {
    this.$bus.$off('closePopUpMenu')
  },
  computed: {
    ...mapState([
      'isDemo',
      'userList',
      'tagList',
      'selectedUsers',
      'shining',
      'eventCardDetailObj',
      'RightMenuOpen',
      'liveList',
      'codeBooks',
      'permissionV2'
    ]),
    ...mapGetters(['getEventTitleId', 'timezone']),
    isSosEvent() {
      return this.event.uid.startsWith('sos')
    },
    isSosChasing() {
      return this.isSosEvent || this.event.chasing === 1
    },
    checkUrgent() {
      return this.event.chasing == 1 ||
        this.isSosEvent ||
        (this.event.chasingStartTime && this.event.chasingEndTime)
        ? true
        : false
    },
    shiningSts() {
      if (this.event.chasing == 1) {
        if (this.event.chasingStartTime && this.event.chasingEndTime) {
          return false
        }
        return this.shining
      }
      return false
    },
    missionIcon() {
      if (this.event.missionCode === 1)
        return require('@/assets/icons/SearchCar_S.svg')
      else if (this.event.missionCode === 2)
        return require('@/assets/icons/Ask_S.svg')
      else if (this.event.missionCode === 3)
        return require('@/assets/icons/Arrest_S.svg')
      else return require('@/assets/icons/SearchCar_S.svg')
    },
    bSelected() {
      return this.$store.state.selectedEvent.id === this.event.id
    },
    detectTime() {
      return moment(this.event.detectTime)
        .tz(this.timezone)
        .format('YYYY-MM-DD HH:mm:ss')
    },
    sosStartTime() {
      return moment(this.event.startTime)
        .tz(this.timezone)
        .format('YYYY-MM-DD HH:mm:ss')
    },
    sosLiveSnapshotUrl() {
      // SOS若有Live，則有預覽圖片可以顯示
      if (this.event.uid.startsWith('sos')) {
        const live = this.liveList.find(
          (live) => live.id === this.event.userAccount
        )
        return live ? live.snapshotUrl : require('@/assets/icons/no-image.svg')
      } else return require('@/assets/icons/no-image.svg')
    },
    sosSnapshotObj() {
      return {
        src: this.event.snapshotUrl,
        error: this.sosLiveSnapshotUrl
      }
    },
    eventUserId() {
      return this.isSosEvent ? this.event.userAccount : this.event.user.id
    }
  },
  watch: {
    eventCardDetailObj() {
      if (!('user' in this.eventCardDetailObj)) {
        this.chosen = false
      }
      if (
        this.event.detectTime == this.eventCardDetailObj.detectTime &&
        this.event.id == this.eventCardDetailObj.id
      ) {
        this.chosen = true
        const el = document.getElementById(this.event.id)
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        this.chosen = false
      }
    }
  },
  methods: {
    ...mapMutations('history', ['updateEventPlate']),
    checkCardChosenSts() {
      // mounted 確認有沒有選中的event 有就讓那張卡呈現選中的樣式
      // 打開detail modal：bus emit 先讓所有eventcard回到初始狀態 再選中特定的卡
      // 關掉detail modal：bus emit 讓所有eventcard回到初始狀態
      if (Object.keys(this.eventCardDetailObj).length == 0) {
        this.chosen = false
        return
      }
      if (this.eventCardDetailObj.sos > 0) {
        // 判斷SOS事件
        this.chosen =
          this.event.uid.startsWith('sos') &&
          this.eventCardDetailObj.user.id == this.event.userAccount
            ? true
            : false
      } else {
        this.chosen =
          this.eventCardDetailObj.id === this.event.id ? true : false
      }
    },
    preventRightClickClose(e) {
      e.stopPropagation()
    },
    showLocation(e) {
      //console.log(e)
      e.stopPropagation()
      this.popupOpen = false
      //console.log(this.event.user.id)
      let element = document.getElementById(this.eventUserId)
      element.scrollIntoView({ behavior: 'smooth' })
      // 顯示帳號位置 要同步把左邊的該user也勾選起來
      let idx = this.selectedUsers.findIndex(
        (user) => user.id == this.eventUserId
      )
      if (idx >= 0) this.$store.commit('setTrackUser', this.selectedUsers[idx])
    },
    showHistoryRecord(e) {
      e.stopPropagation()
      this.popupOpen = false
      this.updateEventPlate(this.event.triggered[0].content)
      this.$router.push('/history')
    },
    handlePopUpMenu(e) {
      e.stopPropagation()
      e.preventDefault()
      this.$bus.$emit('closePopUpMenu')
      this.popupOpen = !this.popupOpen
    },
    openDetailCard() {
      this.clicks++
      if (this.clicks === 1) {
        // 2秒後重設 clicks = 0, 避免使用者短時間快速點擊
        this.clickTimer = setTimeout(() => {
          this.clicks = 0
        }, 2000)

        // 打開跳窗
        // 新舊事件跳窗型態不同 就要判斷哪個開哪個關 相同就單純更新eventDetailObj和liveVideoUrl即可
        if (this.event.chasing == 1 || this.isSosEvent) {
          this.syncUserTree()
          this.$store.commit('updateSingleUrlUserID', this.eventUserId)
        }
        this.$bus.$emit('closePopUpMenu')
        this.$store.dispatch('handleEventModal', this.event)

        if (this.event.chasing == 1 || this.isSosEvent) {
          this.$bus.$emit(
            'sosCall',
            'group',
            this.eventUserId,
            this.event.groupId
          )
        }
      }
    },
    syncUserTree() {
      // 點擊right bar追車事件時 要同步把左邊的該user也勾選起來
      let idx = this.selectedUsers.findIndex(
        (user) => user.id == this.eventUserId
      )
      if (idx >= 0) this.$store.commit('setTrackUser', this.selectedUsers[idx])
      else {
        // 點擊未勾選裝置的追車事件，自動將其勾選(加入selectedUsers --> reloadGroupUserTree)，並將其設為trackUser
        let clickUser = this.userList.find(
          (item) => item.id === this.eventUserId
        )
        if (clickUser) {
          // 須將資料處理成與selectedUser, trackUser一樣的格式
          let user = {
            id: clickUser.id,
            name: clickUser.video.title,
            groupId: clickUser.groupId,
            label: clickUser.video.title + ' (' + clickUser.id + ')'
          }
          this.$store.commit('addSelectedUser', user)
          this.$store.commit('setTrackUser', user)
        }
      }
      this.$bus.$emit('panToTrackUser') // 地圖移動到TrackUser最新的位置
    },
    clickEvent() {
      this.$store.commit('updateSelectedEvent', this.event)
      if (this.event.chasing == 1) {
        this.$store.state.showVideoStatus = true
      } else {
        // 跳窗
        this.$store.state.showVideoStatus = false
        this.$store.state.showEventDetail = true
      }
    },
    getTag(arrTag) {
      let tags = ''
      arrTag.forEach((item) => {
        let idx = this.tagList.findIndex((obj) => obj.id === item)
        if (idx !== -1) {
          let seperator = tags == '' ? '' : ' / '
          tags += seperator + this.tagList[idx].name
        }
      })
      return tags
    },
    getTagNameList(arrTag) {
      return arrTag.map((item) => {
        let objTag = this.tagList.find((obj) => obj.id === item)
        return objTag ? objTag.name : ''
      })
    },
    async setEventMisjudged(event) {
      let data = {
        event: [{ id: event.id }],
        misjudged: event.misjudged === 1 ? 0 : 1
      }
      let res = await apiEditBoviaLprEvent(data)
      if (res.status === 204) {
        event.misjudged = event.misjudged === 1 ? 0 : 1
      }
    },
    onMouseOver(str) {
      const tag = this.$refs[str]
      const contentWidth = tag.offsetWidth
      const contentHeight = tag.offsetHeight
      const scrollWidth = tag.scrollWidth
      const scrollHeight = tag.scrollHeight
      this.isShowTooltip =
        contentWidth < scrollWidth || contentHeight < scrollHeight
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.eventcard-wrap {
  display: flex;
  width: 363px;
  background-color: #19223b;
  border: 1px solid #4a5c78;
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  padding: 8px 0px 16px 8px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  cursor: pointer;
  position: relative;
}

.active-yellow {
  background-color: #384c83;
  border-color: #ffc600;
  border-width: 3px 3px 3px 0;
}

.active-red {
  background-color: #426393;
  border-color: #f94144;
  border-width: 3px 3px 3px 0;
}

.eventcard-wrap.active {
  background-color: #426393;
}

.eventcard-wrap:first-child {
  margin-top: 20px;
}

.shine {
  border-left: 10px solid #4a5c78;
  margin-bottom: 10px;
  border-radius: 5px 0px 0px 5px;
  visibility: visible;
}

.red {
  border-left: 10px solid #f94144;
}

.yellow {
  border-left: 10px solid #ffc600;
}

.gray {
  border-left: 10px solid #4a5c78;
}

.none {
  border-left: 10px solid #f9414466;
}

.photo,
.snapshot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  border-radius: 3px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
  img[lazy=error] {
    width: 60%;
    height: 60%;
  }
}

.photo {
  width: 105px;
  height: 105px;
  margin-bottom: 8px;
  
}

.snapshot {
  width: 105px;
  height: 70px;
  position: relative;
}

.snapshot > .camera {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 28px;
  height: 28px;
}

.container-info {
  width: calc(100% - 136px);
  padding: 0 4px 0 8px;
}

.sos-chasing {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f94144;
  border: 4px solid #f94144;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 3px 0;
  color: #ffffff;
  animation: bgchange 1.5s infinite;
}

.sos-chasing img {
  margin-right: 8px;
}

.sos-chasing-title {
  font-size: 34px;
  line-height: 46px;
}

@keyframes bgchange {
  0% {
    background-color: #f94144;
  }
  50% {
    background-color: #f9414433;
  }
  100% {
    background-color: #f94144;
  }
}

.plate {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.plate-code {
  font-size: px2rem(24);
  font-weight: 400;
  line-height: 37px;
  color: #ffe99f;
}

.plate-icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.time {
  display: inline-block;
  background: #4a5c78;
  border-radius: 17px;
  padding: 4px 16px 5px;
  font-size: px2rem(18);
  line-height: 24px;
  margin-bottom: 8px;
}

.time.active {
  background: #282942;
}

.info {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.info img {
  margin-right: 8px;
}

.video-title {
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 8px;
}

.video-title span {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.separater {
  width: 100%;
  height: 1px;
  background: #ffffff33;
  margin: 6px 0;
}

.urgent {
  left: -2px;
}

.tags {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.tags span {
  background: #ffffff33;
  padding: 1px 12px 2px 12px;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
  font-size: 20px;
  white-space: nowrap;
}

.push-message {
  margin-bottom: 4px;
  font-size: 20px;
  letter-spacing: 1.5px;
  color: #ffffff;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.message img {
  margin-right: 8px;
  vertical-align: bottom;
}

.push-content {
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  word-break: break-all;
}

.push-content img {
  position: relative;
  margin-right: 8px;
  top: 3px;
}

.push-content span {
  /* width: 100%; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.container-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mis-judgment {
  opacity: 0.7;
}

.mis-judgment img {
  vertical-align: middle;
}

.pop-up-menu {
  background-color: #224974;
  padding: 2px 5px 2px 1px;
  gap: 5px;
  box-sizing: border-box;
  border-radius: 3px;
  position: absolute;
  top: 13px;
  right: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.option {
  display: flex;
  gap: 10px;
  line-height: 20px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.8px;
  height: 50%;
  align-items: center;
  box-sizing: border-box;
  padding-left: 5px;
}

.option img {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.location:hover {
  background-color: #577590;
  border-radius: 3px 3px 0px 0px;
}

.historyRecord:hover {
  background-color: #577590;
  border-radius: 0px 0px 3px 3px;
}

.note {
  line-height: 24px;
}

.info-icon {
  transform: rotate(90deg);
  color: #ffffff;
  cursor: pointer;
}
</style>
