<template>
  <div class="eventboard-wrap">
    <div class="navbar">
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: tab === 'All' }"
          @click="changeTab('All')"
        >
          <img src="@/assets/icons/all-s.svg" alt="" />
          <span>{{ $t('event_tab_all') }}</span>
        </div>
        <div
          class="tab"
          :class="{ active: tab === 'Match' }"
          @click="changeTab('Match')"
        >
          <img src="@/assets/icons/db_s.svg" alt="" />
          <span>{{ $t('event_tab_match') }}</span>
        </div>
        <div
          class="tab"
          :class="{ active: tab === 'Urgent' }"
          @click="changeTab('Urgent')"
        >
          <img src="@/assets/icons/urgent-s.svg" alt="" />
          <span>{{ $t('event_tab_urgent') }}</span>
        </div>
      </div>
      <div class="icons">
        <div class="icon">
          <!-- <img src="@/assets/icons/filter.svg" alt=""> -->
        </div>
        <div class="icon" @click="handleRightMenu">
          <img src="@/assets/icons/double-right.svg" alt="" />
        </div>
      </div>
    </div>

    <ToggleSwitch
      v-if="permissionV2.lprDashboard === 3"
      class="event-switch"
      :suffix="$t('event_switch') /*顯示所有事件*/"
      v-model="showNotDbEvts"
    />
    <div ref="contentEvents" class="content">
      <lazy-component v-for="event in filterEventList" :key="event.uid">
        <FaceCard
          v-if="event.uid.startsWith('fr')"
          :event="event"
          :key="event.uid"
        />
        <EventCard v-else :key="event.uid" :event="event" />
      </lazy-component>
    </div>
    <audio ref="audio" hidden controls src="@/assets/sound/alert.mp3"></audio>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import EventCard from './EventBoard/EventCard'
import FaceCard from './EventBoard/FaceCard'
import ToggleSwitch from '@/components/Base/ToggleSwitch'

export default {
  name: 'EventBoard',
  components: { EventCard, FaceCard, ToggleSwitch },
  data() {
    return {
      tab: 'All',
      alertCount: 0
    }
  },
  computed: {
    ...mapState([
      'RightMenuOpen',
      'selectedUsers',
      'shining',
      'shineTimeout',
      'sosConnectionList',
      'lockDeviceId',
      'permissionV2',
      'showNotDbEvents',
      'sosEventList'
    ]),
    ...mapGetters(['displayEventList']),
    showNotDbEvts: {
      get() {
        return this.showNotDbEvents
      },
      set(value) {
        this.updateShowNotDbEvents(value)
      }
    },
    filterEventList() {
      let events = []
      if (this.tab === 'All') {
        events = this.allEvents
      } else if (this.tab === 'Urgent') {
        events = this.urgentEvents
      } else {
        events = this.matchedEvents
      }
      this.updateFilteredDisplayEvents(events)
      return events
    },
    chasingEvents() {
      let evts = this.allEvents.filter((event) => event.chasing == 1)
      return evts
    },
    urgentEvents() {
      // SOS事件 ＋ 正在追車事件 + 追車結束事件
      let evts = this.allEvents.filter(
        (event) =>
          event.chasing == 1 || (event.chasingStartTime && event.chasingEndTime)
      )
      return this.sosEvents.concat(evts)
    },
    matchedEvents() {
      return this.allEvents.filter(
        (event) =>
          (event.uid.startsWith('lpr') &&
            event.matched == 1 &&
            event.chasing == 0 &&
            !event.chasingEndTime) ||
          (event.uid.startsWith('fr') && event.triggered.length > 0)
      )
    },
    allEvents() {
      let tmp = []
      // 如果lockDeviceId不為空值，表示在single video有按下過濾事件的按鈕，則只顯示該裝置的事件，SOS, chasing事件仍舊置頂
      if (this.lockDeviceId) {
        this.displayEventList.forEach((event) => {
          if (event.chasing === 1 || event.user.id === this.lockDeviceId)
            tmp.push(event)
        })
      } else {
        this.displayEventList.forEach((event) => {
          // 若是showNotDbEvents=true，matched = 0的事件要顯示;反之，若是showNotDbEvents=false，只顯示matched = 1的事件
          if (this.showNotDbEvents) tmp.push(event)
          else if (event.matched === 1) tmp.push(event)
          else if (event.uid.startsWith('fr') && event.triggered.length > 0)
            tmp.push(event)
        })
      }
      return this.sosEvents.concat(tmp)
    },
    sosEvents() {
      // connections API 中 SOS 欄位 只要 > 0, 就表示有 SOS 事件 (>0 的數字是 SOS 的事件 id)
      // 將sosConnectionList的資料模擬成eventList，僅取其中少數欄位資料作為顯示用
      // let tmp = []
      // this.sosConnectionList.forEach((conn) => {
      //   let eventData = {
      //     sos: conn.sos,
      //     detectTime: conn.updatedTime,
      //     user: { id: conn.userAccount },
      //     groupId: conn.groupID,
      //     uid: `lpr-${ new Date(conn.updatedTime).getTime() }`
      //   }
      //   tmp.push(eventData)
      // })
      // return tmp
      return this.sosEventList
    }
  },
  watch: {
    chasingEvents() {
      // console.log('this.allEvents變惹')
      if (this.chasingEvents.length) {
        // console.log('allEvents 裡面有追車事件')
        if (this.shineTimeout == null) {
          // console.log('因為沒有監聽事件 所以要開始設setTimeout')
          this.shine()
        }
      } else {
        // console.log('allEvents 裡面沒有追車事件')
        if (this.shineTimeout != null) {
          // console.log('因為有監聽事件 所以要取消 然後把timeout設回null')
          clearTimeout(this.shineTimeout)
          this.$store.commit('updateShining', false)
          this.$store.commit('updateShineTimeout', null)
        }
      }
    },
    'chasingEvents.length'(newValue, oldValue) {
      if (newValue > oldValue) {
        this.tab = 'All'
        this.alertCount = 0
        this.alertSound()
        this.$refs.contentEvents.scrollTop = 0
      }
    },
    'sosEvents.length'(newValue, oldValue) {
      if (newValue > oldValue) {
        this.tab = 'All'
        this.alertCount = 0
        this.alertSound()
        this.$refs.contentEvents.scrollTop = 0
      }
    },
    'displayEventList.length'() {
      console.log(
        `[EventBoard.W] displayEventList:`,
        this.displayEventList.length
      )
    }
  },
  mounted() {
    // 閃燈
    // mounted時立即確認有沒有追車 有就開始閃
    // destroy時如果還在閃 就clearTimeout
    // 同時監聽每五秒變動的allEvents 一有或沒有追車事件 就改變閃燈狀態
    if (this.chasingEvents.length) {
      this.shine()
    }

    // 關閉右側事件列表下發生SOS事件時，事件列表會自動展開，也要同步播放音效
    if (this.chasingEvents.length > 0 || this.sosEvents.length > 0) {
      this.alertSound()
    }
  },
  beforeDestroy() {
    if (this.shineTimeout != null) {
      clearTimeout(this.shineTimeout)
      this.$store.commit('updateShining', false)
      this.$store.commit('updateShineTimeout', null)
    }

    // 關閉音效
    this.$refs.audio.pause()
    this.$refs.audio.currentTime = 0
  },
  methods: {
    ...mapMutations(['updateShowNotDbEvents', 'updateFilteredDisplayEvents']),
    shine() {
      // console.log('shine function')
      let timeout = setTimeout(() => {
        this.shine()
      }, 500)
      this.$store.commit('updateShining', !this.shining)
      this.$store.commit('updateShineTimeout', timeout)
    },
    changeTab(tab) {
      this.tab = tab
    },
    handleRightMenu() {
      this.$store.commit('updateRightMenuOpen', !this.RightMenuOpen)
    },
    alertSound() {
      this.$refs.audio.currentTime = 0
      this.$refs.audio.play()
      this.alertCount++
      if (this.alertCount < 3) {
        setTimeout(() => {
          this.alertSound()
        }, 5000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.eventboard-wrap {
  width: 100%;
  height: 100%;
  background-color: #151b35;
  display: flex;
  flex-direction: column;
}

.navbar {
  height: 50px;
  min-height: 50px;
  display: flex;
}

.tabs {
  width: 290px;
  display: flex;
  padding-left: 3px;
  padding-top: 7px;
}

.tab {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4a5c7880;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  img {
    width: px2rem(20);
    height: px2rem(20);
  }
}

.tab.active {
  background-color: #4a5c78;
}

.tab img {
  margin-right: 8px;
}

.tab:nth-child(1) {
  border-radius: 8px 0px 0px 0px;
}

.tab:nth-child(3) {
  border-radius: 0px 8px 0px 0px;
}

.icons {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2px;
  padding-right: 12px;
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon:first-child:hover {
  background: #ffffff1a;
  border-radius: 8px;
}

.icon:last-child {
  background: #ffffff33;
  border-radius: 8px;
}

.icon:last-child:hover {
  background: #ffffff80;
}

.icon img {
  width: 20px;
  height: 20px;
}

.content {
  height: 100%;
  box-sizing: border-box;
  padding: 0px 3px;
  overflow-y: scroll;
}

.all-event {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  /*height: 70px;*/
  background-color: #19223b;
  color: #fff;
  border-radius: 3px 0px 0px 0px;
  cursor: pointer;
}

.match-event,
.urgent-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc((100% - 66px - 66px) / 2); /*121px;*/ /* 100% - all - close */
  /*height: 70px;*/
  color: #fff;
  background-color: #19223b;
  cursor: pointer;
  position: relative;
}

.all-event.active,
.match-event.active,
.urgent-event.active {
  background-color: #ffb703;
}

.urgent-event {
  border-left: 2px solid #21375d;
}

.match-event.active .svg-db {
  filter: invert(62%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.icon {
  display: flex;
}

.text {
  line-height: 16px;
}

.close-right-menu-icon {
  display: flex;
  width: 66px;
  justify-content: center;
  align-items: center;
  background-color: #343b5e;
  color: #fff;
  cursor: pointer;
}

.event-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  padding: 8px 15px 8px 20px;
}
.event-switch .slider {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 25px;
  border-radius: 20px;
  cursor: pointer;
  background: #546267;
  transition: all 0.2s cubic-bezier(0.75, 0.05, 0.07, 1.05);
}
.event-switch .slider:after {
  background: #fefefe;
  position: absolute;
  left: -8px;
  top: -2px;
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  content: '';
  transition: all 0.2s;
}
.event-switch .label {
  color: #ffffff;
  margin-right: 16px;
  line-height: 28px;
}
.event-switch .input {
  display: none;
}

.event-switch .input:checked ~ .slider:after {
  left: 16px;
}
.event-switch .input:checked ~ .slider {
  background: #0096c7;
}

.none {
  visibility: hidden;
}
</style>
