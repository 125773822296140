<template>
  <portal to="ai-box-portal">
    <div class="ai-box-wrap" @click="closeModal"></div>
    <!-- deleteTask -->
    <div v-if="paramAiboxPortal.info === 'deleteTask'" class="ai-box-modal">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_delete_data') }}
      </div>
      <div class="content">
        <div class="title">{{ $t('ai_sure_to_delete') }}</div>
        <div class="data">
          <div class="items">
            <div class="col">{{ $t('ai_box') }}</div>
            <div class="col">{{ $t('ai_device') }}</div>
            <div class="col">{{ $t('ai_recognition_category') }}</div>
            <div class="col">{{ $t('ai_recognition_status') }}</div>
            <div class="col">{{ $t('ai_update_time') }}</div>
          </div>
          <div class="value">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="aiBoxTask.aiboxName" placement="bottom">
              <div class="col">{{ aiBoxTask.aiboxName }}</div>
            </el-tooltip>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="deviceName" placement="bottom">
              <div class="col">{{ deviceName }}</div>
            </el-tooltip>
            <div class="col">
              <img src="@/assets/icons/object.svg" alt="">
              <template v-if="aiBoxTask.ai === 'or'">{{ $t('ai_object')/*物件*/ }}</template>
              <template v-else>{{ aiBoxTask.ai }}</template>
            </div>
            <!-- <div class="col">{{ aiBoxTask.status }}</div> -->
            <div v-if="aiBoxTask.id === -99" class="col">{{ $t('ai_task_add') }}</div>
            <div v-else-if="mode === 'edit' && aiBoxTask.id >= 0 && aiBoxTask.id === taskId" class="col">{{ $t('ai_task_edit') }}</div>
            <div v-else-if="aiBoxTask.status === 0" class="col">{{ $t(`ai_status_${aiBoxTask.status}`) }}</div>
            <div v-else class="col">
              <img src="@/assets/icons/error-info.svg" alt="">{{ $t(`ai_status_${aiBoxTask.status}`) }}
            </div>
            <div class="col">{{ formatTime(aiBoxTask.updatedTime) }}</div>
          </div>
        </div>
        <div class="btns">
          <div class="btn" @click="closeModal">{{ $t('cancel') }}</div>
          <div class="btn red" @click="handleDelete">{{ $t('delete') }}</div>
        </div>
      </div>
    </div>
    <!-- cancelEdit -->
    <div v-else-if="paramAiboxPortal.info === 'cancelEdit'" class="ai-box-modal small">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_edit_not_complete')/*編輯未完成*/ }}
      </div>
      <div class="content">
        <div class="title">{{ $t('ai_cancel_edit')/*確定取消此編輯嗎？*/ }}</div>
        <div class="btns">
          <div class="btn" @click="closeModal">{{ $t('no') }}</div>
          <div class="btn red" @click="handleCancelEdit">{{ $t('yes') }}</div>
        </div>
      </div>
    </div>
    <!-- resourceNotAvailable -->
    <div v-else-if="paramAiboxPortal.info === 'resourceNotAvailable'" class="ai-box-modal small">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />
        {{ $t('ai_insufficient_recognition_resources')/*辨識資源不足*/ }}
      </div>
      <div class="content">
        <div class="title">{{ $t('ai_no_boxes_available')/*很抱歉，無可使用的辨識盒。*/ }}</div>
        <div class="btns">
          <div class="btn yellow" @click="handleConfirm">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'AiboxPortal',
  data() {
    return {}
  },
  computed: {
    ...mapState('aibox', ['mode', 'deviceName', 'paramAiboxPortal']),
    ...mapGetters(['timezone']),
    ...mapGetters('aibox', ['aiBoxTask'])
  },
  methods: {
    ...mapMutations('aibox', ['updateShowAiboxPortal', 'updateMode', 'updateTaskId', 'updateShowAiBox']),
    ...mapActions('aibox', ['getAiBoxes', 'deleteAiBoxTask', 'getAiBoxTasks']),
    formatTime(utcTime) {
      return moment(utcTime).tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')
    },
    closeModal() {
      this.updateShowAiboxPortal(false)
    },
    handleDelete() {
      this.deleteAiBoxTask()
      this.updateShowAiboxPortal(false)
    },
    async handleCancelEdit() {
      switch (this.paramAiboxPortal.action) {
        case 'closeModal':
          this.updateShowAiBox(false)
          break
        case 'switchTask':
          this.updateTaskId(this.paramAiboxPortal.taskId)
          this.getAiBoxes(this.aiBoxTask.ai) // 取得辨識盒資源
          break
        case 'switchMode':
          await this.getAiBoxTasks() // 重新取得AiBoxTasks
          break
        case 'cancelFrEdit':
          this.$store.commit('aiboxFr/updateMode', 'view')
          await this.$store.dispatch('aiboxFr/getUserInfo')
          this.$store.dispatch('aiboxFr/initSetting')
          this.$store.dispatch('aiboxFr/getUserSubscribers')
          break
        case 'cancelLprEdit':
          this.$store.commit('aiboxLpr/updateMode', 'view')
          await this.$store.dispatch('aiboxLpr/getUserInfo')
          this.$store.dispatch('aiboxLpr/initSetting')
          this.$store.dispatch('aiboxLpr/getUserSubscribers')
          break
      }
      this.updateMode('view') // 回到瀏覽模式
      this.updateShowAiboxPortal(false)
    },
    handleConfirm() {
      this.updateShowAiboxPortal(false)
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.ai-box-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000b3;
  z-index: 10;
}

.ai-box-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  z-index: 10;
}

.ai-box-modal.small {
  width: 500px;
}

.header {
  background: #4a5c78;
  color: #ffffff;
  border-radius: 23px 23px 0px 0px;
  font-size: 23px;
  line-height: 30px;
  font-weight: 300;
  padding: 12px 32px;
  display: flex;
  align-items: center;
}

.header img {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}

.content {
  background: #282942;
  border-radius: 0px 0px 23px 23px;
  
  position: relative;
  padding: 20px 32px 32px 32px;
}

.content .title {
  font-size: 32px;
  line-height: 44px;
  font-weight: 300;
  color: #ffffff;
  padding-bottom: 32px;
  text-align: center;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-block;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  border : 1px solid #ffffff;
  padding: 10px 48px;
  cursor: pointer;
}

.items {
  border-bottom: 1px solid #4A5C78;
  font-size: 20px;
  font-weight: 300;
  display: flex;
}

.value {
  display: flex;
  font-size: 22px;
  margin-top: 8px;
  margin-bottom: 60px;
}

.col {
  padding-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.col:nth-child(1) {
  width: 20%;
  padding-left: 30px;
}

.col:nth-child(2) {
  width: 18%;
}

.col:nth-child(3) {
  width: 15%;
}

.col:nth-child(4) {
  width: 23%;
}

.col:nth-child(5) {
  width: 23%;
}

.col img {
  position: relative;
  top: 4px;
  margin-right: 8px;
}

.btn:hover {
  background: #ffffffcc;
}

.red {
  background: #F94144;
  border-color: #F94144;
}

.red:hover {
  background: #F94144cc;
}

.btn.yellow {
  border: #ffc600 1px solid;
  background: #ffc600;
  color: #ffffff;
}

.btn.yellow:hover {
  background: #ffd231;
}

.btns .btn:not(:last-child) {
  margin-right: 48px;
}

/* .btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
} */
</style>