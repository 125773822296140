/* eslint-disable */

function mse(video, src, resizeFunc) {

    const srcUrl = new URL(src);
    this.target = srcUrl.searchParams.has('target') ? srcUrl.searchParams.get('target') : "";
    this.startupCount = 0;

    this.active = true;

    this.debug = 0;
    this.heartbeat = 0;
    this.streamingStarted = false;
    this.queue = [];
    this.ws = null;

    this.element = video;
    this.src = src;

    this.mediaSource = new MediaSource();

    this.sourceBuffer;
    this.mimeCodec;

    this.tryToRestart = false;
    this.tryToReplay = false;
    this.prevEndTime = 0; // Log the last end time after data was appended to the source buffer
    this.lastWsTime = 0; // Log the last time a message was received or a connection was opened
    this.futureTsErr = 0; // Error count for future timestamps
    this.pastTsErr = 0; // Error count for past timestamps
    this.noMsgErr = 0; // Error count of messages not received within the specified period
    this.noConnErr = 0; // Error count for unexpectedly closing connections

    const FUTURE_TS_THRESHOLD = 3; // second
    const PAST_TS_THRESHOLD = 10; // count
    const NO_MSG_THRESHOLD = 10000; // millisecond
    const LONG_DELAY_THRESHOLD = 12; // second; must be greater than LONG_DELAY_REDUCTION
    const LONG_DELAY_REDUCTION = 3; // second

    const _this = this;

    if (!Uint8Array.prototype.slice) {
        Object.defineProperty(Uint8Array.prototype, 'slice', {
            value: function (begin, end) {
                return new Uint8Array(Array.prototype.slice.call(this, begin, end));
            }
        });
    }

    this.pushPacket = function (arr) {
        var view = new Uint8Array(arr);
        if (_this.debug > 1) {
            console.log('got', arr.byteLength, 'bytes.  Values=', view[0], view[1], view[2], view[3], view[4]);
        }
        const data = arr;
        if (!_this.streamingStarted) {
            try {
                _this.sourceBuffer.appendBuffer(data);
                _this.streamingStarted = true;

            } catch (e) {
                _this.debug && console.log(`[${_this.target}#${_this.startupCount}] active = ${_this.active}, error name = ${e.name}`);
                if (e.name === 'QuotaExceededError' && _this.active) {
                    _this.debug && console.error(e);
                    _this.restart();    
                } else {
                    // e.name === 'InvalidStateError'
                    _this.close();
                    _this.element.setAttribute('error', 'true');
                }
            }
            return;
        }
        _this.queue.push(data);

        if (_this.debug > 1) {
            console.log('queue push:', _this.queue.length);
        }
        if (!_this.sourceBuffer.updating) {
            _this.loadPacket();
        }
    }

    this.loadPacket = function () {
        _this.heartbeat = 0;

        if (!_this.sourceBuffer.updating) {
            let bufferedLen = _this.sourceBuffer.buffered.length;
            if (_this.sourceBuffer.buffered.length > 0) {
                // Check the end time of the buffer to make sure the video segment has the correct timestamp
                if (_this.prevEndTime <= 0) {
                    _this.prevEndTime = _this.sourceBuffer.buffered.end(bufferedLen-1);
                } else if ((_this.sourceBuffer.buffered.end(bufferedLen-1) - _this.prevEndTime) > FUTURE_TS_THRESHOLD) {
                    // If the segment duration is under 3 seconds, the end time should not be far
                    _this.futureTsErr++;
                    _this.debug && console.warn(`[${_this.target}#${_this.startupCount}] ts is in the future (${_this.futureTsErr})`);
                } else if (_this.futureTsErr == 0 && _this.sourceBuffer.buffered.end(bufferedLen-1) == _this.prevEndTime) {
                    // End time not updated; assuming ts is too old and exceeds append window
                    // But when the appended data is an audio sample, the end time is also the same
                    _this.pastTsErr++;
                    _this.debug && _this.pastTsErr > PAST_TS_THRESHOLD && console.warn(`[${_this.target}#${_this.startupCount}] ts is too old (${_this.pastTsErr})`);
                } else {
                    _this.pastTsErr = 0;
                }
                _this.prevEndTime = _this.sourceBuffer.buffered.end(bufferedLen-1);
            }
            if (_this.queue.length > 0) {
                const inp = _this.queue.shift();
                if (_this.debug > 1) {
                    console.log('queue PULL:', _this.queue.length);
                }
                var view = new Uint8Array(inp);
                if (_this.debug > 1) {
                    console.log('writing buffer with', view[0], view[1], view[2], view[3], view[4]);
                }
                try {
                    _this.sourceBuffer.appendBuffer(inp);
                    _this.streamingStarted = true;
                } catch (e) {
                    // _this.close();
                    // _this.element.setAttribute('error', 'true');
                    _this.debug && console.error(e);
                    _this.restart();
                }
            } else {
                _this.streamingStarted = false;
            }
        }
        //_this.sourceBuffer.removeEventListener('updateend', _this.loadPacket)
    }

    this.opened = function () {
        // Tracy 2021.12.30 先呼叫URL.revokeObjectURL()使其在沒有被用到時記憶體可以被回收 https://developers.google.com/web/fundamentals/media/mse/basics
        //URL.revokeObjectURL(_this.element.src)
        if (_this.tryToReplay) {
            _this.debug && console.log(`[${_this.target}#${_this.startupCount}] >>> Replay <<<`);
            try {
                _this.element.play();
            } catch (e) {
                _this.debug && console.error(e);
            }
        }
        _this.streamingStarted = false;
        _this.queue = []
        _this.ws = new WebSocket(_this.src);
        _this.ws.binaryType = 'arraybuffer';
        _this.ws.onopen = function (event) {
            if (_this.debug) {
                console.log(`[${_this.target}#${_this.startupCount}] Connect ${_this.src}`);
            }
            _this.lastWsTime = Date.now();
        }
        _this.ws.onmessage = function (event) {
            _this.lastWsTime = Date.now();
            var data = new Uint8Array(event.data);
            if (data[0] == 9) {
                const decoded_arr = data.slice(1);
                if (window.TextDecoder) {
                    _this.mimeCodec = new TextDecoder('utf-8').decode(decoded_arr);
                } else {
                    _this.mimeCodec = _this.Utf8ArrayToStr(decoded_arr);
                }
                if (_this.debug) {
                    console.log(`[${_this.target}#${_this.startupCount}] first packet with codec data: ${ _this.mimeCodec}`);
                }
                _this.sourceBuffer = _this.mediaSource.addSourceBuffer('video/mp4; codecs="' + _this.mimeCodec + '"');
                _this.sourceBuffer.mode = 'segments'
                _this.sourceBuffer.addEventListener('updateend', _this.loadPacket);

                if (resizeFunc) resizeFunc();
            } else {
                _this.pushPacket(event.data);
            }
        };
        _this.ws.onclose = () => {
            _this.debug && console.log(`[${_this.target}#${_this.startupCount}] ${_this.src} Closed`);
            if (_this.active) {
                _this.noConnErr++;
                _this.debug && console.warn(`[${_this.target}#${_this.startupCount}] ws is closed unexpectedly (${_this.noConnErr})`);
            }
        };
    }

    this.Utf8ArrayToStr = function (array) {
        var out, i, len, c;
        var char2, char3;
        out = '';
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 7:
                    out += String.fromCharCode(c);
                    break;
                case 13:
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }
        return out;
    }

    this.seeker = function () {

        if (_this.element.buffered.length > 0 && !_this.element.paused) {
            let threshold = _this.element.buffered.end(0) - _this.element.currentTime;
            _this.element.controls = false;

            /*
            if (threshold <= 0.5) {
                _this.element.playbackRate = 0.1;
            } else if (threshold <= 1.0) {
                _this.element.playbackRate = 0.5;
            } else if (threshold <= 2.0) {
                _this.element.playbackRate = 1;
            } else if (threshold <= 3.0) {
                _this.element.playbackRate = 1.5;
            } else {
                _this.element.currentTime = _this.element.buffered.end(0) - 1.5;
                _this.element.playbackRate = 1;
            }*/
            
            var targetBuffer=0.5;
            var tolerance=  0.1;
            if (threshold > LONG_DELAY_THRESHOLD) {
                // _this.element.currentTime = _this.element.buffered.end(0) - targetBuffer;
                // _this.element.playbackRate = 1.0;
                _this.debug && console.log(`[${_this.target}#${_this.startupCount}] long delay=${threshold}`);
                threshold = LONG_DELAY_REDUCTION;
                _this.element.currentTime = _this.element.buffered.end(0) - threshold;
            }
            {
                _this.element.playbackRate = rateFunction(threshold, targetBuffer, tolerance);
                _this.debug && threshold >= LONG_DELAY_REDUCTION && console.log(`[${_this.target}#${_this.startupCount}] delay=${threshold}, playbackRate=${_this.element.playbackRate}`);
            }
           
            _this.element.controls = true;
        }

        _this.active && window.setTimeout((() => _this.seeker()), 200);
    };

    this.startup = function () {
        _this.startupCount++;
        _this.debug && console.log(`[${_this.target}#${_this.startupCount}] startup is called`);
        _this.active = true;
        _this.resetErrorChecking();
        _this.mediaSource.addEventListener('sourceopen', _this.opened, false);
        _this.element.src = window.URL.createObjectURL(_this.mediaSource);
        window.setTimeout((() => _this.seeker()), 2000);
        window.setTimeout((() => _this.errorChecker()), 1000);
    }

    this.close = function () {
        _this.debug && console.log(`[${_this.target}#${_this.startupCount}] close is called`);
        _this.tryToRestart = false;
        _this.mediaSource.removeEventListener('sourceopen', _this.opened, false);
        //_this.element.src = '';
        _this.active = false;
        _this.ws.close();
    }

    this.restart = function (forceReplay) {
        try {
            _this.tryToReplay = (!_this.element.paused) || forceReplay;
            _this.close();
            _this.tryToRestart = true;
            window.setTimeout((() => {
                if (_this.tryToRestart) {
                    _this.debug && console.log(`[${_this.target}#${_this.startupCount}] >>> Restart <<<`);
                    _this.startup();
                }
            }), 1000);    
        } catch (e) {
            _this.debug && console.error(e);
        }
    }

    this.resetErrorChecking = function () {
        _this.tryToRestart = false;
        _this.prevEndTime = 0;
        _this.lastWsTime = 0;
        _this.futureTsErr = 0;
        _this.pastTsErr = 0;
        _this.noMsgErr = 0;
        _this.noConnErr = 0;
    }

    this.errorChecker = function() {
        try {
            let tsNow = Date.now();
            if (_this.lastWsTime > 0) {
                if ((tsNow - _this.lastWsTime) > NO_MSG_THRESHOLD) {
                    _this.noMsgErr++;
                    _this.debug && console.warn(`[${_this.target}#${_this.startupCount}] No message is received via WS (${_this.noMsgErr})`);
                } else {
                    _this.noMsgErr = 0;
                }
            }    
        } catch(e) {
            _this.debug && console.error(e);
        }

        // If an error occurs, restart player
        if (_this.active) {
            (_this.debug > 1) && console.log(`[${_this.target}#${_this.startupCount}] errorChecker: futureTsErr=${_this.futureTsErr}, oldTsErr=${_this.pastTsErr}, noMsgErr=${_this.noMsgErr}, noConnErr=${_this.noConnErr}`);
            if (_this.futureTsErr > 0 || _this.pastTsErr > PAST_TS_THRESHOLD || _this.noMsgErr > 0 || _this.noConnErr > 0) {
                _this.debug && console.warn(`[${_this.target}#${_this.startupCount}] Error occurred; try to restart player`);
                _this.restart();
            } else {
                window.setTimeout((() => _this.errorChecker()), 200);
            }
        }
    }

    function rateFunction(currentBufferedTime, targetBufferedTime, tolerance){
        var rate = 1.0;
        var upperBound = targetBufferedTime+ tolerance;
        var lowerBound = (targetBufferedTime- tolerance)>0.3? (targetBufferedTime- tolerance):0.3 //minimum
        if (currentBufferedTime > upperBound) {
          //buffer too much
          rate = Math.pow(1.15, (currentBufferedTime - upperBound)*4 );
          if (rate > 3.0) {
            rate = 3.0;
          }
        }
        else if( currentBufferedTime < lowerBound) {
          //buffer too less
          rate = Math.pow(1.5, (currentBufferedTime - lowerBound) );
          if (rate < 0.8) {
            rate = 0.8;
          }    
        }
        rate = Math.round(rate*1000)/1000.0;
        return rate;
    }
}

export default mse