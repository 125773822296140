<template>
  <div class="video-canvas">
    <canvas id="canvas" style="z-index: 10; background-color: transparent;"></canvas>
    <div v-if="!mseUrl" ref="noVideo" class="no-stream-info">No Streaming Available</div>
    <video v-show="mseUrl" ref="video" class="video" loop muted @click.prevent>
      <source :src="mseUrl"/>
    </video>
  </div>
</template>

<script>
import mse from '@/api/mse.js'
import { fabric } from 'fabric'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'VideoCanvas',
  data() {
    return {
      player: null,
      playPromise: null,
      canvas: null,
      rectRoi: null,
      videoW: 0,
      videoH: 0,
      pointArray: [],
      lineArray: [],
      activeShape: '',
      activeLine: '',
      polygonCount: 0,
      strokeColor: ['#F94144', '#F99D41', '#4361EE'],
      fillColor: ['#F9414433', '#F99D4133', '#4361EE33'],
    }
  },
  computed: {
    ...mapState(['liveList', 'isPageVisible']),
    ...mapState('aibox', ['mode', 'editRoi', 'polygonMode', 'setAreaNo', 'isResetRoi']),
    ...mapGetters('aibox', ['aiBoxTask']),
    mseUrl() {
      // liveList.index (integer) == aiBoxTask.sourceId (string)
      let live = this.liveList.find(live => live.index == this.aiBoxTask.sourceId)
      if (live) {
        return live.mseUrl
      } else return null 
      
    },
  },
  watch: {
    isPageVisible() {
      if (this.isPageVisible) this.playVideo() // 網頁若切換到前景則 reset video
      else this.pauseVideo()                   // 若切換到背景則 pause video
    },
    'aiBoxTask.id'() {
      if (this.canvas) this.canvas.clear() // remove all object
      this.initTaskSetting()
      this.initCanvas()
    },
    editRoi() {
      if (this.editRoi) this.drawRoi()
      else this.getRoiCoordAndRedraw()
    },
    isResetRoi() {
      if (this.isResetRoi) {
        this.drawRoi()
        this.updateIsResetRoi(false)
      }
    },
    polygonMode() {
      if (this.polygonMode) {
        this.pointArray = []
        this.lineArray = []
        this.activeShape = null
        this.activeLine = null
        this.updateEditRoi(false)
      }
    },
    'aiBoxTask.config.area1.length'() {
      if(this.aiBoxTask.config.area1.length === 0) 
        this.removePolygonCircle(1)
    },
    'aiBoxTask.config.area2.length'() {
      if(this.aiBoxTask.config.area2.length === 0) 
        this.removePolygonCircle(2)
    },
    'aiBoxTask.config.area3.length'() {
      if(this.aiBoxTask.config.area3.length === 0) 
        this.removePolygonCircle(3)
    },
    setAreaNo() {
      if (this.setAreaNo === -1) this.removePointAndLine()
    }
  },
  mounted() {
    this.initTaskSetting()
    this.playVideo()
    this.initCanvas()
    window.addEventListener('resize', this.windowResize)
  },
  beforeDestroy() {
    this.pauseVideo()
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    ...mapMutations('aibox', ['updateEditRoi', 'updatePolygonMode', 'updateIsResetRoi']),
    ...mapActions('aibox', ['initTaskSetting']),
    playVideo() {
      if (!this.mseUrl) return
      this.player = new mse(this.$refs.video, this.mseUrl)
      this.player.startup()
      this.playPromise = this.$refs.video.play()
    },
    pauseVideo() {
      if (this.player) this.player = null
      if (this.playPromise) {
        this.playPromise.then(() => {
          if (this.$refs.video) this.$refs.video.pause()
        })
        .catch(error => {
          console.log('Pause video error: ', error)
        })
      }
    },
    initCanvas() {
      if (!this.canvas)
        this.canvas = new fabric.Canvas('canvas')
      const [videoW, videoH] = this.getVideoWidthHeight()
      this.canvas.setWidth(videoW)
      this.canvas.setHeight(videoH)
      this.videoW = videoW
      this.videoH = videoH

      this.drawRoi()

      // draw area
      if (this.aiBoxTask.config.area1.length > 0) 
        this.drawAreaPolygon(this.aiBoxTask.config.area1, 1)
      if (this.aiBoxTask.config.area2.length > 0) 
        this.drawAreaPolygon(this.aiBoxTask.config.area2, 2)
      if (this.aiBoxTask.config.area3.length > 0) 
        this.drawAreaPolygon(this.aiBoxTask.config.area3, 3)

      if (this.mode === 'view') {
        this.canvas.hoverCursor = 'default'
        return
      }

      this.canvas.on('object:moving', this.onObjectMoving)
      this.canvas.on('object:modified', this.onObjectModified)
      this.canvas.on('object:scaling', this.onObjectScaling)
      this.canvas.on('mouse:down', this.onMouseDown)
      this.canvas.on('mouse:move', this.onMouseMove)
    },
    windowResize() {
      if (this.canvas) this.canvas.clear() // remove all object
      this.initCanvas()
    },
    preventDragOffCanvas(e) {
      let target = e.target
      let width = target.width
      let height = target.height
      let top = target.top
      let left = target.left
      let rightBound = this.videoW
      let bottomBound = this.videoH
      let modified = false
      if (top < 0) {
        top = 0
        modified = true
      }
      if (top + height > bottomBound) {
        top = bottomBound - height
        modified = true
      }
      if (left < 0) {
        left = 0
        modified = true
      }

      if (left + width > rightBound) {
        left = rightBound - width
        modified = true
      }

      if (modified) {
        target.setLeft(left)
        target.setTop(top)
      }

    },
    onObjectMoving(event) {
      if (event.target.name === 'roi') {
        // this.preventDragOffCanvas(event)
        this.updateConfigRoi(event.target)
      }
      
      if (!this.polygonMode && event.target.areaNo) {
        let p = event.target
        let areaNo = p.areaNo
        let areaPoints
        if (areaNo === 1) areaPoints = this.aiBoxTask.config.area1
        else if (areaNo === 2) areaPoints = this.aiBoxTask.config.area2
        else if (areaNo === 3) areaPoints = this.aiBoxTask.config.area3
        areaPoints[p.name] = { x: p.getCenterPoint().x / this.videoW, y: p.getCenterPoint().y / this.videoH }
        
        let objects = this.canvas.getObjects()
        let obj = objects.find(item => item.name === `polygon${areaNo}`)
        obj.points[p.name] = { x: p.getCenterPoint().x, y: p.getCenterPoint().y }
      }
    },
    onObjectModified(event) {
      if (!this.polygonMode && event.target.areaNo) {
        const areaNo = event.target.areaNo
        this.removePolygonCircle(areaNo)
        this.drawAreaPolygon(this.aiBoxTask.config[`area${areaNo}`], areaNo)
      }
    },
    onObjectScaling(event) {
      if (event.target.name === 'roi') {
        this.updateConfigRoi(event.target)
      }
    },
    onMouseDown(event) {
      if (event.target && event.target.id == this.pointArray[0]?.id) {
        this.generatePolygon(this.pointArray, this.setAreaNo)
      } 

      if (this.polygonMode) {
        this.addPoint(event, this.setAreaNo)
      }
    },
    onMouseMove(event) {
      if (this.polygonMode && this.activeLine && this.activeLine.class == 'line') {
        let pointer = this.canvas.getPointer(event.e)
        this.activeLine.set({ x2: pointer.x, y2: pointer.y })
        let points = this.activeShape.get('points')
        points[this.pointArray.length] = {
          x: pointer.x,
          y: pointer.y
        }
        this.activeShape.set({ points: points })
        this.canvas.renderAll()
      } 
    },
    drawRoi() {
      // if (this.rectRoi) this.canvas.remove(this.rectRoi)
      let objects = this.canvas.getObjects()
      objects.forEach(obj => {
        if (obj.name === 'roi') this.canvas.remove(obj)
      })

      this.rectRoi = new fabric.Rect({
        fill: 'transparent',
        // fill: '#D9FFF8',
        // opacity: 0.2,
        stroke: '#ffffff',
        strokeWidth: 1.5,
        left: this.videoW * this.aiBoxTask.config.roi.x1,
        top: this.videoH * this.aiBoxTask.config.roi.y1,
        width: this.videoW * (this.aiBoxTask.config.roi.x2 - this.aiBoxTask.config.roi.x1) - 1.5,
        height: this.videoH * (this.aiBoxTask.config.roi.y2 - this.aiBoxTask.config.roi.y1) - 1.5,
        name: 'roi',
        hasBorders: false,
        lockRotation : true,
        objectCaching: false,
        cornerSize: 10,
        cornerColor: '#ffffff',
        transparentCorners: false,
        selectable: this.mode === 'view' ? false : true,
      })
      this.canvas.add(this.rectRoi)
      if (this.mode === 'edit') {
        this.canvas.setActiveObject(this.rectRoi)
        this.updateEditRoi(true)
      }
    },
    updateConfigRoi(objRoi) {
      this.aiBoxTask.config.roi.x1 = objRoi.aCoords.tl.x / this.videoW
      this.aiBoxTask.config.roi.y1 = objRoi.aCoords.tl.y / this.videoH
      this.aiBoxTask.config.roi.x2 = objRoi.aCoords.br.x / this.videoW
      this.aiBoxTask.config.roi.y2 = objRoi.aCoords.br.y / this.videoH
    },
    getRoiCoordAndRedraw() {
      let objects = this.canvas.getObjects()
      // remove masks
      objects.forEach(obj => {
        if (obj.name === 'mask') this.canvas.remove(obj)
      })
      
      // get roi object
      const objRoi = objects.find(obj => obj.name === 'roi')
      if (objRoi) {
        this.updateConfigRoi(objRoi) // update aiBoxTask.config.roi
        const x1 = objRoi.aCoords.tl.x
        const y1 = objRoi.aCoords.tl.y
        const x2 = objRoi.aCoords.br.x
        const y2 = objRoi.aCoords.br.y 

        // remove roi rect
        this.canvas.remove(objRoi)

        // redraw roi outside rect
        //.......top...........
        let option = {
          fill: '#000000',
          opacity: 0.5,
          left: 0,
          top: 0,
          width: this.videoW,
          height: y1,
          name: 'mask',
          hasBorders: false,
          lockRotation : true,
          selectable: false,
          objectCaching: false,
        }
        let rectTop = new fabric.Rect(option)
        this.canvas.add(rectTop)
        this.canvas.sendToBack(rectTop)

        //.......bottom...........
        option.left = 0
        option.top = y2
        option.width = this.videoW
        option.height = (this.videoH - y2)

        let rectBottom = new fabric.Rect(option)
        this.canvas.add(rectBottom)
        this.canvas.sendToBack(rectBottom)
        
        //.......left...........
        option.left = 0
        option.top = y1
        option.width = x1
        option.height = (y2 - y1)

        let rectLeft = new fabric.Rect(option)
        this.canvas.add(rectLeft)
        this.canvas.sendToBack(rectLeft)
  
        //.......right...........
        option.left = x2
        option.top = y1
        option.width = (this.videoW - x2)
        option.height = (y2 - y1)

        let rectRight = new fabric.Rect(option)
        this.canvas.add(rectRight)
        this.canvas.sendToBack(rectRight)
      }
      this.canvas.renderAll()
    },
    getVideoWidthHeight() {
      let videoW = this.$refs.video.clientWidth > 0 ? this.$refs.video.clientWidth : this.$refs.noVideo.clientWidth
      let videoH = this.$refs.video.clientHeight > 0 ? this.$refs.video.clientHeight : this.$refs.noVideo.clientHeight
      let realVW = 0
      let realVH = 0
      if (videoW / videoH >= 16 / 9) {
        realVH = videoH
        realVW = Math.round(realVH * 16 / 9)
      } else {
        realVW = videoW
        realVH = Math.round(videoW * 9 / 16)
      }
      return [realVW, realVH]
    },
    drawAreaPolygon(arrPoints, areaNo) {
      // rescale points
      let polyPoints = []
      arrPoints.forEach(point => {
        polyPoints.push({ x: point.x * this.videoW, y: point.y * this.videoH })
      })

      let polygon = new fabric.Polygon(polyPoints, {
        stroke: this.strokeColor[areaNo - 1],
        strokeWidth: 2,
        fill: this.fillColor[areaNo - 1],
        polygonNumber: this.polygonCount,
        name: `polygon${areaNo}`,
        selectable: false,
        objectCaching: false
      })
      this.canvas.add(polygon)

      polyPoints.forEach((point, index) => {
        let circle = new fabric.Circle({
          radius: this.mode === 'view' ? 3 : 5,
          fill: this.strokeColor[areaNo - 1],
          stroke: this.strokeColor[areaNo - 1],
          strokeWidth: 1,
          opacity: 0.7,
          left: point.x,
          top: point.y,
          originX:'center',
          originY:'center',
          hasBorders: false,
          hasControls: false,
          objectCaching: false,
          selectable: this.mode === 'view' ? false : true,
          areaNo: areaNo,
          name: index
        })
        this.canvas.add(circle)
      })
    },
    removePolygonCircle(areaNo) {
      let objects = this.canvas.getObjects()
      objects.forEach(obj => {
        if (obj.name === `polygon${areaNo}`) {
          this.canvas.remove(obj)
        }
        else if (obj.areaNo === areaNo) {
          this.canvas.remove(obj)
        }
      })
    },
    removePointAndLine() {
      this.pointArray.forEach(circle => {
          this.canvas.remove(circle)
      })
      this.lineArray.forEach(line => {
        this.canvas.remove(line)
      })
      this.canvas.remove(this.activeShape)
      this.canvas.remove(this.activeLine)
    
      this.pointArray = []
      this.lineArray = []
      this.activeShape = null
      this.activeLine = null
    },
    addPoint(option, setAreaNo) {
      if (this.setAreaNo === -1 || 
        this.aiBoxTask.config[`area${setAreaNo}`].length >= 8) return

      let min = 99
      let max = 999999
      let random = Math.floor(Math.random() * (max - min + 1)) + min
      let id = new Date().getTime() + random
      let pointer = this.canvas.getPointer(option.e)
      let circle = new fabric.Circle({
        id: id,
        radius: 5,
        fill: '#ffffff',
        stroke: this.strokeColor[setAreaNo - 1],
        strokeWidth: 1,
        left: pointer.x,
        top: pointer.y,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX:'center',
        originY:'center',
        objectCaching: false
      })
      
      if (this.pointArray.length === 0) {
        circle.set({ fill: this.strokeColor[this.setAreaNo - 1] })
      }

      let points = [pointer.x, pointer.y, pointer.x, pointer.y]
      let line = new fabric.Line(points, {
        strokeWidth: 2,
        stroke: this.strokeColor[this.setAreaNo - 1],
        class: 'line',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false
      })

      if (this.activeShape) {
        let pos = this.canvas.getPointer(option.e)
        let points = this.activeShape.get('points')
        points.push({
          x: pos.x,
          y: pos.y
        })
        let polygon = new fabric.Polygon(points, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching:false
        })
        this.canvas.remove(this.activeShape)
        this.canvas.add(polygon)
        this.activeShape = polygon
        this.canvas.renderAll()
      } else {
        let polyPoint = [{ x: pointer.x, y: pointer.y }]
        let polygon = new fabric.Polygon(polyPoint, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false
        })
        this.activeShape = polygon
        this.canvas.add(polygon)
      }
      this.activeLine = line
      this.pointArray.push(circle)
      this.lineArray.push(line)

      this.canvas.add(line)
      this.canvas.add(circle)
      this.canvas.selection = false

      // 最多8個點，若點到第8點，自動產生polygon
      if (this.pointArray.length === 8) 
        this.generatePolygon(this.pointArray, this.setAreaNo)
    },
    generatePolygon(pointArray, setAreaNo) {
      if (pointArray.length === 0 || setAreaNo === -1) return
      let areaPoints = null
      if (setAreaNo === 1) areaPoints = this.aiBoxTask.config.area1
      else if (setAreaNo === 2) areaPoints = this.aiBoxTask.config.area2
      else if (setAreaNo === 3) areaPoints = this.aiBoxTask.config.area3

      let polyPoints = []
      pointArray.forEach(pt => {
        polyPoints.push({ 
          x: pt.left,
          y: pt.top
         })
        areaPoints.push({
          x: pt.left / this.videoW,
          y: pt.top / this.videoH
        })
        this.canvas.remove(pt)
      })

      this.lineArray.forEach(line => {
        this.canvas.remove(line)
      })

      this.canvas.remove(this.activeShape).remove(this.activeLine)
      this.activeLine = null
      this.activeShape = null
      this.updatePolygonMode(false)

      let polygon = new fabric.Polygon(polyPoints, {
        stroke: this.strokeColor[setAreaNo - 1],
        strokeWidth: 2,
        fill: this.fillColor[setAreaNo - 1],
        polygonNumber: this.polygonCount,
        name: `polygon${setAreaNo}`,
        selectable: false,
        objectCaching: false
      })
      this.canvas.add(polygon)

      polyPoints.forEach((point, index) => {
        let circle = new fabric.Circle({
          radius: 5,
          fill: this.strokeColor[setAreaNo - 1],
          stroke: this.strokeColor[setAreaNo - 1],
          strokeWidth: 1,
          opacity: 0.7,
          left: point.x,
          top: point.y,
          originX:'center',
          originY:'center',
          hasBorders: false,
          hasControls: false,
          objectCaching: false,
          areaNo: setAreaNo,
          name: index
        })
        this.canvas.add(circle)
      })
      
      // this.canvas.selection = true
    },
  }
}
</script>

<style scoped>
.video-canvas {
  width: 100%;
  height: 100%;
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-stream-info {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #010101;
  color: #666666;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #010101;
  display: block;
  z-index: -1;
  /* display: none; */
}

.canvas {
  z-index: 10;
  background-color: transparent;
  outline: 1px solid #4A5C78;
}
</style>