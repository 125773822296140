<template>
  <div class="app-wrap">
    <div class="container">
      <TopBar />
      <RightBar />
      <BtmContent />
      <DashboardEventModal v-if="showEventCardDetail" />
      <AllowAudio v-if="showAllowAudio" @closemodal="closeAllowAudioModal" />
      <AiBox v-if="showAiBox" />
    </div>
  </div>
</template>

<script>
import RightBar from '@/components/RightBar'
import TopBar from '@/components/TopBar'
import BtmContent from '@/components/BtmContent'
import DashboardEventModal from '@/components/Dashboard/DashboardEventModal'
import AllowAudio from '@/components/AllowAudio'
import AiBox from '@/components/AiBox'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  apiGetConnections,
  apiGetLiveList,
  apiGetBoviaLprEventsDashboard,
  apiGetBoviaFrEventsDashboard,
  apiGetBoviaLprEvent
} from '@/api/index.js'

export const MaxEventLimit = 100
export const MaxEventCount = 200 // 1000

export default {
  name: 'HomePage',
  components: {
    TopBar,
    RightBar,
    BtmContent,
    DashboardEventModal,
    AllowAudio,
    AiBox
  },
  data() {
    return {
      startTime: '',
      allow_audio_modal_visible: false,
      connetionTimer: null,
      liveTimer: null,
      eventTimer: null,
      frEventTimer: null,
      seconds: 5,
      currentEventId: 0,
      currentFrEventId: 0,
      latestSearchId: 0,
      latestSearchFrId: 0
    }
  },
  computed: {
    ...mapState([
      'isDemo',
      'isLogin',
      'permissionV2',
      'eventList', // LPR
      'frEventList', // FR
      'timeInterval',
      'connectionList',
      'showAllowAudio',
      'showNotDbEvents',
      'showEventCardDetail',
      'userList',
      'selectedUsers'
    ]),
    ...mapState(['RightMenuOpen']),
    ...mapState('aibox', ['showAiBox']),
    ...mapGetters(['displayEventList']),
    isShowNotDbEvents() {
      return this.permissionV2.lprDashboard === 3 && this.showNotDbEvents
    },
    lprEventsEnabled() {
      return this.permissionV2.lprDashboard >= 1
    },
    apiParamUserIds() {
      return this.selectedUsers.map((user) => user.index)
    },
    selectedUserIds() {
      return this.selectedUsers.map((user) => user.id)
    },
    matched() {
      if (!this.isShowNotDbEvents) {
        return 1
      }
      return 0
    },
    noDevice() {
      return this.selectedUsers.length <= 0
    }
  },
  watch: {
    RightMenuOpen(val) {
      if (!val) this.updateShowEventCardDetail(false)
    },
    selectedUsers: {
      deep: true,
      async handler(nVal, oVal) {
        // console.log(`[Dashboard.W.selectedUsers] nVal, oVal:`, nVal, oVal)
        // 選＆不選的切換
        // 無到有, 有到無
        if ((!oVal && nVal) || (!nVal && oVal)) {
          // console.log(`[W.selectedUsers] 無到有, 有到無`)
          // 重頭撈新資料
          this.clearEventList()
          this.clearFrEventList()
          await this.getLatestLprEvents(100)
          await this.getLatestFrEvents(100)
        } else if (oVal && nVal) {
          if (oVal.length !== nVal.length) {
            // console.log(`[W.selectedUsers] 長度不同`)
            // 重頭撈新資料
            this.clearEventList()
            this.clearFrEventList()
            await this.getLatestLprEvents(100)
            await this.getLatestFrEvents(100)
          }
        }
      }
    },
    async matched() {
      clearTimeout(this.eventTimer)
      clearTimeout(this.frEventTimer)

      this.clearEventList()
      this.clearFrEventList()
      await this.getLatestLprEvents(100)
      await this.getLatestFrEvents(100)
      this.getDashboardLprEvents() // 每seconds秒重複執行
      this.getDashboardFrEvents() // 每seconds秒重複執行
    },
    // displayEventList() {
    //   console.log(`[Dashboard.W] displayEventList:`, this.displayEventList[0])
    // }
  },
  created() {
    this.getTagList()
    this.getFrTagList()
    this.getBoviaLprCodebooks()
    this.getGroupList()

    // 以防 SelectedUsers 沒有 userList 取即時事件列表
    if (!this.userList || this.userList.length <= 0) {
      this.getUserList()
    }

    // 新舊資料結構,相容性處理
    this.formatSelectedUsers()
  },
  async mounted() {
    this.seconds =
      this.$route.params.seconds && this.$route.params.seconds >= 1
        ? this.$route.params.seconds
        : this.isDemo
        ? 0.5
        : 5

    setTimeout(() => {
      this.getConnections() // 每5秒重複執行
      this.getLiveList() // 每5秒重複執行
      // this.getDashboardLprEvents() // 每seconds秒重複執行
      // this.getDashboardFrEvents()  // 每seconds秒重複執行
    }, 500)

    await this.getLatestLprEvents(100)
    await this.getLatestFrEvents(100)

    setTimeout(() => {
      // this.getConnections()    // 每5秒重複執行
      // this.getLiveList()       // 每5秒重複執行
      this.getDashboardLprEvents() // 每seconds秒重複執行
      this.getDashboardFrEvents() // 每seconds秒重複執行
    }, 500)

    setTimeout(() => {
      this.allow_audio_modal_visible = true
    }, 1000)

    document.addEventListener('visibilitychange', this.handleVisible)
  },
  beforeDestroy() {
    clearTimeout(this.connetionTimer)
    clearTimeout(this.liveTimer)
    clearTimeout(this.eventTimer)
    clearTimeout(this.frEventTimer)
    document.removeEventListener('visibilitychange', this.handleVisible)
    this.updateShowEventCardDetail(false)
  },
  methods: {
    ...mapMutations([
      'clearEventList',
      'clearFrEventList',
      'clearSosEventList',
      'updateEventList',
      'updateFrEventList',
      'updateIsPageVisible',
      'updateShowEventCardDetail'
    ]),
    ...mapActions([
      'getTagList',
      'getFrTagList',
      'getBoviaLprCodebooks',
      'getGroupList',
      'getUserList',
      'getSosingEvents'
    ]),
    formatSelectedUsers() {
      // 因應 selectedUsers 擴充 index 欄位,
      // 導至舊selectedUsers(已先存在localStorage的)沒有index欄位,
      // 發送api時,會將userIds帶成 undefined
      // 所以需要將舊selectedUsers增加index欄位

      // console.log(`[formatSelectedUsers] userList:`, this.userList)
      // console.log(`[formatSelectedUsers] selectedUsers:`, this.selectedUsers)

      // 格式化 selectedUsers
      const selectedUsers = JSON.parse(JSON.stringify(this.selectedUsers))
      selectedUsers.forEach((user, idx) => {
        if (!user.index) {
          // console.log(`補上 index `)
          const userDetail = this.userList.find((u) => u.id === user.id)
          if (userDetail)
            selectedUsers[idx] = {
              ...selectedUsers[idx],
              ...{ index: userDetail.index }
            }
        }
      })

      this.$store.commit('setSelectedUsers', selectedUsers)
    },
    setParamMatched(param) {
      if (this.matched) {
        param.matched = 1
      } else {
        delete param.matched
      }
      return param
    },
    async getLatestLprEvents(limit) {
      try {
        if (!this.lprEventsEnabled) {
          this.clearEventList()
          this.clearSosEventList()
          return
        }

        if (this.noDevice) return

        let param = {}
        param = this.setParamMatched(param)
        param.limit = limit

        let rawRes = await apiGetBoviaLprEventsDashboard(param)
        let len = rawRes.data?.length ? rawRes.data.length : 0
        let oldestId = 0
        let selectedEvents = []

        if (len > 0) {
          this.latestSearchId = rawRes.data[0].id
          oldestId = rawRes.data[len - 1].id
          // 根據 selectedUsers 來過濾事件
          selectedEvents = rawRes.data.filter((event) => {
            return this.selectedUserIds.includes(event.user.id)
          })

          this.updateEventList(selectedEvents)
        }

        while (len === limit && this.eventList.length < MaxEventCount) {
          if (this.noDevice) break

          param = this.setParamMatched(param)
          if (oldestId > 0) param.beforeId = oldestId // 回傳事件id 大 --> 小
          param.userIds = this.apiParamUserIds 
          rawRes = await apiGetBoviaLprEventsDashboard(param)
          len = rawRes.data?.length ? rawRes.data.length : 0

          if (len > 0) {
            selectedEvents = rawRes.data
            this.updateEventList(selectedEvents)
            oldestId = selectedEvents[len - 1].id
          }
        }
      } catch (err) {
        console.error(`[getLatestLprEvents] `, err)
      }
    },
    async getLatestFrEvents(limit) {
      try {
        // L0: 無權限, L1: 可存取人物即時事件
        if (this.permissionV2.frDashboard === 0) {
          this.clearFrEventList()
          return
        }
        if (this.noDevice) return

        let param = {}
        param = this.setParamMatched(param)
        param.limit = limit

        let rawRes = await apiGetBoviaFrEventsDashboard(param)
        let len = rawRes.data?.length ? rawRes.data.length : 0
        let oldestId = 0
        let selectedEvents = []

        if (len > 0) {
          this.latestSearchFrId = rawRes.data[0].id
          oldestId = rawRes.data[len - 1].id

          // 根據 selectedUsers 來過濾事件
          selectedEvents = rawRes.data.filter((event) => {
            return this.selectedUserIds.includes(event.user.id)
          })

          this.updateFrEventList(selectedEvents)
        }

        while (len === limit && this.frEventList.length < MaxEventCount) {
          if (this.noDevice) break

          param = this.setParamMatched(param)
          if (oldestId > 0) param.beforeId = oldestId
          param.userIds = this.apiParamUserIds // 帶入 userIds 查詢

          rawRes = await apiGetBoviaFrEventsDashboard(param)
          len = rawRes.data?.length ? rawRes.data.length : 0

          if (len > 0) {
            selectedEvents = rawRes.data
            this.updateFrEventList(selectedEvents)
            oldestId = selectedEvents[len - 1].id
          }
        }
      } catch (err) {
        console.log(`[getLatestFrEvents] `, err)
      }
    },
    async fetchEvents(afterId, extraParams, apiFunc) {
      let params = {
        ...extraParams,
        limit: MaxEventLimit
      }
      params = this.setParamMatched(params)
      if (afterId > 0) params.afterId = afterId // 取大於且不包含 afterId 的事件

      return await apiFunc(params)
    },
    processEvents(data, updateFunc) {
      const filterEvents = data.filter((event) =>
        this.selectedUserIds.includes(event.user.id)
      )
      if (filterEvents.length > 0) {
        updateFunc(filterEvents)
      }

      return data[data.length - 1].id
    },
    async fetchAndUpdateEvents(
      apiFunc,
      updateFunc,
      latestSearchId,
      extraParams
    ) {
      if (this.noDevice) return

      let result = await this.fetchEvents(latestSearchId, extraParams, apiFunc)
      if (result.data && result.data.length > 0) {
        latestSearchId = this.processEvents(result.data, updateFunc)
      }

      while (result.data && result.data.length === MaxEventLimit) {
        if (this.noDevice) break

        result = await this.fetchEvents(latestSearchId, extraParams, apiFunc)
        if (result.data && result.data.length > 0) {
          latestSearchId = this.processEvents(result.data, updateFunc)
        }
      }

      return latestSearchId
    },

    async getDashboardLprEvents() {
      if (!this.lprEventsEnabled) {
        this.clearEventList()
        this.clearSosEventList()
        return
      }

      try {
        let extraParams = {}
        extraParams = this.setParamMatched(extraParams)
        // extraParams.userIds = this.apiParamUserIds
        // if (this.permissionV2.lprDashboard === 2) extraParams.public = 1 // 取得公開事件

        this.latestSearchId = await this.fetchAndUpdateEvents(
          apiGetBoviaLprEventsDashboard,
          this.updateEventList,
          this.latestSearchId,
          extraParams
        )
        this.updateChasingEvents()
      } catch (err) {
        console.log('getDashboardLprEvents error => ', err)
      } finally {
        this.eventTimer = this.isLogin
          ? setTimeout(this.getDashboardLprEvents, this.seconds * 1000)
          : clearTimeout(this.eventTimer)
      }
    },
    async getDashboardFrEvents() {
      // L0: 無權限, L1: 可存取人物即時事件
      if (this.permissionV2.frDashboard < 1) {
        this.clearFrEventList()
        return
      }

      try {
        let extraParams = {}
        extraParams = this.setParamMatched(extraParams)
        // extraParams.userIds = this.apiParamUserIds
        // if (this.permissionV2.frDashboard === 2) extraParams.public = 1 // 取得公開事件

        this.latestSearchFrId = await this.fetchAndUpdateEvents(
          apiGetBoviaFrEventsDashboard,
          this.updateFrEventList,
          this.latestSearchFrId,
          extraParams
        )
      } catch (err) {
        console.error('getDashboardFrEvents error => ', err)
      } finally {
        this.frEventTimer = this.isLogin
          ? setTimeout(this.getDashboardFrEvents, this.seconds * 1000)
          : clearTimeout(this.frEventTimer)
      }
    },
    async getConnections() {
      try {
        let res = await apiGetConnections()
        this.$store.commit('updateConnectionList', res.data)

        if (this.lprEventsEnabled) {
          res.data.forEach(async (conn) => {
            // 若是追車事件lprChasing > 0 且lprChasing = event.id
            if (conn.lprChasing > 0) {
              // 搜尋該帳號該筆追車事件
              let resChasing = await apiGetBoviaLprEvent(conn.lprChasing)
              this.updateEventList([resChasing.data]) // 將追車事件加入事件列表或更新該事件
            }
          })

          // 透過connection.sos > 0 取得並更新 SOS 事件
          this.getSosingEvents()
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.connetionTimer = this.isLogin
          ? setTimeout(this.getConnections, 5000) // 5秒後再重複執行getConnections
          : clearTimeout(this.connetionTimer)
      }
    },
    async getLiveList() {
      if (this.permissionV2.liveView === 0) {
        this.$store.commit('updateLiveList', [])
        return
      }

      try {
        let res = await apiGetLiveList()
        this.$store.commit('updateLiveList', res.data.liveList)
      } catch (err) {
        console.log(err)
      } finally {
        this.liveTimer = this.isLogin
          ? setTimeout(this.getLiveList, 5000) // 5秒後再重複執行getLiveList
          : clearTimeout(this.liveTimer)
      }
    },
    updateChasingEvents() {
      this.eventList.forEach((event) => {
        if (event.chasing == 1) {
          this.getChasingEvent(event)
        }
      })
    },
    async getChasingEvent(event) {
      /**
       * 若前端設備正在追車中發生當機或斷線，則search event取得該筆事件
       * chasing會一直等於1(目前後端無法再更新這筆資料)
       * 須透過connection來進行判斷，當機或離線狀態下，該裝置若不在connectionList中
       * 或是該裝置connection.lprChasing=0，則將該筆追車事件結束
       */
      // 該裝置若不在connectionList中, 則將該筆追車事件結束
      let idx = this.connectionList.findIndex(
        (conn) => conn.userAccount == event.user.id
      )
      if (idx == -1) {
        event.chasing = 0
        event.chasingEndTime = 'end' // 先填入end, 之後只會判斷有沒有值
        this.$store.commit('updateChasingEvent', event)
        return
      }

      try {
        // 根據該事件id搜尋該事件最新資料
        let res = await apiGetBoviaLprEvent(event.id)
        if (res.data.chasingEndTime && res.data.chasingEndTime != '') {
          this.$store.commit('updateChasingEvent', res.data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    closeAllowAudioModal() {
      this.$store.commit('updateShowAllowAudio', false) // 之後不需再顯示跳窗詢問
    },
    handleVisible(e) {
      switch (e.target.visibilityState) {
        case 'hidden':
          this.updateIsPageVisible(false)
          break
        case 'visible':
          this.updateIsPageVisible(true)
          break
      }
    }
  }
}
</script>

<style scoped>
.app-wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.container {
  display: grid;
  flex-grow: 1;
  /* width: 100vw; */
  grid-template-columns:
    [start] minmax(0, 0) [left-close] minmax(0, 0) [left-open] minmax(0, 1fr)
    [right-open] minmax(0, 315px) [right-close] minmax(0, 75px) [end];
  grid-template-rows:
    [start] minmax(0, 50px)
    [second] minmax(0, 1fr) [third] minmax(0, 1fr)
    [fourth] minmax(0, 1fr) [onethird] minmax(0, 1fr)
    [middle] minmax(0, 1fr)
    [seventh] minmax(0, 1fr)
    [ptz] minmax(0, 90px)
    [twothird] minmax(0, 1fr) [ninth] minmax(0, 1fr) [call-btm] minmax(0, 1fr)
    [end];
}

.event-card-detail {
  grid-column: start / right-open;
  grid-row: start / end;
  z-index: 1;
}
</style>
