<template>
  <div class="input-range">
    <span>{{ min }}</span>
    <input
      type="range"
      :min="min"
      :max="max"
      class="range"
      :style="rangeStyle"
      step="1"
      v-model="range"
      :disabled="disabled"
    />
    <span class="max">{{ max }}</span>
  </div>
</template>

<script>
export default {
  name: 'InputRange',
  props: {
    min: { required: true },
    max: { required: true },
    value: { required: true },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    range: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    rangeStyle() {
      return {
        '--range': (this.range - this.min) / (this.max - this.min) * 100 + '%'
      }
    },
  }
}
</script>

<style scoped>
.input-range {
  display: flex;
  align-items: center;
}

span {
  width: 18px;
  font-size: 16px;
  line-height: 24px;
  color: #9D9D9D;
}

.max {
  text-align: right;
}

.range {
  width: 100%;
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0 2px;
  background: none;
}

.range::-webkit-slider-runnable-track {
  background-color: #4A5C78;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(
    to right,
    #FFC600,
    #FFC600 var(--range),
    #4A5C78 var(--range),
    #4A5C78 100%
  );
}

.range[disabled]::-webkit-slider-runnable-track {
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-moz-range-thumb {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: none;
  background: none;
  background-color: #ffffff;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 50%;
  background-color: #ffffff;
  height: 16px;
  width: 16px;
  margin-top: -6px;
  cursor: pointer;
}

.range[disabled]::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 1px solid #d7dbdd;
  cursor: default;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range:hover::-webkit-slider-thumb {
  background: radial-gradient(circle at 65% 30%, white 2px, rgb(235, 231, 18) 3%, rgb(127, 127, 141) 80%, rgb(18, 235, 235) 100%);
}
</style>