<template>
  <div class="live-event-wrap" ref="videoWrap" @mouseover="hideTimeAxis(true)" @mouseleave="hideTimeAxis(false)">
    <div v-if="permissionV2.liveView === 0 && permissionV2.video === 0" class="no-permission-all">
      <div class="no-permission-info">
        {{ $t('no_permission_all')/*無觀看即時影音與歷史影片權限*/ }}
      </div>
      <div class="no-permission-icon">
        <img src="@/assets/icons/lock-gray.svg" />
      </div>
    </div>
    <div
      ref="wrapVideo"
      class="video-wrap"
      :class="{ timeAxis: showTimeAxis }"
      v-loading="loading && !liveMode"
      element-loading-background="rgba(1, 1, 1, 0.6)"
      :draggable="false"
      @dragenter.prevent="onDragDevEnter"
      @dragover.prevent="onDragDevOver"
      @drop="onDropDev"
    >
      <div v-show="playMessage" class="play-message">
        <div>{{ playMessage }}</div>
      </div>
      <video
        id="live-video"
        ref="liveVideo"
        :muted="bMuted"
        :style="zoomStyle"
        @loadedmetadata="onLoadedMetaData"
        @timeupdate="onTimeUpdate"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @mouseout="handleMouseOut"
        @ended="onVideoEnd"
        @click.prevent
      ></video>

      <!-- <div class="video-info" :style="{ '--video-info-top': videoInfoTop }">
        <div v-show="liveMode && liveMseUrl" class="live-info">
          <div class="video-live-icon">
            <img src="@/assets/icons/liveVideo.svg" />
          </div>
          <div class="video-height-fps">{{ videoHeight }}{{ videoFPS }}</div>
        </div>
        <div>{{ videoTitle }}</div>
        <div>{{ videoDevice }}</div>
        <div>{{ videoUser }}</div>
      </div> -->

      <div class="video-info" v-if="showVideoInfo" :style="{ '--video-info-top': videoInfoTop }">
        <div v-show="liveMode && liveMseUrl || true" class="live-info">
          <div class="video-live-icon" :class="{live: liveMode && liveMseUrl}">{{ (liveMode && liveMseUrl) ? 'LIVE' : 'VOD' }}</div>
          <div class="video-height-fps">{{ videoHeight }}{{ videoFPS }}</div>
          <div class="video-title">{{ `${videoTitle}(${videoDevice})` }}</div>
        </div>
      </div>
      <!-- v-if="liveMseUrl || this.videoList.length > 0" -->
      <div class="controls">
        <div class="left">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('previous_video')/*前一段影片*/" placement="top">
            <div class="control-btn skip-prev" @click="playPrevVideo">
              <img :src="prevVideoIcon" />
            </div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="this.pause ? $t('play')/*播放*/ : $t('pause')/*暫停*/" placement="top">
            <div class="control-btn" @click="playOrPause">
              <img :src="playBtnIcon" />
            </div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('next_video')/*後一段影片*/" placement="top">
            <div class="control-btn skip-next" :class="{disabled: liveMode}" @click="playNextVideo">
              <img :src="nextVideoIcon" />
            </div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="this.bMuted ? $t('unmute')/*解除靜音*/ : $t('mute')/*靜音*/" placement="top">
            <div
              class="control-btn"
              :class="{ muted: bMuted }"
              @click="switchMuted"
            >
              <img class="volume-icon" :src="muteIcon" />
            </div>
          </el-tooltip>
          <input
            type="range"
            min="0"
            max="1"
            class="volume"
            :style="volumeStyle"
            step="0.01"
            v-model="volume"
            @input="volumeChange"
          />
          <!-- <div v-if="!liveMode && duration.seconds" class="time">
            <span
              >{{ timeElapsed.minutes }}:{{ timeElapsed.seconds }} /
              {{ duration.minutes }}:{{ duration.seconds }}</span
            >
          </div> -->
        </div>
        <div class="right">
          <!-- <div class="dropup">
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('play_rate')/*播放倍率*/" placement="left">
              <button
                class="dropbtn"
                @mouseover="rateUpMouseOver"
                :style="[
                  liveMode
                    ? { '--dropbtn-color': '#AAA' }
                    : { '--dropbtn-color': '#FFF' }
                ]"
              >
                {{ showRate }}
              </button>
            </el-tooltip>
            <div class="dropup-content" v-show="rateUpActive && !liveMode">
              <a
                v-for="item in rateList"
                :key="item.value"
                :style="[
                  item.value == playRate
                    ? { '--rateup-color': '#0F0' }
                    : { '--rateup-color': '#FFF' }
                ]"
                @click="selectRate(item.label, item.value)"
                >{{ item.label }}</a
              >
            </div>
          </div> -->
          <VideoSpeedBtn
            :tooltip="'play_rate' /*播放倍率*/"
            :tooltipPlacement="'bottom'"
            :disabled="liveMode"
            v-model="playRate"
            @input="selectRate"
          />
          <!-- <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="`${$t('zoom_in')}${zoomIndex >= 0 ? '(X'+zoomList[zoomIndex+1]+')' : ''}`/*放大畫面*/" placement="top">
            <div class="control-btn" @click="zoomIn()">
              <img src="@/assets/icons/zoom-in.svg" />
            </div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="`${$t('zoom_out')}${zoomIndex > 0 ? '(X'+zoomList[zoomIndex-1]+')' : ''}`/*縮小畫面*/" placement="top">
            <div class="control-btn" @click="zoomOut()">
              <img src="@/assets/icons/zoom-out.svg" />
            </div>
          </el-tooltip> -->
          <VideoZoomBtn
            :class="['zoom']"
            :tooltip="'zoom_ratio' /*縮放倍率*/"
            :tooltipPlacement="'bottom'"
            :disabled="false"
            v-model="zoomRate"
            @input="selectZoom"
          />
          <div class="piple"></div>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('show_only_events')/*只顯示此設備的事件*/" placement="top">
            <div class="control-btn" @click="switchDeviceMode">
              <img :src="deviceSwitchIcon" />
            </div>
          </el-tooltip>  
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('download_video')/*影片下載*/" placement="top">
            <div class="control-btn" @click="downloadMP4">
              <img src="@/assets/icons/download-video.svg" />
            </div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('full_screen')/*全螢幕*/" placement="top">
            <div class="control-btn" @click="extendToFullscreen">
              <img src="@/assets/icons/fullscreen.svg" />
            </div>
          </el-tooltip>
          <PtzControlBtn />
          <!-- LIVE 按鈕 -->
          <!-- <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('jump_to_live')/*跳至直播畫面*/" placement="top">
            <div
              class="control-btn live"
              :class="{ 'no-live': !liveMseUrl, 'no-permission': permissionV2.liveView === 0 }"
              @click="switchLiveMode"
            >
              <img :src="liveIcon" />
            </div>
          </el-tooltip> -->
        </div>
      </div>
    </div>
    <transition name="fade">
      <TimeAxis 
        v-if="showTimeAxis"
        ref="timeAxis"
        :liveList="liveList"
        :videoList="videoList"
        :videoIndex="videoIndex"
        :liveMode="liveMode"
        :videoCurrentSeconds="videoCurrentSeconds"
        :showArrowTime="permissionV2.video > 0"
        :reTimeAxis="reTimeAxis"
        :permissionV2video="permissionV2.video"
        :axisRange.sync="timeAxisRange"
        @stopAndPlayAtSpecificTim="stopAndPlayAtSpecificTim"
        @loadVideoWithPointerTim="loadVideoWithPointerTim"
        @setReTimeAxis="setReTimeAxis"

        @switchLiveMode="switchLiveMode"
      />
    </transition>
    <!-- <VideoDownload
      v-if="isDownload"
      @closeDL="closeDownload"
      @backPlay="backPlayVideo"
      :isDownload="isDownload"
    /> -->
    <VideoDownload
      v-if="isDownload"
      :queryByTime="true"
      :canDelete="true"
      :postIds="[this.singleUrlUserID]"
      :caller="'dashboard'"
      @close="closeDownload"
    />
  </div>
</template>

<script>
import mse from '@/api/mse.js'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { apiSearchVideo } from '@/api/index.js'
import TimeAxis from './TimeAxis.vue'
import PtzControlBtn from './PtzControlBtn.vue'
import moment from 'moment'
// import VideoDownload from './Download/VideoDownload.vue'
import VideoDownload from '@/components/Base/VideoDownload.vue'
import VideoSpeedBtn from '@/components/Base/VideoSpeedBtn.vue'
import VideoZoomBtn from '@/components/Base/VideoZoomBtn.vue'
import Hls from 'hls.js'
//import * as d3 from 'd3'
import { sleep } from '@/utils/lib.js'

// const zoomList = [
//   { label: '3.0X', value: 3.0 },
//   { label: '2.75X', value: 2.75 },
//   { label: '2.5X', value: 2.5 },
//   { label: '2.25X', value: 2.25 },
//   { label: '2.0X', value: 2.0 },
//   { label: '1.75X', value: 1.75 },
//   { label: '1.5X', value: 1.5 },
//   { label: '1.25X', value: 1.25 },
//   { label: '1.0X', value: 1.0 }
// ]

export default {
  name: 'CompareLive',
  components: {
    TimeAxis,
    PtzControlBtn,
    VideoDownload,
    VideoSpeedBtn,
    VideoZoomBtn
  },
  props: {
    currentPage: {
      type: Number,
      default: 0
    },
    // for debug
    pageCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      videoCurrentSeconds: null,
      videoList: [], // 歷史影片
      videoIndex: 0, // 歷史影片index, index越大是越舊的影片
      loading: false, // 載入歷史影片時的loading狀態
      player: null,
      pause: true,
      volume: 0,
      preVolume: 0.5,
      // zoomList,
      // zoomIndex: 0,
      zoomRate: 1,
      fullScreenMode: false,
      bMuted: true, // true: 靜音; false: 非靜音
      bLockDevice: false, // true: 只顯示該device的事件; false: 顯示全部勾選device的事件
      bDrag: false,
      startPosX: 0,
      startPosY: 0,
      dragRange: null,
      shiftX: null,
      shiftY: null,
      duration: '',
      timeElapsed: '',
      rateList: [
        { label: '16X', value: 16.0 },
        { label: '8X', value: 8.0 },
        { label: '4X', value: 4.0 },
        { label: '2X', value: 2.0 },
        { label: '1X', value: 1.0 },
        { label: '0.5X', value: 0.5 },
        { label: '-1X', value: -1.0 }
      ],
      playRate: 1.0,
      showRate: '1X',
      rateUpActive: false,
      videoHeight: 0,
      videoFPS: '',
      cTime: 0,
      vFrames: 0,
      fpsInterval: null,
      intervalRewind: null,
      videoInfoTop: '0px',
      openTimeAxis: false,
      specificTimeout: null,
      isDownload: false,
      reLoadHVL: null, // 每分鐘重撈歷史影片
      liveStartTime: new Date(),
      reTimeAxis: false, // 重劃時間軸
      resetTim: null, // 若是拉到沒有影片的地方 抓最近的 要重設 tim
      abortController: new AbortController(),
      noStreaming: false,
      timeAxisRange: 4, // 8H
    }
  },
  computed: {
    ...mapState([
      'liveMode',
      'liveList',
      'userList',
      'liveVideoUrl',
      'lockDeviceId',
      'singleUrlUserID',
      'TopMenuHeightLevel',
      'videoViewMode',
      'isPageVisible',
      'permissionV2',
      'selectedUsers',
      'dragUser',
      'callMode',
      'callCmd'
    ]),
    ...mapState('video', ['isBackPlay']),
    ...mapGetters(['timezone']),
    showTimeAxis() {
      // 2/3高度 + 單路模式才呈現時間軸
      // 有歷史影片才呈現時間軸
      // 顯示PTZ設定時不顯示時間軸; 顯示時間軸不顯示PTZ設定
      let heightAndMode = this.TopMenuHeightLevel > 2 && this.videoViewMode === 1
      //let play = this.videoList.length > 0
      let play = this.videoCurrentSeconds != null
      return heightAndMode && play
      // return heightAndMode && play && this.openTimeAxis
    },
    prevVideoIcon() {
      return require('@/assets/icons/skip-next.svg')
      // return this.videoList.length > 0 &&
      //   this.videoIndex < this.videoList.length - 1
      //   ? require('@/assets/icons/skip-next.svg')
      //   : require('@/assets/icons/skip-next-disable.svg')
    },
    nextVideoIcon() {
      return require('@/assets/icons/skip-next.svg')
      // return this.videoList.length > 0 && this.videoIndex > 0
      //   ? require('@/assets/icons/skip-next.svg')
      //   : require('@/assets/icons/skip-next-disable.svg')
    },
    playBtnIcon() {
      return this.pause
        ? require('@/assets/icons/play.svg')
        : require('@/assets/icons/pause.svg')
    },
    muteIcon() {
      return this.bMuted
        ? require('@/assets/icons/volume-mute.svg')
        : require('@/assets/icons/volume-2.svg')
    },
    liveIcon() {
      return this.liveMode && this.permissionV2.liveView > 0
        ? require('@/assets/icons/liveVideo.svg')
        : require('@/assets/icons/liveVideo-gray.svg')
    },
    volumeStyle() {
      return {
        '--volume': this.volume * 100 + '%'
      }
    },
    zoomStyle() {
      return {
        '--zoom': this.zoomRate, // this.zoomList[this.zoomIndex],
        '--shift-x': this.shiftX !== null ? this.shiftX + 'px' : '50%',
        '--shift-y': this.shiftY !== null ? this.shiftY + 'px' : '50%'
      }
    },
    deviceSwitchIcon() {
      return this.bLockDevice
        ? require('@/assets/icons/check-square-enabled.svg')
        : require('@/assets/icons/check-square.svg')
    },
    videoTitle() {
      let user = this.userList.find((item) => item.id === this.singleUrlUserID)
      return user ? user.video.title : ''
    },
    videoDevice() {
      let user = this.userList.find((item) => item.id === this.singleUrlUserID)
      return user ? user.id : ''
    },
    videoUser() {
      let user = this.userList.find((item) => item.id === this.singleUrlUserID)
      return user ? user.info.name : ''
    },
    liveMseUrl() {
      let live = this.liveList.find((item) => item.id == this.singleUrlUserID)
      if (!live) this.$store.commit('updateLiveMode', false)

      return live ? live.mseUrl : null
    },
    playMessage() {
      let message = ''
      if (this.permissionV2.liveView === 0 && this.permissionV2.video > 0 ) {
        // 無觀看即時影音權限 ＋ 有觀看歷史影音權限
        if (this.videoIndex === -1) message = this.$t('no_video'/*無可播放的歷史影音*/)
        else if (this.pause) message = this.$t('video_pause'/*停留在暫停畫面*/)
      } else if (this.permissionV2.liveView > 0 && this.permissionV2.video === 0) {
        // 有觀看即時影音權限 ＋ 無觀看歷史影音權限
        if (!this.liveMseUrl) message = this.$t('no_live_stream'/*無可播放的即時影音*/)
      } else if (this.permissionV2.liveView > 0 && this.permissionV2.video > 0) {
        // 有觀看即時影音權限 ＋ 有觀看歷史影音權限
        if (!this.liveMseUrl && this.videoIndex === -1) message = this.$t('no_video'/*無可播放的歷史影音*/)
        else if (!this.liveMseUrl && this.videoIndex === -1 && this.pause) message = this.$t('video_pause'/*停留在暫停畫面*/)
        else if (this.selectedUsers.length <= 0) message = this.$t(`no_selected_user`)
      }
      return message
    },
    showVideoInfo() {
      return this.selectedUsers.length > 0
    },
  },
  watch: {
    isPageVisible() {
      try {
        if (this.isPageVisible) {
          if (this.liveMode && this.liveMseUrl) {
            this.playLiveVideo('W.isPageVisible') // 網頁若切換到前景則 reset video
          } else if (!this.liveMode && this.videoList.length > 0) {
            this.playOrPause()
          }
        } else {
          this.pause = true
          this.stopLiveVideo('W.isPageVisible') // 若切換到背景則 pause video
        }
      } catch (err) {
        console.error(`[LliveEvent.W.isPageVisible]`, err)
      }
    },
    liveMseUrl() {
      if (this.liveMseUrl) {
        // 直播
        this.playLiveVideo('liveMseUrl')
      } else {
        this.pause = true
      }
    },
    async singleUrlUserID(nVal, oVal) {
      console.log(`[LiveView.W.singleUrlUserID] nVal, oVal:`, nVal, oVal)
      try {
        // 8/8 暫時處理 切換LIVE設備 會狂閃的問題
        if (nVal !== oVal) {
          if (this.player && this.player.close) {
            this.player.close()
            this.player = null
          }
        }
        if (this.singleUrlUserID) {
          // 切換設備時 把畫出來的歷史影片長方形清掉 撈完後再重畫
          //d3.selectAll('.videoLine').remove()
          let left = this.convertToUTC(new Date().addMins(-24*60)) 
          let right = this.convertToUTC(new Date().addMins(24*60)) 
          await this.getHistoryVideoList([left, right])
          if (this.videoList.length > 0 && !this.liveMseUrl) {
            this.videoIndex = 0
            this.playHistVideo()
          }
          // 若無歷史影片則清空videoMarker, videoPath等資料
          if (this.videoList.length == 0 && !this.liveMseUrl) {
            this.resetVideoGpsMarkerPath()
            this.pause = true
            this.$refs.liveVideo.pause()
          }
        }
      } catch (err) {
        console.error(`[LiveEvent.W.singleUrlUserID]`, err)
      }
    },
    volume() {
      this.bMuted = this.volume == 0 ? true : false
    },
    TopMenuHeightLevel() {
      this.videoInfoTop = this.getVideoInfoTop()
      this.dragRange = this.getVideoDragRange()
    },
    zoomRate() {
      this.$nextTick(() => {
        this.dragRange = this.getVideoDragRange()
      })
    },
    videoCurrentSeconds(val) {
      if (this.liveMode && val==0) {
        this.liveStartTime = new Date()
      }
    },
    // selectedUsers: {
    //   deep: true,
    //   handler(/*nVal, oVal*/) {}
    // },
    player(nVal, oVal) {
      console.log(`[LiveEvent.W.player] nVal:`, oVal)
      console.log(`[LiveEvent.W.player] oVal:`, oVal)
      if (nVal && !oVal) {
        console.log(`[LiveEvent.W.player]：可以開啟 mse`, moment().format('HH:mm:ss.SSS'))
      } else if (!nVal && oVal) {
        console.log(`[LiveEvent.W.player]：應已關閉 mse`, moment().format('HH:mm:ss.SSS'))
      }
    },

    callMode() {
      // console.log(`[LiveEvent.W.callMode] Mode, Cmd:`, this.callMode, this.callCmd)

      // 掛斷
      if (this.callMode === 'off') {
        if (this.callCmd === 'hangup') {
          console.log('恢復聲音')
          this.switchMuted()
          this.$notify({
            type: 'warning',
            title: this.$t('hint'),
            message: this.$t('top_bar_calling_volume_reverse')
          })
        }
      }
    },
    callCmd() {
      // console.log(`[LiveEvent.W.callCmd] Mode, Cmd:`, this.callMode, this.callCmd)

      // 接聽
      if (this.callCmd === 'answer') {
        console.log('關掉聲音')
        this.switchMuted()
        this.$notify({
          type: 'warning',
          title: this.$t('hint'),
          message: this.$t('top_bar_calling_volume_off')
        })
      }
    },
    // 9/12 已有加快搜尋速度, 所以不作UX處理; 讓使用者確切知道會下載多久
    // '$store.state.video.videoList.length'(len) {
    //   // 處理UX, 讓使用者可以更快看到影像
    //   const loading = this.$store.state.video.searchLoading
    //   const page = 2
    //   if (loading && len >= 20 * page) {
    //     this.$store.commit('video/updateSearchLoading', false)
    //   }
    // },
    isBackPlay(nVal) {
      try {
        if (nVal) {
          this.isDownload = false
          const module = this.$store.state.video
          const { videoIndex, videoList } = module

          this.backPlayVideo(videoIndex, videoList)

          this.resetState() // Store.video
        }
      } catch (err) {
        console.error(`[LiveEvent.W.isBackPlay] `, err)
      }
    }
  },
  mounted() {
    // console.log(`[LiveEvent.mounted] currentPage `, `${this.currentPage} / ${this.pageCount}`, this.singleUrlUserID)

    this.addMethodInDate()
    let left = this.convertToUTC(new Date().addMins(-24*60)) 
    let right = this.convertToUTC(new Date().addMins(24*60))
    this.getHistoryVideoList([left, right])
    this.playLiveVideo('mounted')
    // 5秒鐘 抓取一次FPS數
    this.getFPS()
    this.$bus.$on('playVideoAgain', this.playVideoAgain) // 監聽左側欄點擊再次播放
    this.videoInfoTop = this.getVideoInfoTop()
    this.dragRange = this.getVideoDragRange()
    // 若是直播 60秒 重撈1天歷史影片
    this.reloadHVLtimeout()

    // 模式 1x1 自動開啟聲音 2022-10-13
    if (this.videoViewMode == 1) {
      this.bMuted = false
      this.volume = 0.5
    }
    //歷史影音權限需判斷 permissionV2 的 video 參數：
    //0(關) → 無權限，隱藏歷史播放相關功能 (時間軸不能拖動，下載影片反灰不能下載，選擇歷史影片的日曆及時間也不可點）
    //1(一般)→ 可以看歷史影音 (但不可下載/刪除/修改，下載影片內的下載/刪除/保留編輯需禁止）
    //2(進階) → 可以修改/刪除/下載歷史影音

    // 調整el-loading-mask z-index
    this.$nextTick(() => {
      let loadingMask = document.querySelector('.el-loading-mask')
      if (loadingMask) loadingMask.style.zIndex = 3
    })
  },
  beforeDestroy() {
    this.stopLiveVideo('beforeDestroy')
    if (this.fpsInterval) {
      //clearInterval(this.fpsInterval)
      clearTimeout(this.fpsInterval)
      this.fpsInterval = null
    }
    if (this.intervalRewind) clearInterval(this.intervalRewind)
    this.$bus.$off('palyVideoAgain', this.playVideoAgain)
    if (this.reLoadHVL) {
      //clearInterval(this.reLoadHVL)
      clearTimeout(this.reLoadHVL)
      this.reLoadHVL = null
    }
  },
  methods: {
    ...mapActions(['getVideoGpsMarker', 'resetVideoGpsMarkerPath']),
    ...mapActions('video', ['resetVideoGpsPath']),
    ...mapMutations('ptz', ['updateFramerate', 'updateResolution']),
    ...mapMutations('video', ['updateIsShowVideoDownloadPanel', 'resetState']),
    onVideoEnd() { // 歷史影片播完自動切下一個
      if (!this.liveMode && !this.noStreaming) { 
        if (this.videoIndex > 1) {
          this.videoIndex -= 1
          this.playHistVideo()
        } else { // 切換直播
          this.switchLiveMode() 
        }
      } else {
        /** 若是live video中斷，呼叫playLiveVideo() */
        /*if (!(this.$refs.liveVideo.currentTime > 0 && 
          !this.$refs.liveVideo.paused && this.$refs.liveVideo.readyState > 2)) {
          console.log('直播停止: ', new Date())
          this.playLiveVideo() 
        }*/ 
      }
    },
    addMethodInDate() {
      // 在Date新增方法 方便增加天數 畫path和text要用
      Date.prototype.addDays = function (days) {
        this.setDate(this.getDate() + days)
        return this
      }

      Date.prototype.addSecs = function (secs) {
        this.setTime(this.getTime() + secs * 1000)
        return this
      }

      Date.prototype.addMins = function (mins) {
        this.setTime(this.getTime() + mins * 60 * 1000)
        return this
      }

      Date.prototype.addHours = function (hours) {
        this.setTime(this.getTime() + hours * 60 * 60 * 1000)
        return this
      }
    },
    stopAndPlayAtSpecificTim(tim) {
      if(this.specificTimeout) { clearTimeout(this.specificTimeout) }
      // this.stopLiveVideo('stopAndPlayAtSpecificTim')
      this.specificTimeout = setTimeout(() => { 
        this.getSpecificTimVideo(tim) 
        this.reTimeAxis = false
      }, 200)
    },
    loadVideoWithPointerTim(pointerTim) {
      // 播放歷史影片時, 根據影片時間同步更新marker & path
      if (!this.liveMode && this.videoList.length > 0)
        this.$store.commit('updateVideoMarkerPath', pointerTim.toISOString())
    },
    getSpecificTimVideo(tim) {
      if (this.permissionV2.video === 0) return // L0 無觀看歷史影片權限  
      // 用現有的videoList找到index 播放影片後
      // 以pointerTim往前後2.5小時撈資料(大部分約100筆) 更新videoList和videoIndex
      let index = this.assignVideoIndex(tim)
      let timeout = null
      //console.log('indx', index, tim)
      this.loading = true
      if (index == -1) {
        if (timeout) clearTimeout(timeout)
        this.findVideoAfterFetch(tim)
      } else {
        tim = this.resetTim
        let ckgap = index / this.videoList.length
        let minGap = 0.2, maxGap = 0.8
        if (this.$refs.timeAxis.setHour>=8) {
          minGap = 0.4, maxGap = 0.6
        }
        if (ckgap < minGap || ckgap > maxGap) { // 時間軸 接近邊際時再重撈影片
          if (timeout) clearTimeout(timeout)
          timeout = setTimeout(async () => {
            //放在settimeout 確保影片開始播放後才重撈videoList
            await this.getNewHistoryVideoList(tim)
            this.videoIndex = this.assignVideoIndex(tim)
            this.playSpecificVideo(tim, this.videoIndex)
          }, 100)
        } else {
          this.playSpecificVideo(tim, index)
        }
      }
      this.loading = false
    },
    async getNewHistoryVideoList(tim) {
      // 重撈前後2.5小時的歷史影片
      let left = this.convertToUTC(new Date(tim).addMins(-24*60)) 
      let right = this.convertToUTC(new Date(tim).addMins(24*60)) 
      await this.getHistoryVideoList([left, right])
      //this.$refs.timeAxis.drawVideoList()
    },
    playSpecificVideo(tim, index) {
      // 播放特定時間的歷史影片
      this.videoIndex = index
      if(index < 0) return
      if (this.noStreaming) this.noStreaming = false
      let s = new Date(this.utcToTaipeiTime(this.videoList[this.videoIndex].startTime))
      let secDiff = moment(tim).diff(s) / 1000
      this.playHistVideo(secDiff)
      this.$refs.timeAxis.manualMoveTim = false
    },
    async findVideoAfterFetch(tim) {
      // 用箭頭或是拖很快時 可能會直接拖到沒載到歷史影片的地方 這時候就要重撈 再播放影片
      await this.getNewHistoryVideoList(tim)
      let index = this.assignVideoIndex(tim)
      // 若拖動時間軸到最新的時間，index會等於-1，在這個時候切換到直播模式
      if (index == -1) {
        // 拖拉時間接近當下時間 切換直播
        let tims = tim.getTime() / 1000
        let nows = new Date().getTime() / 1000
        //console.log('拖拉時間接近當下時間 切換直播', tim, new Date(), (nows - tims))
        if ((nows - tims) < 10) {
          this.switchLiveMode()
        } else { 
          this.noStreaming = true
          this.$store.commit('updateLiveMode', false)
          this.pause = false
          this.stopLiveVideo('findVideoAfterFetch')
        }
        this.$refs.timeAxis.manualMoveTim = false
      } else {
        tim = this.resetTim
        this.playSpecificVideo(tim, index)
      }
    },
    assignVideoIndex(tim) {
      this.resetTim = tim
      // 找到符合時間的影片 並以此index更新videoIndex
      let index = this.videoList.findIndex((item) => {
        let s = new Date(this.utcToTaipeiTime(item.startTime))
        let e = new Date(this.utcToTaipeiTime(item.stopTime))
        return ((+tim >= s) && (+tim < +e))
      })
      /*if (index == -1) { // 若是沒有比對到 抓最近時間的影片
        // 拖拉時間接近當下時間 切換直播
        let tims = tim.getTime() / 1000
        let nows = new Date().getTime() / 1000
        //console.log('拖拉時間接近當下時間 切換直播', tim, new Date(), (nows - tims))
        if ((nows - tims) < 10) {
          return -1
        } else {
          let old = -1
          let val = 0
          this.videoList.some((item, indx) => {
            let s = new Date(this.utcToTaipeiTime(item.startTime))
            if (+tim >= +s) {
              val = (+tim)-(+s)
              // console.log('s-Sec: ', +tim, +s, (+tim)-(+s))
            } else {
              // console.log('Sec-s: ', +s, +tim, (+s)-(+tim))
              val = (+s)-(+tim)
            }
            if (old != -1) {
              if (val >= old) {
                // console.log(old, val, indx)
                index = indx
                this.resetTim = new Date(this.utcToTaipeiTime(item.startTime))
                return true
              }
            }
            old = val
          })
        }
      }*/
      // console.log(index)
      this.noStreaming = index >= 0 ? false : true
      return index
    },
    getVideoInfoTop() {
      /**
       * 取得video的寬高，因為實際播放video的寬高，則是在element保持16:9的寬度與高度
       * 因應高度會調整，為了使video資訊能顯示在固定video的左上，且不遮蓋到上方的OSD
       * 故利用以下方式計算
       */
      if (this.TopMenuHeightLevel > 1 && this.$refs.liveVideo) {
        let w = this.$refs.liveVideo.offsetWidth
        let h = this.$refs.liveVideo.offsetHeight
        if (w / h > 1.78) {
          // video佔滿高度
          return '30px'
        } else {
          // video佔滿寬度
          let realVideoHeight = w / 1.78
          return (h - realVideoHeight) / 2 + 20 + 'px'
        }
      } else {
        return '30px'
      }
    },
    // 處理左側欄點擊相同帳號，再次播放，有串流就直播，沒有就播歷史影片
    // 點擊不同帳號是由watch處理
    playVideoAgain() {
      if (this.liveMseUrl) {
        // 直播
        this.playLiveVideo('playVideoAgain')
      } else if (this.videoList.length > 0) {
        this.videoIndex = 0
        this.playHistVideo()
      }
    },
    hideTimeAxis(bool) {
      this.openTimeAxis = bool
    },
    convertToUTC(tim) {
      // 放進api搜尋時 要更換成UTC時區
      tim = new Date(tim)
      //return new Date(tim.getTime() - 8 * 60000).toISOString()
      return moment(new Date(tim)).toISOString()
    },
    utcToTaipeiTime(tim) {
      // 轉換成UI畫面上的時間時 要根據時區轉換
      //tim = new Date(moment.utc(tim).format())
      //return moment(new Date(tim.getTime() + 8 * 60000)).format('YYYY/MM/DD HH:mm:ss')
      return moment(new Date(tim)).tz(this.timezone).format('YYYY/MM/DD HH:mm:ss')
    },
    async getHistoryVideoList(seDate = [new Date().addMins(-24*60), new Date()]) {
      if (this.permissionV2.video === 0) return
      if (this.videoCurrentSeconds==null) this.videoCurrentSeconds = 0  // 沒有影片 也顯示時間軸
      if (this.noStreaming) this.noStreaming = false
      this.videoIndex = -1
      this.videoList = []
      if (!this.singleUrlUserID) return
      const [startTime, stopTime] = seDate.map((item) => this.convertToUTC(item))
      // console.log(seDate, startTime + ' => ' + stopTime)
      let postData = Object
      postData = { postId: [this.singleUrlUserID], startTimeGt: startTime, startTimeLt: stopTime,  }
      let signal = { signal: this.abortController.signal }
      try {
        this.abortController.abort()
        let res = await apiSearchVideo(postData, signal)
        if (res.data.total > 0) { 
          res.data.videoList.forEach((video) => {
            if (video.type == 'MP4' && video.length > 10) this.videoList.push(video) //  
          })
          if (res.data.total > 100) { // 預載全部資料
            let page = Math.ceil(res.data.total / 100)
            let index = 0
            for (let i=1; i<page; i++) {
              index = i * 100
              postData = { postId: [this.singleUrlUserID], startTimeGt: startTime, startTimeLt: stopTime, index: index, count: 100 }
              let rr = await apiSearchVideo(postData, signal)
              if (rr.data.total > 0) {
                rr.data.videoList.forEach((video) => {
                  if (video.type == 'MP4' && video.length > 10) this.videoList.push(video) //  
                })
              }
            }
          }
          this.reTimeAxis = false
        } else {
          //this.getBeforeAfterTime(startTime, stopTime)
          this.noStreaming = true
          this.$refs.timeAxis.removeTimeAxis()
        }
        res = null
      } catch (err) {
        console.log(err)
      }
    },
    async getBeforeTime(startTime) { // 往前抓歷史影片
      let tVideo = null
      let postData = Object
      postData = { postId: [this.singleUrlUserID], startTimeLt: startTime }
      let signal = { signal: this.abortController.signal }
      try {
        this.abortController.abort()
        let res = await apiSearchVideo(postData, signal)
        // console.log(res.data.total)
        if (res.data.total > 0) {
          // console.log(res.data.total + ", " + res.data.videoList[0].startTime)
          res.data.videoList.some((video) => {
            if (video.type == 'MP4' && video.length > 10) { //  
              // console.log(video.id + ", " + video.startTime+ ", " + video.length)
              tVideo = video
            }
            return (video.type == 'MP4' && video.length > 10) //  
          })
        }
        res = null
      } catch (err) {
        console.log(err)
      }
      if (tVideo) {
        // console.log(tVideo)
        let tID = tVideo.id
        let tim = new Date(this.utcToTaipeiTime(tVideo.startTime))
        let left = this.convertToUTC(new Date(tim).addMins(-18*60)) 
        let right = this.convertToUTC(new Date(tim).addMins(18*60)) 
        await this.getHistoryVideoList([left, right])
        let index = this.videoList.findIndex((item) => item.id == tID)
        // console.log(index)
        if (index >= 0) {
          this.videoIndex = index
          this.playHistVideo()
        }
      } else {
        this.videoCurrentSeconds = 0
        // this.$message.warning(this.$t('video_search_none')) // '查無歷史影片！'
        this.$notify({
          type: 'warning',
          message: this.$t('video_search_none') // '查無歷史影片！'
        })
      }
    },
    async getAfterTime(stopTime) { // 往後抓歷史影片
      let tVideo = null
      let postData = Object
      postData = { postId: [this.singleUrlUserID], startTimeGt: stopTime }
      let signal = { signal: this.abortController.signal }
      try {
        this.abortController.abort()
        let res = await apiSearchVideo(postData, signal)
        if (res.data.total > 0) {
          res.data.videoList.forEach((video) => {
            if (video.type == 'MP4' && video.length > 10) { //  
              tVideo = video
            }
          })
          if (res.data.total > 100) { // 預載全部資料
            let index = null
            // index = res.data.total - 100
            index = 0
            postData = { postId: [this.singleUrlUserID], startTimeGt: stopTime, index: index, count: 100 }
            let rr = await apiSearchVideo(postData, signal)
            if (rr.data.total > 0) {
              rr.data.videoList.forEach((video) => {
                if (video.type == 'MP4' && video.length > 10) { // 
                  tVideo = video
                }
              })
            }
          }
        }
        res = null
      } catch (err) {
        console.log(err)
      }
      if (tVideo) {
        // console.log(tVideo)
        let tID = tVideo.id
        let tim = new Date(this.utcToTaipeiTime(tVideo.startTime))
        let left = this.convertToUTC(new Date(tim).addMins(-24*60)) 
        let right = this.convertToUTC(new Date(tim).addMins(24*60)) 
        await this.getHistoryVideoList([left, right])
        let index = this.videoList.findIndex((item) => item.id == tID)
        // console.log(index)
        if (index >= 0) {
          this.videoIndex = index
          this.playHistVideo()
        }
      } else {
        this.videoCurrentSeconds = 0
        // this.$message.warning(this.$t('video_search_none')) // '查無歷史影片！'
        this.$notify({
          type: 'warning',
          message: this.$t('video_search_none') // '查無歷史影片！'
        })
      }
    },
    playPrevVideo() {
      if (
        this.videoList.length == 0 ||
        this.videoIndex == this.videoList.length - 1
      ) {
        let startTime = this.convertToUTC(new Date(this.$refs.timeAxis.pointerTim).addMins(-3))
        this.getBeforeTime(startTime)
        this.noStreaming = true
        this.$refs.timeAxis.removeTimeAxis()
        return
      }
      this.noStreaming = false
      this.videoIndex += 1 // videoIndex越大是越舊影片
      this.playHistVideo()
    },
    playNextVideo() {
      if (this.liveMode) return
      if (this.videoList.length == 0 || this.videoIndex <= 0) {
        //console.log(this.videoList.length, this.videoIndex, this.$refs.timeAxis.pointerTim)
        let stopTime = this.convertToUTC(new Date(this.$refs.timeAxis.pointerTim).addMins(3))
        //console.log(stopTime)
        this.getAfterTime(stopTime)
        this.noStreaming = true
        this.$refs.timeAxis.removeTimeAxis()
        return
      }
      this.noStreaming = false
      this.videoIndex -= 1
      this.playHistVideo()
    },
    playHlsVideo() {
      this.$store.commit('updateLiveMode', false)
      this.pause = false
      this.stopLiveVideo('playHlsVideo')
      this.loading = true
      let hls = new Hls()
      let stream = this.videoList[this.videoIndex].videoUrl
      let video = this.$refs["liveVideo"]
      hls.loadSource(stream)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play()
      });
    },
    playHistVideo(secDiff=null) {
      if (this.videoList[this.videoIndex].type=="HLS") {
        this.playHlsVideo()
      } else {
        try {
          this.$store.commit('updateLiveMode', false)
          this.pause = false
          let videoUrl = this.videoList[this.videoIndex].videoUrl
          /** 取得video對應的marker position & path */
          this.$store.dispatch('getVideoGpsMarker', {
            videoId: this.videoList[this.videoIndex].id,
            userId: this.videoList[this.videoIndex].user.id,
            userName: this.videoUser
          })
          this.stopLiveVideo('playHistVideo')
          this.loading = true

          this.$nextTick(() => {
            this.$refs.liveVideo.src = videoUrl
            if(secDiff) { 
              this.$refs.liveVideo.currentTime = secDiff 
            }
            let playPromise = this.$refs.liveVideo.play()
            this.$refs.liveVideo.playbackRate = this.playRate
            if (playPromise !== undefined) {
              playPromise.then(() => {
                // console.log('Result OK')
              }).catch(() => {
                //this.$message.warning('無法播放 ' + error)
                this.loading = false
              })
            }
          })
          
          // this.$refs.liveVideo.load()
          
        } catch (err) {
          this.loading = false
          console.log(err)
        }
      }
    },
    playLiveVideo(caller) {
      console.log(`[${caller}][playLiveVideo]`)
      this.loading = false
      this.videoIndex = -1
      if (!this.liveMseUrl) return
      this.shiftX = null // 換下一個直播時取消位移
      this.shiftY = null // 換下一個直播時取消位移
      this.$nextTick(() => {
        this.stopLiveVideo('playLiveVideo') // 須覆驗 WEB-550 的問題
        this.$store.commit('updateLiveMode', true)
        let ele = this.$refs.liveVideo
        this.player = new mse(ele, this.liveMseUrl)
        sleep(0)
        this.player.startup()
        ele.play()
        this.showRate = '1X'
        this.playRate = 1.0
        this.pause = false
      })
    },
    stopLiveVideo(caller) {
      try {
        console.log(`[${caller}][stopLiveVideo] player:`, this.player)
        if (this.player && this.player.close) {
          this.player?.close()
          this.player = null
        }
        if (this.$refs.liveVideo) this.$refs.liveVideo.pause()
      } catch (err) {
        console.error(`[${caller}][stopLiveVideo]`, err)
      }
    },
    playOrPause() {
      this.pause = !this.pause
      if (this.pause) {
        this.$refs.liveVideo.pause()
      } else {
        this.$refs.liveVideo.play()
      }
    },
    extendToFullscreen() {
      if (!this.fullScreenMode) {
        this.$refs.videoWrap.webkitRequestFullScreen()
      } else {
        document.webkitExitFullscreen()
      }
      this.fullScreenMode = !this.fullScreenMode
    },
    switchMuted() {
      this.bMuted = !this.bMuted
      if (this.bMuted) {
        this.preVolume = this.volume
        this.volume = 0
      } else {
        this.volume = this.preVolume
      }
      this.$refs.liveVideo.volume = this.volume
    },
    volumeChange() {
      this.$refs.liveVideo.volume = this.volume
    },
    // zoomIn() {
    //   this.zoomIndex =
    //     this.zoomIndex == this.zoomList.length - 1
    //       ? this.zoomList.length - 1
    //       : this.zoomIndex + 1
    // },
    // zoomOut() {
    //   this.zoomIndex = this.zoomIndex == 0 ? 0 : this.zoomIndex - 1
    //   // 若放大倍率為1時，取消位移
    //   if (this.zoomIndex == 0) {
    //     this.shiftX = null
    //     this.shiftY = null
    //   }
    // },
    selectZoom(value) {
      this.zoomRate = value
    },
    switchDeviceMode() {
      this.bLockDevice = !this.bLockDevice
      let deviceId = this.bLockDevice ? this.singleUrlUserID : ''

      this.$store.commit('updateLockDeviceId', deviceId)
    },
    switchLiveMode() {
      // 沒有直播或是已經在直播中 return
      // 無觀看即時影音權限 return
      if (!this.liveMseUrl || this.liveMode || this.permissionV2.liveView === 0) return
      this.$store.commit('updateLiveMode', true)
      this.playLiveVideo('switchLiveMode')
      // 切換成直播時 把畫出來的歷史影片長方形清掉 撈完後再重畫
      //d3.selectAll('.videoLine').remove()
      let left = this.convertToUTC(new Date().addMins(-24*60)) 
      let right = this.convertToUTC(new Date().addMins(24*60))
      this.getHistoryVideoList([left, right])
    },
    getVideoDragRange() {
      if (!this.$refs.liveVideo) return
      let parentW = this.$refs.wrapVideo.clientWidth
      let parentH = this.$refs.wrapVideo.clientHeight
      let parentRect = this.$refs.wrapVideo.getBoundingClientRect()
      let bcrRect = this.$refs.liveVideo.getBoundingClientRect()
      let zoom = this.zoomRate // this.zoomList[this.zoomIndex]

      let videoW, videoH, minX, maxX, minY, maxY
      if (parentW / parentH > 1.77777778) {
        // video佔滿高度
        videoH = parentH * zoom
        videoW = videoH * 1.77777778  // videoH * 16 / 9
      } else {
        // video佔滿寬度
        videoW = parentW * zoom
        videoH = videoW / 1.77777778
      }

      maxX = (parentRect.left - bcrRect.left - (videoW - parentW)) / zoom
      minY = (parentRect.top - bcrRect.top - (videoH - parentH)) / zoom
      minX = (parentRect.left - bcrRect.left) / zoom
      maxY = (parentRect.top - bcrRect.top) / zoom   

      if (parentW > videoW) {
        this.shiftX = null
      } else if (parentH > videoH) {
        this.shiftY = null
      } else {
        this.outerShift(minX, maxX, minY, maxY)
      }
      
      return { minX: minX, minY: minY, maxX: maxX, maxY: maxY, ratio: parentW / 1280 / zoom}
    },
    outerShift(minX, maxX, minY, maxY) {
      // 重新調整shiftX, shiftY，避免顯示非video區域
      // 因為在不同倍率下，minX會大於maxX，所以須先判斷何者較大
      if (minX < maxX) {
        if (this.shiftX < minX) this.shiftX = minX
        if (this.shiftX > maxX) this.shiftX = maxX
      } else {
        if (this.shiftX > minX) this.shiftX = minX
        if (this.shiftX < maxX) this.shiftX = maxX
      }

      if (this.shiftY < minY) this.shiftY = minY
      if (this.shiftY > maxY) this.shiftY = maxY
    },
    handleMouseDown(e) {
      e.preventDefault()
      this.dragRange = this.getVideoDragRange()
      this.bDrag = true

      if (this.shiftX == null) this.shiftX = (this.dragRange.minX + this.dragRange.maxX) / 2
      if (this.shiftY == null) this.shiftY = (this.dragRange.minY + this.dragRange.maxY) / 2

      this.startPosX = e.clientX
      this.startPosY = e.clientY
    },
    handleMouseMove(e) {
      // 當放大倍率為1時，不可以拖曳
      if (this.bDrag && this.zoomRate !== 1 /*this.zoomIndex !== 0*/) {
        let newX = this.shiftX + (e.clientX - this.startPosX) * this.dragRange.ratio
        let newY = this.shiftY + (e.clientY - this.startPosY) * this.dragRange.ratio
        this.shiftX = newX
        this.shiftY = newY

        this.outerShift(this.dragRange.minX, this.dragRange.maxX, this.dragRange.minY, this.dragRange.maxY)
        
        this.startPosX = e.clientX
        this.startPosY = e.clientY
      }
    },
    handleMouseUp() {
      this.bDrag = false
    },
    handleMouseOut() {
      this.bDrag = false
    },
    rateUpMouseOver() {
      this.rateUpActive = true
    },
    selectRate(label, value) {
      this.showRate = label
      this.playRate = value
      this.rateUpActive = !this.rateUpActive
      // document.getElementsByClassName('dropup-content')[0].style.display = 'none'
      if (value < 0) {
        this.rewind(1.0)
      } else {
        if (this.intervalRewind) clearInterval(this.intervalRewind)
        this.$refs.liveVideo.playbackRate = value
        this.pause = false
        this.$refs.liveVideo.play()
      }
    }, // 倒回播放功能
    rewind(rewindSpeed) {
      if (this.intervalRewind) clearInterval(this.intervalRewind)
      let video = this.$refs.liveVideo
      let startSystemTime = new Date().getTime()
      let startVideoTime = video.currentTime
      this.intervalRewind = setInterval(() => {
        video.playbackRate = 1.0
        if (video.currentTime < 0.1) {
          clearInterval(this.intervalRewind)
          video.pause()
          this.pause = true
          this.showRate = '1X'
          this.playRate = 1.0
        } else {
          let elapsed = new Date().getTime() - startSystemTime
          // log.textContent='Rewind Elapsed: '+elapsed.toFixed(3)
          video.currentTime = Math.max(
            startVideoTime - (elapsed * rewindSpeed) / 1000.0,
            0
          )
        }
      }, 30)
    },
    onLoadedMetaData() {
      if (!this.liveMode && this.$refs.liveVideo) {
        this.loading = false
        const videoDuration = Math.round(this.$refs.liveVideo.duration)
        this.duration = this.formatTime(videoDuration)
      }
    },
    onTimeUpdate() {
      if(this.$refs.liveVideo) {
        this.videoCurrentSeconds = Math.round(this.$refs.liveVideo.currentTime)
        if(!this.liveMode) {
          this.timeElapsed = this.formatTime(
            this.videoCurrentSeconds
          )
          if (
            this.timeElapsed.minutes == this.duration.minutes &&
            this.timeElapsed.seconds == this.duration.seconds
          ) {
            this.pause = true
          }
        }
      }
    },
    formatTime(timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)
      return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2)
      }
    },
    getFPS() {
      this.fpsInterval = setTimeout(() => {
        if (this.$refs.liveVideo) {
          let v = this.$refs.liveVideo
          if (this.videoHeight == 0 || this.videoHeight == '0p')
            this.videoHeight = v.videoHeight + 'p'
          let q = v.getVideoPlaybackQuality()
          let fps = 0
          if (this.cTime > 0) {
            let diffTime = (q.creationTime - this.cTime) / 1000
            let diffFrames =
              q.totalVideoFrames - q.droppedVideoFrames - this.vFrames
            fps = Math.round(diffFrames / diffTime)
          }
          this.cTime = q.creationTime
          this.vFrames = q.totalVideoFrames - q.droppedVideoFrames
          if (fps > 0) {
            this.videoFPS = fps
            const framerate = fps + ' fps'
            const resolution = v.videoWidth + 'x' + v.videoHeight
            this.updateFramerate(framerate)
            this.updateResolution(resolution)
          }
        }
        this.getFPS()
      }, 5000)
    },
    reloadHVLtimeout() {
      // 若是直播 60秒 重撈1天歷史影片
      this.reLoadHVL = setTimeout(() => {
        if (this.liveMode) {
          let left = this.convertToUTC(new Date().addMins(-24*60))
          let right = this.convertToUTC(new Date().addMins(24*60))
          this.getHistoryVideoList([left, right])
          let tim = new Date(this.liveStartTime).addSecs(this.videoCurrentSeconds)
          let liveTime = tim.getTime() / 1000
          let nowTime = new Date().getTime() / 1000
          let diffSecounds = Math.round(nowTime - liveTime)
          if (diffSecounds >= 30) { // 直播若 delay 30 sec以上 重設直播
            console.log(diffSecounds + " : 直播若 delay 30 sec以上 重設直播 " + moment().format('HH:mm:ss.SSS'))
            this.playLiveVideo('reloadHVLtimeout')
          } /* else {
            console.log(nowTime, "每分鐘直播誤差 : " + diffSecounds)
          }*/
        }
        this.reloadHVLtimeout()
      }, 60000)
    },
    downloadMP4() {
      if (this.permissionV2.video === 0) return
      this.isDownload = true
    },
    closeDownload() {
      this.isDownload = false
      this.updateIsShowVideoDownloadPanel(this.isDownload)
    },
    async backPlayVideo(vIdx, videoList) { // 從歷史影片下載 回來的 播放影片
      // this.liveMseUrl = false
      let tID = videoList[vIdx].id
      let tim = new Date(this.utcToTaipeiTime(videoList[vIdx].startTime))
      let left = this.convertToUTC(new Date(tim).addMins(-24*60)) 
      let right = this.convertToUTC(new Date(tim).addMins(24*60)) 
      await this.getHistoryVideoList([left, right])
      let index = this.videoList.findIndex((item) => item.id == tID)
      if (index >= 0) {
        this.videoIndex = index
        this.playHistVideo()
      }
    },
    setReTimeAxis(bool) {
      this.reTimeAxis = bool
    },

    // 拖拉左側設備進 1x1
    onDragDevEnter() {
      // console.log(`[onDragDevEnter] dragUser:`, this.dragUser)
    },
    onDragDevOver() {
      // 一定要有, 因為才可以觸發 drop 事件
      // console.log(`[onDragDevOver]`)
    },
    onDropDev() {
      const dragUser = { ...this.dragUser }

      // 拖拉左側設備 進 1x1 影像播放區
      const idx = this.selectedUsers.findIndex((user) => {
        return user.id === dragUser.id
      })
      const dropIdx = this.currentPage - 1
      // const dragIdx =
      if (idx >= 0) {
        // SWAP
        const oVal = this.selectedUsers[dropIdx].id
        const nVal = dragUser.id

        console.log(`[onDropDev] replace ${oVal} with ${nVal}`)
      } else {
        // 新增
        if (dropIdx >= this.selectedUsers.length) {
          this.$store.commit('addSelectedUser', dragUser)
        } else {
          this.$store.commit('insertSelectedUser', {
            insertIndex: dropIdx,
            user: dragUser
          })
          this.$store.commit('setDragUser', {})
        }
      }
      this.$store.commit('updateSingleUrlUserID', dragUser.id)

      let payload = {
        dragIndex: this.selectedUsers.findIndex((u) => u.id === dragUser.id),
        dropIndex: dropIdx
      }
      this.$store.commit('swapSelectedUsers', payload)

      // console.log(`[onDropDev] selectedUsers: `, this.selectedUsers.map((u) => u.id))
    }
  }
}
</script>

<style lang="scss" scoped>
.live-event-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.no-permission-all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $color_black_80;
  color: $color_FFF_80;
  font-size: px2rem(30);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .no-permission-icon {
    position: absolute;
    left: 50%;
    bottom: px2rem(15);
  }
}

.video-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.video-wrap.timeAxis {
  height: calc(100% - px2rem(90));
}

.no-stream-info {
  width: 100%;
  height: 100%;
  background-color: $color_black;
  color: $color_9D9D9D;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-message {
  // position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color_000;
  color: $color_FFF_50;
  font-size: 1.75rem;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

#live-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: var(--shift-x) var(--shift-y);
  background-color: $color_000;
  transform: scale(var(--zoom));
}

.video-info {
  position: absolute;
  // left: px2rem(10);
  top: var(--video-info-top);
  color: $color_FFF;
}

.live-info {
  display: flex;
  align-items: center;
}

.video-height-fps {
  margin-left: px2rem(12);
  font-size: 1rem;
}

.video-title {
  margin-left: 0.5rem;
  border-left: 1px solid $color_FFF;
  padding-left: 0.5rem;
}

.video-live-icon {
  border-radius: 0px px2rem(17) px2rem(17) 0px;
  width: px2rem(80);
  height: px2rem(34);
  line-height: px2rem(34);
  text-align: center;
  background-color: $color_9D9D9D;

  &.live {
    background-color: $color_4C68EB;
  }
}

video::-webkit-media-controls-enclosure {
  display: none;
}

.controls {
  position: absolute;
  width: 100%;
  height: px2rem(40);
  background-color: $color_000_20;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: px2rem(10);
  padding-right: px2rem(10);
  transition: opacity 0.1s ease-in;
  // opacity: 0;
  z-index: 3;
}

// .video-wrap:hover .controls {
//   opacity: 1;
// }

.left,
.right {
  display: flex;
  align-items: center;

  .zoom {
    margin-left: px2rem(calc(24 / 2));
    margin-left: 0.25rem;
  }

  .piple {
    margin-left: px2rem(6);
    margin-right: px2rem(6);
    border: 1px solid $color_FFF_40;
    height: px2rem(24);
  }
}

img {
  width: 100%;
  height: 100%;
}

.control-btn {
  cursor: pointer;
  width: px2rem(20);
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: px2rem(5) px2rem(6);
  border-radius: px2rem(4);
  transition: opacity 0.2s ease-out, scale 0.2s ease-out;
  transform: scale(1);

  &.live {
    width: px2rem(38);
    margin-right: 0;

    img {
      width: 100%;
    }
  }
}

.control-btn:hover {
  opacity: 1;
  transform: scale(1.2);
  background: $color_FFF_20;
}

.control-btn.skip-prev:hover  {
  opacity: 1;
  transform: scale(1.2) rotate(180deg);
}

.control-btn.disabled {
  opacity: 0.2;
  transform: scale(1);
  cursor: default;
  &:hover {
    background: unset;
  }
}

.control-btn img {
  width: px2rem(20);
  height: auto;
}

.skip-prev {
  width: px2rem(20);
  transform: rotate(180deg);
}

.skip-next {
  width: px2rem(20);
}

.muted {
  width: px2rem(20);
  // margin-right: px2rem(4);
}

.no-live {
  cursor: default;
}

.no-permission {
  filter: brightness(0) saturate(100%) invert(5%) sepia(7%) saturate(8%) hue-rotate(323deg) brightness(101%) contrast(81%);
  cursor: default;
}

.volume-icon {
  width: px2rem(24);
  height: px2rem(20);
}

.volume {
  -webkit-appearance: none;
  width: px2rem(80);
  height: 0.5em;
  margin-left: px2rem(12);
  position: relative;
  left: px2rem(-5);
  border-radius: px2rem(5);
  @include volume_color(--volume);
}

.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
  background-color: $color_FFF;
  position: relative;
}

.dropbtn {
  background-color: $color_FFF_20;
  color: var(--dropbtn-color);
  padding: px2rem(9) px2rem(14);
  font-size: px2rem(18);
  border: none;
}

.dropup {
  position: relative;
  display: inline-block;
  margin-right: px2rem(4);
}

.dropup-content {
  display: none;
  position: absolute;
  font-size: 1.125rem;
  color: var(--rateup-color);
  background-color: $color_black_40;
  min-width: px2rem(50);
  bottom: px2rem(39);
  z-index: 1;
}

.dropup-content a {
  font-size: 1.125rem;
  color: var(--rateup-color);
  padding: px2rem(2) px2rem(6);
  text-decoration: none;
  text-align: center;
  display: block;
}

.dropup-content a:hover {
  background-color: $color_C9C9C9;
}

.dropup:hover .dropup-content {
  display: block;
}

.dropup:hover .dropbtn {
  background-color: $color_FFF_20;
}

.time span {
  color: $color_FFF;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to, .fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 1680px) {
  .volume {
    width: px2rem(50);
  }

  .dropup-content {
    font-size: 1rem;
    min-width: px2rem(40);
  }
}
</style>
