<template>
  <div class="aibox-table" v-click-outside="onClickOutside">
    <div class="header">
      <div class="col">{{ $t('ai_box')/*辨識盒*/ }}</div>
      <!-- <div class="col">{{ $t('ai_temperature')/*溫度*/ }}</div> -->
      <div class="col">CPU</div>
      <div class="col">GPU</div>
      <div class="col">{{ $t('ai_memory')/*記憶體*/ }}</div>
      <div class="col">{{ $t('ai_hard_disk')/*硬碟*/ }}</div>
      <div class="col">{{ $t('ai_resources')/*辨識資源*/ }}</div>
      <div class="col">{{ $t('ai_update_time')/*更新時間*/ }}</div>
      <div v-if="!showAll && mode === 'edit'" class="drop" @click="toggleList"><img src="@/assets/icons/Drop.svg" alt=""></div>
    </div>
    <div class="content" @click="toggleList">
      <div v-for="(item, index) in useAiBox" :key="index" class="box-item">
        <div class="col">
          <!-- <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" :content="item.name" placement="bottom"> -->
            <div class="name">{{ item.name }}</div>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" :content="item.udid" placement="bottom"> -->
            <div class="udid">{{ item.udid }}</div>
          <!-- </el-tooltip> -->
        </div>
        <!-- <div class="col" :class="{ green: item.temperature <= 70, 
          orange: item.temperature > 70 && item.temperature <= 90,
          red: item.temperature > 90 }">{{ item.temperature }} &#8451;
        </div> -->
        <div class="col">
          <DonutChart :value="item.cpuUsage" />
        </div>
        <div class="col">
          <DonutChart :value="item.gpuUsage" />
        </div>
        <div class="col">
          <DonutChart :value="getIntPercent(item.totalMamory, item.availableMemory)" :free="item.availableMemory" :total="item.totalMamory" />
        </div>
        <div class="col">
          <DonutChart :value="getIntPercent(item.totalSpace, item.freeSpace)" :free="item.freeSpace" :total="item.totalSpace" />
        </div>
        <div class="col">
          <div class="value">
            <img src="@/assets/icons/object.svg" alt="">
            {{ getResourceInfo(item) }}
          </div>
        </div>
        <div class="col">
          <div
            class="value ago"
            :class="{
              orange:
                /*diffSecs(item.updatedTime)*/ item.timeSinceLastUpdated > 30 &&
                /*diffSecs(item.updatedTime)*/ item.timeSinceLastUpdated <= 60,
              red: /*diffSecs(item.updatedTime)*/ item.timeSinceLastUpdated > 60
            }"
          >
            {{ timeAgo(item.updatedTime, item.timeSinceLastUpdated) }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAll" class="float" @click="toggleList">
      <div v-for="(item, index) in showList" :key="index" class="box-item" :class="{ full: isFull(item)}" @click="setAiBox(item)">
        <div class="col">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="item.name" placement="bottom">
            <div class="name">{{ item.name }}</div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="item.udid" placement="bottom">
            <div class="udid">{{ item.udid }}</div>
          </el-tooltip>
        </div>
        <!-- <div class="col" :class="{ green: item.temperature <= 70, 
          orange: item.temperature > 70 && item.temperature <= 90,
          red: item.temperature > 90 }">{{ item.temperature }} &#8451;
        </div> -->
        <div class="col">
          <DonutChart :value="item.cpuUsage" />
        </div>
        <div class="col">
          <DonutChart :value="item.gpuUsage" />
        </div>
        <div class="col">
          <DonutChart :value="getIntPercent(item.totalMamory, item.availableMemory)" :free="item.availableMemory" :total="item.totalMamory" />
        </div>
        <div class="col">
          <DonutChart :value="getIntPercent(item.totalSpace, item.freeSpace)" :free="item.freeSpace" :total="item.totalSpace" />
        </div>
        <div class="col">
          <div class="value">
            <img src="@/assets/icons/object.svg" alt="">
            {{ getResourceInfo(item) }}
          </div>
        </div>
        <div class="col">
          <div
            class="value ago"
            :class="{
              orange:
                /*diffSecs(item.updatedTime)*/ item.timeSinceLastUpdated > 30 &&
                /*diffSecs(item.updatedTime)*/ item.timeSinceLastUpdated <= 60,
              red: /*diffSecs(item.updatedTime)*/ item.timeSinceLastUpdated > 60
            }"
          >
            {{ timeAgo(item.updatedTime, item.timeSinceLastUpdated) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import DonutChart from './base/DonutChart.vue'
import { diffSecs, timeAgo } from '@/utils/lib.js'
import vClickOutside from 'v-click-outside'

export default {
  name: 'AiboxTable',
  components: { DonutChart },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    ...mapState('aibox', ['mode', 'aiBoxes']),
    ...mapGetters('aibox', ['aiBoxTask']),
    useAiBox() {
      return this.aiBoxes.filter(item => item.id === this.aiBoxTask.aiboxId)
    },
    showList() {
      // 將使用的辨識盒放在第一個
      const useBox = this.aiBoxes.filter(item => item.id === this.aiBoxTask.aiboxId)
      if (this.showAll) {
        let retList = this.aiBoxes.filter(item => item.id !== this.aiBoxTask.aiboxId)
        retList.unshift(useBox[0])
        return retList
      } else
        return useBox
    },
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    ...mapMutations('aibox', ['updateTaskAiBox']),
    ...mapActions('aibox', ['setTaskAiBox']),
    diffSecs,
    timeAgo,
    getIntPercent(total, free) {
      let usage =  Math.ceil((total - free) * 100 / total)
      return usage
    },
    getResourceInfo(aiBox) {
      let info = ''
      if (this.aiBoxTask.ai === 'or') 
        info = `${aiBox.orUsedResouces} / ${aiBox.orCapability}`
      else
        info = `${aiBox.lprUsedResouces} / ${aiBox.lprCapability}`
      return info
    },
    isFull(aiBox) {
      let bFull = false
      if (this.aiBoxTask.ai === 'or')
        bFull = aiBox.orUsedResouces === aiBox.orCapability
      else
        bFull = aiBox.lprUsedResouces === aiBox.lprCapability
      return bFull
    },
    toggleList() {
      if (this.mode === 'view') return
      this.showAll = !this.showAll
    },
    setAiBox(aibox) {
      if (this.isFull(aibox)) return
      this.updateTaskAiBox({
        id: aibox.id,
        name: aibox.name,
      })
    },
    onClickOutside() {
      this.showAll = false
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  user-select: none;
}
.aibox-table {
  width: 100%;
  color: #ffffff; 
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  background: #151B35;
  margin-bottom: 12px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.header {
  display: flex;
  background: #4A5C78;
  padding-top: 9px;
  padding-bottom: 9px;
  position: relative;
}

.content {
  width: 100%;
  overflow: overlay;
}

.show-all {
  height: calc(60% - 50px);
}

.col {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .col:nth-child(1) {
  padding-left: 1.9%;
  width: 29.78%;
} */

/* 溫度先隱藏，將其寬度移到第一欄 */
/* .col:nth-child(2) {
  width: 8.9%;
} */

.col:nth-child(1) {
  padding-left: 1.9%;
  width: 38.68%;
}

.col:nth-child(2) {
  width: 9.4%;
}

.col:nth-child(3) {
  width: 9.4%;
}

.col:nth-child(4) {
  width: 9.4%;
}

.col:nth-child(5) {
  width: 8.7%;
}

.col:nth-child(6) {
  width: 10.52%;
  
}

.col:nth-child(7) {
  width: 12%;
}

.col:nth-child(6) .value {
  font-size: 18px;
}

.col:nth-child(6) .value img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  position: relative;
  top: 3px;
}

.ago {
  font-size: 20px;
  color: #8CC24D;
}

.name {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}

.udid {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  color: #ffffff80;
}

.green {
  color: #64D848;
}

.orange {
  color: #D8A848;
}

.red {
  color: #F94144;
}

.drop {
  position: absolute;
  top: 94px;
  right: 24px;
  cursor: pointer;
}

.box-item {
  display: flex;
  align-items: center;
  background: #151B35;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}

.full {
  opacity: 0.4;
  cursor: not-allowed;
}

.float {
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  max-height: 550px;
  overflow: overlay;
  background: #151b34;
  z-index: 11;
}

.box-item:hover {
  background: #6E7D9333;
}

.box-item:last-child {
  border-radius: 0px 0px 8px 8px;
}


</style>