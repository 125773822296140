<template>
  <div v-if="aiBoxTask" class="recognition-edit">
    <AiboxTable :data="aiBoxes" />
    <div class="container-center">
      <div class="image">
        <VideoCanvas />
      </div>
      <div class="settings">
        <div class="parameters">
          <div class="set-item">
            <div class="header">
              <div class="name">{{ $t('ai_mode')/*模式*/ }}</div>
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="tooltipCfgMode" placement="top">
                <div class="info"><img src="@/assets/icons/info.svg" alt=""></div>
              </el-tooltip>
            </div>
            <div class="modes">
              <div class="mode" :class="{ 'mode-active': aiBoxTask.config.mode === 1 }" @click="setDetectMode(1)">{{ $t('ai_object_detect')/*物件偵測*/ }}</div>
              <div class="mode" :class="{ 'mode-active': aiBoxTask.config.mode === 2 }" @click="setDetectMode(2)">{{ $t('ai_motion_detect')/*移動偵測*/ }}</div>
            </div>
          </div>
          <template v-if="aiBoxTask.config.mode === 1">
            <div class="header">
              <div class="name">{{ $t('ai_detect_target_threshold')/*偵測目標閾值*/ }}</div>
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_threshold')" placement="top">
                <div class="info"><img src="@/assets/icons/info.svg" alt=""></div>
              </el-tooltip>
            </div>
            <div v-for="item in levelList" :key="item.key" class="set-item">
            <div class="header">
              <div class="name" @click="handleCheck(item.key)">
                <div class="check" :class="{ checked: aiBoxTask.config[cfgMode][item.key] > 0 }"></div>
                <img :src="iconDict[item.key]" alt="">
                {{ item.label }}
              </div>
              <div class="info">{{ $t(`ai_level_${aiBoxTask.config[cfgMode][item.key]}`) }}</div>
            </div>
            <div class="range">
              <InputRange :min="minLevel" :max="maxLevel" v-model="aiBoxTask.config[cfgMode][item.key]" />
            </div>
          </div>
          </template>
          <div v-else class="set-item">
            <div class="header">
              <div class="name">{{ $t('ai_motion_level')/*偵測靈敏度*/ }}</div>
              <div class="info">
                {{ $t(`ai_motion_level_${aiBoxTask.config.md.motionLevel}`) }}
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_motion')" placement="top">
                  <img src="@/assets/icons/info.svg" alt="">
                </el-tooltip>
              </div>
            </div>
            <div class="range">
              <InputRange type="motion" :min="minMotionLevel" :max="maxMotionLevel" v-model="aiBoxTask.config.md.motionLevel" />
            </div>
          </div>
          <div class="adv-header" :class="{ 'arrow-right': !showAdvSettings }" @click="showAdvSettings = !showAdvSettings">
            <img src="@/assets/icons/Arrow_down.svg" alt="">{{ $t('ai_advanced_settings')/*進階設定*/ }}
          </div>
          <div v-if="showAdvSettings" class="set-item">
            <div class="header">
              <div class="name">{{ $t('ai_recg_fps_level')/*辨識頻率*/ }}</div>
              <div class="info">
                {{ $t(`ai_level_${aiBoxTask.config[cfgMode].recgFpsLevel}`) }}
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_recg_fps_level')" placement="top">
                  <img src="@/assets/icons/info.svg" alt="">
                </el-tooltip>
              </div>
            </div>
            <div class="range">
              <InputRange :min="minLevel" :max="maxLevel" v-model="aiBoxTask.config[cfgMode].recgFpsLevel" />
            </div>
          </div>
          <div v-if="showAdvSettings" class="set-item">
            <div class="header">
              <div class="name">{{ $t('ai_repeat_interval')/*重複事件間隔*/ }}</div>
              <div class="info">
                {{ aiBoxTask.config[cfgMode].repeatInterval }}{{ $t('min') }}
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_repeat_interval')" placement="top">
                  <img src="@/assets/icons/info.svg" alt="">
                </el-tooltip>
              </div>
            </div>
            <div class="select">
              <InputRange :min="minInterval" :max="maxInterval" type="value" v-model="aiBoxTask.config[cfgMode].repeatInterval" />
            </div>
          </div>
          <div v-if="showAdvSettings" class="set-item">
            <div class="header">
              <div class="name">{{ $t('ai_stay_sec')/*停留秒數*/ }}</div>
              <div class="info">
                {{ aiBoxTask.config[cfgMode].staySec }}{{ $t('sec') }}
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_stay_sec')" placement="top">
                  <img src="@/assets/icons/info.svg" alt="">
                </el-tooltip>
              </div>
            </div>
            <div class="select">
              <InputRange :min="minStay" :max="maxStay" type="value" v-model="aiBoxTask.config[cfgMode].staySec" />
            </div>
          </div>
        </div>
        <div class="warning-area">
          <div class="container-roi-mode">
            <div class="label">{{ $t('ai_warning_area')/*警戒區*/ }}
              <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_warning_area')" placement="top">
                <img src="@/assets/icons/info.svg" alt="">
              </el-tooltip>
            </div>
            <div class="roi-mode" @click="toggleEditRoi()">
              <div v-if="editRoi" class="roi-lock">
                <img src="@/assets/icons/roi-lock.svg" alt="">
                <span>{{ $t('ai_roi_lock')/*鎖定辨識範圍*/ }}</span>
                <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('ai_tooltip_roi_reset')" placement="top">
                  <div class="roi-refresh" @click.stop="onRoiReset">
                    <img src="@/assets/icons/roi-refresh.svg" alt="">
                  </div>
                </el-tooltip>
              </div>
              <div v-else class="roi-edit">
                <img src="@/assets/icons/roi-edit.svg" alt=""> 
                <span>{{ $t('ai_roi_set')/*調整辨識範圍*/ }}</span>
              </div>
            </div>
          </div>
          
          <div class="btns">
            <div class="btn" @click="setArea(1)">
              <div class="set-area" v-if="setAreaNo === 1 || aiBoxTask.config.area1.length > 0">
                <div class="area-close" @click.stop="removeArea(1)">
                  <img src="@/assets/icons/close.svg" alt="">
                </div>
                {{ $t('ai_area1')/*區域一*/ }}
              </div>
              <img v-else src="@/assets/icons/plus.svg" alt="">
            </div>
            <div class="btn" @click="setArea(2)">
              <div class="set-area" v-if="setAreaNo === 2 || aiBoxTask.config.area2.length > 0">
                <div class="area-close" @click.stop="removeArea(2)">
                  <img src="@/assets/icons/close.svg" alt="">
                </div>
                {{ $t('ai_area2')/*區域二*/ }}
              </div>
              <img v-else src="@/assets/icons/plus.svg" alt="">
            </div>
            <div class="btn" @click="setArea(3)">
              <div class="set-area" v-if="setAreaNo === 3 || aiBoxTask.config.area3.length > 0">
                <div class="area-close" @click.stop="removeArea(3)">
                  <img src="@/assets/icons/close.svg" alt="">
                </div>
                {{ $t('ai_area3')/*區域三*/ }}
              </div>
              <img v-else src="@/assets/icons/plus.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-bottom">
      <div class="receiver">
        <div class="receiver-header">
          <div class="receiver-search">
            <div class="receiver-label">{{ $t('ai_receiver')/*接收人*/ }}</div>
            <div class="receiver-input">
              <input type="text" v-model="filterReceiver" :placeholder="$t('ai_unit_name_placeholder')" spellcheck="false">
              <div v-if="filterReceiver.length > 0" class="clear" @click="clearInput">
                <img src="@/assets/icons/clear.svg" alt="">
              </div>
              <img src="@/assets/icons/feather-search-gray.svg" alt="">
            </div>
          </div>
          <InputSwitch v-model="showChecked" :label="$t('ai_show_checked')" />
        </div>
        <div class="receiver-tree">
          <el-tree
            ref="tree" 
            :data="userTreeList" 
            :props="defaultProps"
            show-checkbox
            node-key="id" 
            :empty-text="$t('tree_no_data')" 
            :default-expanded-keys="expandKeys"
            :filter-node-method="filterNode"
          >
            <div
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <div
                class="label"
                :class="{ account: !data.children }"
              >
                <span>{{ node.label }}</span>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="remark">
        <div class="remark-title">{{ $t('ai_notice')/*備註說明*/ }}</div>
        <div class="remark-content">
          <p ref="notice" contenteditable="true" @input="onNoticInput"></p>
        </div>
        <div class="actions">
          <div class="cancel-btn" @click="cancelTaskEdit">{{ $t('cancel')/*取消*/ }}</div>
          <div class="confirm-btn" @click="handleTaskEdit">{{ $t('confirm')/*確認*/ }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AiboxTable from '@/components/AiBox/AiboxTable.vue'
import VideoCanvas from '@/components/AiBox/base/VideoCanvas.vue'
import InputRange from '@/components/AiBox/base/InputRange.vue'
import InputSwitch from '@/components/AiBox/base/InputSwitch.vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { apiPostAiBoxTaskSubscriber, apiDeleteAiBoxTaskSubscriber } from '@/api/index.js'

export default {
  name: 'RecognitionEdit',
  components: { AiboxTable, VideoCanvas, InputRange, InputSwitch },
  data() {
    return {
      showAdvSettings: true,
      iconDict: {
        personLevel: require('@/assets/icons/person.svg'),
        carLevel: require('@/assets/icons/car.svg'),
        bikeLevel: require('@/assets/icons/motor.svg'),
        truckLevel: require('@/assets/icons/truck.svg'),
        busLevel: require('@/assets/icons/bus.svg'),
        motionLevel: null
      },
      orList: [
        { key: 'personLevel', label: this.$t('ai_person_level') },
        { key: 'carLevel', label: this.$t('ai_car_level') },
        { key: 'bikeLevel', label: this.$t('ai_bike_level') },
        { key: 'truckLevel', label: this.$t('ai_truck_level') },
        { key: 'busLevel', label: this.$t('ai_bus_level') },
      ],
      mdList: [
        { key: 'motionLevel', label: this.$t('ai_motion_level') }
      ],
      showChecked: false,
      filterReceiver: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      minLevel: 1,
      maxLevel: 5,
      minInterval: 1,
      maxInterval: 60,
      minStay: 0,
      maxStay: 60,
      minMotionLevel: 1,
      maxMotionLevel: 3,

      expandKeys: [],
      editUsers: [],
    }
  },
  computed: {
    ...mapState(['groupList']),
    ...mapState('aibox', ['aiBoxes', 'userTreeList', 'editRoi', 'polygonMode', 'setAreaNo']),
    ...mapGetters('aibox', ['aiBoxTask']),
    cfgMode() {
      if (this.aiBoxTask.config.mode === 1) return 'or'
      else return 'md'
    },
    levelList() {
      if (this.aiBoxTask.config.mode === 1) return this.orList
      else return this.mdList
    }, 
    tooltipCfgMode() {
      if (this.aiBoxTask.config.mode === 1) return this.$t('ai_tooltip_or')
      else return this.$t('ai_tooltip_md')
    }
  },
  watch: {
    'aiBoxTask.id'() {
      this.initNoticeTree()
    },
    filterReceiver(val) {
      this.$refs.tree.filter(val)
    },
    showChecked(val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
    this.initNoticeTree()
  },
  methods: {
    ...mapMutations('aibox', [
      'updateMode', 
      'updateParamAiboxPortal', 
      'updateShowAiboxPortal',
      'updateEditRoi',
      'updatePolygonMode',
      'updateSetAreaNo',
    ]),
    ...mapActions('aibox', ['getAiBoxTasks', 'addOrUpdateAiBoxTask', 'resetRoi']),
    setDetectMode(mode) {
      this.aiBoxTask.config.mode = mode
    },
    initNoticeTree() {
      if (this.aiBoxTask && this.$refs.notice) {
        this.$refs.notice.innerText = this.aiBoxTask.notice

        this.expandKeys = [this.userTreeList[0].id]
        
        // node-key設定為id, 重新勾選起來要用id，這邊資料對應有點亂要仔細
        let checkedNodeIds = this.aiBoxTask.subscribers.map(data => {
          return data.userAccount
        })
        this.$refs.tree.setCheckedKeys(checkedNodeIds, true)
      }
    },
    clearInput() {
      this.filterReceiver = ''
    },
    filterNode(val, data) {
      let checkedNodes = this.$refs.tree.getCheckedNodes()
      let checked = true
      if (this.showChecked) {
        checked = checkedNodes.findIndex(node => node.id === data.id) >= 0
      }

      let filtered = true
      if (this.filterReceiver !== '') {
        const filter = this.filterReceiver.toLowerCase()
        filtered = data.label.toLowerCase().includes(filter)
        const groupName = this.groupList.find(g => g.id === data.groupId).name
        filtered = filtered || groupName.toLowerCase().includes(filter)
      }
      return checked && filtered
    },
    handleCheck(key) {
      if (this.aiBoxTask.config[this.cfgMode][key] > 0) this.aiBoxTask.config[this.cfgMode][key] = 0
      else this.aiBoxTask.config[this.cfgMode][key] = 3
    },
    onNoticInput(e) {
      this.aiBoxTask.notice = e.target.innerText
    },
    cancelTaskEdit() {
      // 出現cancelEdit跳窗詢問，確認後的動作為切換模式到檢視模式
      this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'switchMode' })
      this.updateShowAiboxPortal(true)
    },
    async handleTaskEdit() {
      this.keepEditUsers()
      await this.addOrUpdateAiBoxTask()
      await this.editSubscribers()
      // 重新取得AiBoxTasks
      this.getAiBoxTasks()
      this.updateMode('view')
    },
    keepEditUsers() {
      // 再確認時先取得須編輯的帳號，因為後續更新可能會蓋掉要設定的資料
      this.editUsers = []
      let checkedNodes = this.$refs.tree.getCheckedNodes()

      // user若有children表示為群組，user.childre=[]表示為空群組
      checkedNodes.forEach(user => {
        if (!user.children && this.aiBoxTask.subscribers.findIndex(item => item.userId === user.index) === -1) {
          // 應應弱掃：直接使用 method: 'post'|'delete', 會被認為有 CSRF 風險
          // 這段程式碼確實存在潛在的 Cross-Site Request Forgery (CSRF) 風險，因為它可能允許未經授權的用戶進行POST請求。
          // 要優化這段程式碼以防止CSRF攻擊，可以考慮使用CSRF令牌來確保僅允許授權的用戶執行POST請求
          // this.editUsers.push({ id: user.index, method: 'post' })
          this.editUsers.push({ id: user.index, method: apiPostAiBoxTaskSubscriber })
        }
      })
      
      this.aiBoxTask.subscribers.forEach(user => {
        if (checkedNodes.findIndex(node => !node.children && node.index === user.userId) === -1) {
          // this.editUsers.push({ id: user.userId, method: 'delete' })
          this.editUsers.push({ id: user.userId, method: apiDeleteAiBoxTaskSubscriber })
        }
      })
    },
    async editSubscribers() {  
      const results = await Promise.all(this.editUsers.map(async(user) =>{
        // if (user.method === 'post')
        //   return await apiPostAiBoxTaskSubscriber(this.aiBoxTask.id, user.id)
        // else
        //   return await apiDeleteAiBoxTaskSubscriber(this.aiBoxTask.id, user.id)
        return await user.method(this.aiBoxTask.id, user.id)
      }))

      let nSuccess = 0
      let nFailed = 0
      results.forEach(res => {
        if (res.status === 200) nSuccess++
        else nFailed++
      })
      
      if (nSuccess !== 0 || nFailed !== 0 ) {
        this.$notify({
          title: `${this.$t('ai_edit_receiver') /*編輯接收人*/}`,
          message: `${nSuccess}${this.$t('account_n_success')}，${nFailed}${this.$t('account_n_failed')}。`,
          type: 'success',
        })
      }
    },
    setArea(no) {
      // 警戒區若以設定點，需要先清空，才能重新設定
      if (this.aiBoxTask.config[`area${no}`].length === 0) {
        this.updatePolygonMode(true)
        this.updateSetAreaNo(no)
      }
    },
    removeArea(no) {
      this.aiBoxTask.config[`area${no}`] = []
      this.updateSetAreaNo(-1)
    },
    toggleEditRoi() {
      this.updateEditRoi(!this.editRoi)
    },
    onRoiReset() {
      this.resetRoi()
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.recognition-edit {
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  padding: 0px 36px 12px 28px;
}

.container-center {
  width: 100%;
  height: calc((100% - 190px) * 0.6);
  display: flex;
  margin-bottom: 12px;
}

.image {
  width: 70%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  outline: #4A5C78 1px solid;
}

.settings {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.parameters {
  flex: 1;
  background: #151B35f5;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  overflow: overlay;
}

.set-item {
  background: #4A5C7866;
  border-radius: 8px;
  margin-bottom: 12px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 6px 12px;
  
}

.name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.name img {
  margin-right: 8px;
}

.check {
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  margin-right: 12px;
  cursor: pointer;
}

.check::before {
  content: '';
  position: absolute;
  width: 7px;
  height: 12px;
  border-right: 2px solid #19223B;
  border-bottom: 2px solid #19223B;
  box-sizing: border-box;
  left: 4px;
  top: 0px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.checked {
  background: #FFC600;
  border-color: #FFC600;
}

.check.checked::before {
  transform: scale(1) rotate(45deg);
}

.range {
  padding: 12px;
}

.set-item:not(:first-child):not(:last-child) .header {
  border-bottom: 1px solid #4A5C78;
}

.select {
  padding: 6px 12px;
}

.modes {
  display: flex;
  padding: 5px 12px 12px 12px;
}

.mode {
  width: 50%;
  border: 1px solid #FFFFFF80;
  text-align: center;
  font-size: 20px;
  line-height: 36px;
}

.mode-active {
  background: #FFC600;
  color: #282942;
}

.mode:nth-child(1) {
  border-radius: 8px 0px 0px 8px;
}

.mode:nth-child(2) {
  border-left: none;
  border-radius: 0px 8px 8px 0px;
}

.info img {
  margin-left: 12px;
}

.adv-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.adv-header img {
  margin-right: 12px;
  transition: all 0.3s;
}  

.adv-header.arrow-right img {
  transform: rotate(-90deg);
}

.container-roi-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.label {
  display: flex;
  align-items: center;
}

.label img {
  margin-left: 12px;
}

.roi-edit, .roi-lock {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
}

.roi-edit span {
  padding-left: 6px;
}

.roi-lock span {
  padding-left: 10px;
  padding-right: 12px;
  color: #FFC600;
}

.roi-refresh {
  height: 24px;
  padding-left: 12px;
  border-left: 1px solid #ffffff;
}

.btns {
  display: flex;
  flex-wrap: wrap;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 37px;
  margin-bottom: 4px;
  border-radius: 9px;
  font-size: 20px;
  white-space: nowrap;
  cursor: pointer;
}

.btn img {
  width: 20px;
  height: 20px;
}

.btn:not(:last-child) {
  margin-right: 8px;
}

.btn:nth-child(1) {
  background: #F94144;
}

.btn:nth-child(2) {
  background: #F99D41;
}

.btn:nth-child(3) {
  background: #4361EE;
}

.btn:nth-child(1) .set-area .area-close {
  background: #fa7678;
}

.btn:nth-child(2) .set-area .area-close {
  background: #feb66e;
}

.btn:nth-child(3) .set-area .area-close {
  background: #7790ff;
}

.set-area {
  display: flex;
  align-items: center;
}

.area-close {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f56769;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;
}

.area-close img {
  width: 14px;
  height: 14px;
}

.container-bottom {
  width: 100%;
  height: calc((100% - 190px) * 0.4);
  display: flex;
}

.receiver {
  display: flex;
  flex-direction: column;
  width: calc(70% - 8px);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.receiver-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 4px;
  margin-right: 8px;
}

.receiver-search {
  width: 72%;
  display: flex;
}

.receiver-label {
  padding: 6px 32px;
  color: #ffffff;
  background: #282942;
  border: 1px solid #9D9D9D;
  border-radius: 8px 0px 0px 8px;
}

.receiver-input {
  flex: 1;
  position: relative;
}

.receiver-input input {
  width: 100%;
  font-size: 24px;
  font-weight: 300;
  line-height: 48px;
  padding-left: 24px;
  padding-right: 48px;
  outline: none;
  border: none;
  border-radius: 0px 8px 8px 0px;
}

.clear {
  position: absolute;
  right: 34px;
  top: 4px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.clear img {
  width: 100%;
  height: 100%;
  filter: brightness(0) saturate(100%) invert(39%) sepia(14%) saturate(15%) hue-rotate(331deg) brightness(98%) contrast(91%);
}

.clear:hover {
  filter: brightness(0) saturate(100%) invert(64%) sepia(82%) saturate(741%) hue-rotate(358deg) brightness(108%) contrast(107%);
}

.receiver-input img {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
}

::placeholder {
  color: #9D9D9D;
}

.receiver-tree {
  width: 100%;
  height: calc(100% - 54px);
  /* flex: 1; */
  overflow: overlay;
}

.remark {
  width: 30%;
  margin-left: 16px;
  background: #4A5C7833;
}

.remark-title {
  background: #282942;
  border: 1px solid #9D9D9D;
  border-radius: 8px 8px 0px 0px;
  padding: 10px 12px;
}

.remark-content {
  width: 100%;
  height: calc(100% - 122px);
  overflow: overlay;
  padding: 12px;
  background: #FFFFFF;
  color: #191919;
  border: 1px solid #9D9D9D;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 12px;
}

.remark-content p {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

.actions {
  display: flex;
  /* padding-left: 24px;
  padding-right: 24px; */
}

.cancel-btn {
  flex: 1;
  color: #FFC600;
  border: 1px solid #FFC600;
  border-radius: 9px;
  text-align: center;
  margin-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.cancel-btn:hover {
  background: #FFC60033;
}

.confirm-btn {
  flex: 1;
  background: #FFC600;
  color: #282942;
  border-radius: 9px;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.confirm-btn:hover {
  background: #FFB703;
}

/* el-tree */
::v-deep .el-tree {
  border: 1px solid #9D9D9D;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: overlay;
}

::v-deep .el-tree-node {
  white-space: normal;
}

::v-deep .el-tree-node__content {
  height: 100%;
  padding: 2px 0;
}

::v-deep .el-tree-node__label {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  color: #191919;
}

/* 調整三角形大小 */
::v-deep .el-tree-node__expand-icon {
  font-size: 20px;
}

/* 調整三角形顏色需排除 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) {
  color: #191919;
}

/* 父層文字顏色 */
::v-deep .el-tree-node__expand-icon:not(.is-leaf) ~ .el-tree-node__label {
  color: #4361EE;
}

.custom-tree-node .label {
  color: #4361EE;
}

.custom-tree-node .label.account {
  color: #191919;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, 
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #FFC600;
  border-color: #FFC600;
}

::v-deep .el-checkbox__inner {
  border-color: #191919;
}

@media screen and (max-width: 1600px) {
  .recognition-edit {
    font-size: 20px;
  }
}

</style>