<template>
  <div class="video-zoom-btn-wrap">
    <div class="options-wrap" v-if="showOptions" v-click-outside="() => showOptions = false">
      <div class="option"
        v-for="item in zoomList"
        :key="item.value"
        :class="{ selected: item.value === currZoom?.value }"
        @click="onSetZoom(item)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
    <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t(tooltip)" :placement="tooltipPlacement">
      <div class="zoom-btn-wrap">
        <!-- zoom-btn-wrap：阻擋 el-tooltip 被 button disabled 影響 hover 不顯示 tooltip -->
        <button class="zoom-btn" :disabled="disabled" @click="onOpenOptions">
          <div class="zoom-icon">
            <img src="@/assets/icons/zoom-in.svg" />
          </div>
          <span class="zoom-value">{{ currZoom?.label }}</span>
          <img class="zoom-toggle" src="@/assets/icons/Drop.svg" :class="{down: showOptions}"/>
        </button>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'VideoZoomBtn',
  components: {},
  directives: {
    clickOutside: vClickOutside.directive // use: v-click-out="xxx"
  },
  props: {
    tooltip: {
      type: String,
      default: null
    },
    tooltipPlacement: {
      type: String,
      default: 'top'
    },
    zoomList: {
      type: Array,
      default() {
        return [
          { label: '3.0X', value: 3.0 },
          { label: '2.75X', value: 2.75 },
          { label: '2.5X', value: 2.5 },
          { label: '2.25X', value: 2.25 },
          { label: '2.0X', value: 2.0 },
          { label: '1.75X', value: 1.75 },
          { label: '1.5X', value: 1.5 },
          { label: '1.25X', value: 1.25 },
          { label: '1.0X', value: 1.0 }
        ]
      }
    },
    value: {
      type: Number,
      default: 1.0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOptions: false,
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    currZoom() {
      return this.zoomList.find((zoom) => zoom.value === this.value)
    }
  },
  watch: {},
  methods: {
    ...mapActions([]), // API query and update
    ...mapMutations([]),
    onOpenOptions() {
      this.showOptions = !this.showOptions
    },
    onSetZoom(speed) {
      const { value } = speed
      this.$emit('input', value)
      this.showOptions = false
    },
    onClose() {
      console.log(`[Zoom.onClose]`)
      this.showOptions = false
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
$NormalScale: 1;
$HoverScale: 1.2;
* {
  box-sizing: border-box;
  // user-select: none;
}
.video-zoom-btn-wrap {
  position: relative;
  font-size: 1.25rem; // 約 20px
  color: $color_FFF;

  .options-wrap {
    position: absolute;
    right: 0;
    bottom: px2rem(43);
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid $color_9D9D9D;
    border-radius: px2rem(2);
    width: calc(66 / 87 * 100%);
    max-height: 37vh;
    overflow-y: auto;
    background-color: $color_black_40;

    .option {
      width: 100%;
      line-height: 1.5; // font-size 1.5 倍
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $color_4A5C78;
      }
      &.selected {
        color: $color_FFC600;
      }
    }
  }
  .zoom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: px2rem(4);
    padding: px2rem(5) px2rem(6);
    cursor: pointer;
    transition: opacity 0.2s ease-out, scale 0.2s ease-out;

    .zoom-icon {
      border-radius: px2rem(4);
      padding: px2rem(5) px2rem(6);
      width: px2rem(32);
      height: px2rem(32);
      img {
        margin: auto;
        width: px2rem(24);
        height: px2rem(24);
      }
    }
    .zoom-value {
      margin-left: px2rem(4);
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      line-height: px2rem(30);
      text-align: center;
    }
    .zoom-toggle {
      margin-left: px2rem(2);
      width: px2rem(16);
      height: px2rem(16);
      transform: rotate(180deg);

      &.down {
        transform: rotate(0deg);
      }
    }

    &:disabled {
      @include disabled;
      &:hover .zoom-icon {
        background-color: unset;
        transform: unset;
      }
    }

    &:hover .zoom-icon {
      background-color: $color_FFF_20;
      transform: scale($HoverScale);
    }
  }
}
</style>
