<template>
  <div class="wrap-select">
    <span v-if="pageCount <= 0" class="page-item">
      {{ currentPage }}
    </span>
    <div v-else class="selector" @click="toggle()">
      <div class="label">
        <span>{{ label }}</span>
        <div class="arrow" :class="{ visible }">
          <i class="fas fa-caret-down"></i>
        </div>
      </div>
      <div class="options" v-if="visible" v-click-outside="toggle">
        <ul>
          <li
            :class="{ current: item.label === label }"
            v-for="(item, index) in options"
            :key="index"
            @click="select(item.value)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'PageSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    currentPage: Number,
    pageCount: Number
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    label() {
      // 不處理補零, 因為會限制住設備數量
      return this.currentPage // this.currentPage < 10 ? '0' + this.currentPage : this.currentPage
    },
    options() {
      let tmp = []
      for (let i = 1; i <= this.pageCount; i++) {
        tmp.push({
          value: i,
          label: i
        })
      }
      return tmp
    }
  },
  methods: {
    toggle() {
      this.visible = !this.visible
    },
    select(option) {
      this.$emit('update:currentPage', option)
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.wrap-select {
  width: px2rem(32);
}

.page-item {
  color: $color_FFF;
  line-height: 1;
  padding-left: px2rem(10);
}
.selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  .label {
    display: flex;
    align-items: center;
    padding: 0 px2rem(4);
    line-height: 1;
    color: $color_FFF;
    cursor: pointer;

    span {
      margin-right: 0.5rem;
    }

    .arrow {
      color: $color_FFF;
      transform: rotate(0deg);
      transition: 0.3s;
      &.visible {
        transform: rotate(180deg);
      }
    }
  }

  .options {
    position: relative;
    top: 0.5rem;
    left: -1.5rem;

    ul {
      position: absolute;
      // left: 0;
      margin: 0;
      padding: 0;
      border: 1px solid $color_4A5C78;
      border-radius: 0.25rem;
      width: px2rem(51);
      max-height: calc(100vh * 0.25);
      overflow-y: auto;
      background: $color_151B35;
      z-index: 3; // 因為 1x1 歷史影音, z-index:2; 所以頁數下拉選單 z-index 需要比他高, 才能浮在上面

      li {
        padding: px2rem(2) px2rem(8);
        text-align: center;
        color: $color_FFF;

        &:hover {
          background: $color_4A5C78;
          overflow: hidden;
        }

        &.current {
          color: $color_FFC600;
        }
      }
    }
  }

}

</style>
