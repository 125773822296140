<template>
  <div :class="RightMenuOpen ? 'right-open ' : 'right-close'">
    <div v-if="!RightMenuOpen" class="close-rect" @click="handleRightMenu">
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('expand_event_list')/*展開事件列表*/" placement="bottom">
        <div class="icon">
          <img src="@/assets/icons/double-left.svg" />
        </div>
      </el-tooltip>
    </div>
    <EventBoard v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventBoard from './RightBar/EventBoard'

export default {
  name: 'Right',
  components: { EventBoard },
  computed: {
    ...mapState(['RightMenuOpen', 'sosConnectionList'])
  },
  watch: {
    'sosConnectionList.length'(newLen) {
      if (newLen > 0) {
        this.$store.commit('updateRightMenuOpen', true) // 有SOS事件自動展開
      }
    }
  },
  methods: {
    handleRightMenu() {
      this.$store.commit('updateRightMenuOpen', !this.RightMenuOpen)
    }
  }
}
</script>

<style lang="scss" scoped>
.right-close {
  grid-area: start / right-close / second / end;
}

.right-open {
  grid-area: start / right-open / end / end;
}

.close-rect {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_39425D;
  cursor: pointer;
}

.icon {
  margin-right: px2rem(-12);
  width: px2rem(32);
  height: px2rem(32);
  border-radius: px2rem(8);
  background: $color_FFF_20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon:hover {
  background: $color_FFF_50;
}
</style>
