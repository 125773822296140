<template>
  <div class="dashboard-event-modal">
    <div class="control">
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('history_event_back_tooltip')/*關閉(Esc)*/" placement="bottom">
        <div class="esc" @click="closeEventCardDetail">
          <img src="@/assets/icons/clear.svg" alt="">
        </div>
      </el-tooltip>
      <div class="screen-capture" @click="handleScreenCapture">
        <img src="@/assets/icons/camera-solid.png" alt="">{{ $t('history_event_screen_capture')/*擷取畫面*/ }}
      </div>
      <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" v-delTabIndex :content="$t('history_event_previous_tooltip')/*上一筆(左鍵)*/" placement="bottom">
        <div class="icon" :class="{ disabled: isStopPrev }" @click="setPrevEvent">
          <img src="@/assets/icons/arrow-prev.svg" alt="">
        </div>
      </el-tooltip>
      <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" v-delTabIndex :content="$t('history_event_next_tooltip')/*下一筆(右鍵)*/" placement="bottom">
        <div class="icon" :class="{ disabled: isStopNext }" @click="setNextEvent">
          <img src="@/assets/icons/arrow-next.svg" alt="">
        </div>
      </el-tooltip>
    </div>
    <div class="event-detail">
      <EventDetailFr v-if="event.uid.startsWith('fr')" :event="event" />
      <EventDetailLpr v-else :event="event" :type="eventType" />
      <div class="event-misjudgment">
        <!-- 23'/09/13 因為未規劃FR誤判誤判功能, 所以先不顯示 誤判 按鈕 -->
        <EventMisjudgment v-if="!event.uid.startsWith('fr')" :event="event"/>
        <!-- <EventMisjudgment v-else :event="event" :api="apiEditBoviaFrEvent"/> -->
      </div>
    </div>
    <div v-if="isPrintScreen" id="dashboard-screen-shot" class="screen-shot">
      <EventDetailFr v-if="event.uid.startsWith('fr')" :event="event" :isPrintScreen="isPrintScreen" />
      <EventDetailLpr v-else :event="event" :type="eventType" :isPrintScreen="isPrintScreen" />
    </div>

    <div v-if="isPrintScreen" class="is-capturing">
        <RingLoading />
      </div>

    <VideoDownload 
      v-if="isShowVideoDownloadPanel" 
      :queryByTime="false"
      :canDelete="false"
      @close="updateIsShowVideoDownloadPanel(false)" 
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import html2canvas from 'html2canvas'
import { getEventFilename } from '@/utils/lib.js'
import { apiEditBoviaFrEvent } from '@/api/'

export default {
  name: 'DashboardEventModal',
  components: { 
    EventDetailFr: () => import('@/components/Base/EventDetailFr.vue'),
    EventDetailLpr: () => import('@/components/Base/EventDetailLpr.vue'),
    EventMisjudgment: () => import('@/components/Base/EventMisjudgment.vue'),
    VideoDownload: () => import('@/components/Base/VideoDownload.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue'),
  },
  data() {
    return {
      clicks: 0,
      isPrintScreen: false,
    }
  },
  computed: {
    ...mapState({ event: 'eventCardDetailObj' }),
    ...mapState(['filteredDisplayEvents']),
    ...mapState('video', ['total', 'isShowVideoDownloadPanel']),
    apiEditBoviaFrEvent,
    isStopPrev() {
      const index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      return index === 0
    },
    isStopNext() {
      const index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      return index === this.filteredDisplayEvents.length - 1
    },
    eventType() {
      // lpr, chased(正在追車/追車結束), sos
      if (this.event.uid.startsWith('sos')) return 'sos'
      return this.event.chased === 2 || this.event.chased === 3 
        ? 'chased' : 'lpr'
    }
  },
  created() {
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    ...mapMutations(['updateShowEventCardDetail']),
    ...mapMutations('video', ['updateIsShowVideoDownloadPanel']),
    closeEventCardDetail() {
      this.updateShowEventCardDetail(false)
    },
    // 與事件列表點擊事件卡片時的行為一致，這邊是將事件資料(eventObj)傳入
    openDetailCard(eventObj) {
      this.clicks++
      if (this.clicks === 1) {
        // 0.5秒後重設 clicks = 0, 避免使用者短時間快速點擊
        this.clickTimer = setTimeout(() => {
          this.clicks = 0
        }, 500)

        this.$bus.$emit('closePopUpMenu')
        this.$store.dispatch('handleEventModal', eventObj)

        if (eventObj.chasing == 1 || eventObj.uid.startsWith('sos')) {
          this.$bus.$emit(
            'sosCall',
            'group',
            eventObj.user.id,
            eventObj.groupId
          )
        }
      }
    },
    setPrevEvent() {
      if (this.isStopPrev) return
      let index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      this.openDetailCard(this.filteredDisplayEvents[index - 1])
    },
    setNextEvent() {
      if (this.isStopNext) return
      let index = this.filteredDisplayEvents.findIndex(item => item.id === this.event.id)
      this.openDetailCard(this.filteredDisplayEvents[index + 1])
    },
    onKeyUp(e) {
      e.preventDefault()
      if (e.keyCode === 27) this.closeEventCardDetail() // Esc
      if (e.keyCode === 37) this.setPrevEvent() // Left
      if (e.keyCode === 39) this.setNextEvent() // Right
    },
    /* 取得畫面截圖放於剪貼簿 */
    getScreenShot(){
      let src = document.getElementById('wrap-history-event-modal')
      html2canvas(src, { useCORS: true, allowTaint: true, })
        .then(function(canvas) {
          canvas.toBlob(function(blob) {
            navigator.clipboard
              .write([
                new window.ClipboardItem(
                  Object.defineProperty({}, blob.type, {
                    value: blob,
                    enumerable: true
                  })
                )
              ])
              .then(function() {
              })
          })
        })
    }, 
    handleScreenCapture() {
      this.isPrintScreen = true
      setTimeout(() => {
        this.screenCapture()
      }, 1000)
    },
    screenCapture() {
      let src = document.getElementById('dashboard-screen-shot')
      html2canvas(src, { useCORS: true, allowTaint: true, })
      .then(canvas => {
        canvas.toBlob(blob => {
          const link = document.createElement('a')
          const imgURL = URL.createObjectURL(blob)
          link.download = getEventFilename(this.event)
          link.href = imgURL
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(imgURL)
        })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.isPrintScreen = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dashboard-event-modal {
  position: absolute;
  top: 0;
  left: 50px;
  right: 390px;
  bottom: 0;
  background: #282942;
  color: #ffffff;
  padding: 17px 0px 24px 20px;
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.esc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background: #19191980;
  }
}

.screen-capture {
  padding: 9px 32px;
  font-size: 24px;
  line-height: 32px;
  border: 1px solid #FFC600;
  border-radius: 9px;
  display: flex;
  align-items: center;
  color: #FFC600;
  cursor: pointer;
  position: relative;
  margin-right: 48px;
}

.screen-capture::before {
  content: '';
  position: absolute;
  right: -24px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ffffff80;
}

.screen-capture img {
  margin-right: 8px;
}

.screen-capture:hover {
  background: #FFC600;
  color: #ffffff;
}

.screen-capture:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(38%) saturate(2%) 
    hue-rotate(179deg) brightness(101%) contrast(101%);
}

.control {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .esc {
    margin-right: 40px;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 9px;
    margin-right: 24px;
    cursor: pointer;
    user-select: none;
  }

  .icon:hover {
    background: #ffffff33;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.event-detail {
  width: 100%;
  height: calc(100% - 86px);
  display: flex;
  background: #282942;
}

.event-misjudgment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 12px;
  .mis-judgment {
    width: 30px;
    height: 30px;
  }
}

.screen-shot {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 86px;
  background: #282942;
  padding: 20px;
  box-sizing: border-box;
}

.is-capturing {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
</style>