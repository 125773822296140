<template>
  <div v-if="aiBoxTask" class="recognition-view">
    <AiboxTable :data="aiBoxes" />
    <div class="container-center">
      <div class="image">
        <VideoCanvas />
      </div>
      <div class="mode">
        <div class="item">
          <div>{{ $t('ai_mode')/*模式*/ }}</div>
          <div v-if="cfgMode === 'or'" class="value">{{ $t('ai_object_detect')/*物件偵測*/ }}</div>
          <div v-else class="value">{{ $t('ai_motion_detect')/*移動偵測*/ }}</div>
        </div>
        <div v-if="cfgMode === 'or'" class="title">{{ $t('ai_detect_target_threshold')/*偵測目標閾值*/ }}</div>
        <div v-else class="title">{{ $t('ai_motion_level')/*偵測靈敏度*/ }}</div>
        <div class="item" v-for="item in levelList" :key="item.key">
          <div class="label">
            <img :src="iconDict[item.key]" alt="">
            {{ item.label }}
          </div>
          <div v-if="cfgMode === 'or'" class="value">{{ $t(`ai_level_${aiBoxTask.config[cfgMode][item.key]}`) }}</div>
          <div v-else class="value">{{ $t(`ai_motion_level_${aiBoxTask.config[cfgMode][item.key]}`) }}</div>
        </div>
        <div class="title adv">{{ $t('ai_advanced_settings')/*進階設定*/ }}</div>
        <div class="item" v-for="item in advSettings" :key="item.key">
          <div>{{ item.label }}</div>
          <div class="value">
            <template v-if="item.key === 'recgFpsLevel'">
              {{ $t(`ai_level_${aiBoxTask.config[cfgMode][item.key]}`) }}
            </template>
            <template v-else-if="item.key === 'repeatInterval'">
              {{ aiBoxTask.config[cfgMode][item.key] }}{{ $t('min') }}
            </template>
            <template v-else>
              {{ aiBoxTask.config[cfgMode][item.key] }}{{ $t('sec') }}
            </template>
            <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="item.tooltip" placement="bottom">
              <img src="@/assets/icons/info.svg" alt="">
            </el-tooltip>
          </div>
        </div>
        <div class="item set-time adv">
          <div>{{ $t('ai_set_time')/*設定時間*/ }}</div>
          <div class="value">{{ updateTime }}</div>
        </div>
      </div>
    </div>
    <div class="container-bottom">
      <div class="receiver">
        <div class="title">{{ $t('ai_receiver')/*接收人*/ }}</div>
        <div class="receiver-content">
          <div v-for="(userList, groupName) in groupByGroupName(aiBoxTask.subscribers)" :key="groupName">
            <div class="name group" >{{ groupName }}</div>
            <div class="wrap-names">
              <div class="name" v-for="user in userList" :key="user.userId">{{ user.userName }} ({{ user.userAccount }})</div>
            </div>
          </div>
        </div>
      </div>
      <div class="notice-setter">
        <div class="notice">
          <div class="title">{{ $t('ai_notice')/*備註說明*/ }}</div>
          <div class="notice-content">
            <div>{{ aiBoxTask.notice }}</div>
          </div>
        </div>
        <div class="setter">
          <div class="title">{{ $t('ai_setter')/*設定人*/ }}</div>
          <div class="setter-content">
            <div class="name group">{{ aiBoxTask.editorGroupName }}</div>
            <div v-if="aiBoxTask.editorUserId" class="name">{{ aiBoxTask.editorUserName }} ({{ aiBoxTask.editorUserAccount }})</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AiboxTable from '@/components/AiBox/AiboxTable.vue'
import VideoCanvas from '@/components/AiBox/base/VideoCanvas.vue'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'RecognitionView',
  components: { AiboxTable, VideoCanvas },
  data() {
    return {
      iconDict: {
        personLevel: require('@/assets/icons/person.svg'),
        carLevel: require('@/assets/icons/car.svg'),
        bikeLevel: require('@/assets/icons/motor.svg'),
        truckLevel: require('@/assets/icons/truck.svg'),
        busLevel: require('@/assets/icons/bus.svg'),
        motionLevel: null,
      },
      orList: [
        { key: 'personLevel', label: this.$t('ai_person_level') },
        { key: 'carLevel', label: this.$t('ai_car_level') },
        { key: 'bikeLevel', label: this.$t('ai_bike_level') },
        { key: 'truckLevel', label: this.$t('ai_truck_level') },
        { key: 'busLevel', label: this.$t('ai_bus_level') },
      ],
      mdList: [
        { key: 'motionLevel', label: this.$t('ai_motion_level') }
      ],
      advSettings: [
        { key: 'recgFpsLevel', label: this.$t('ai_recg_fps_level'), tooltip: this.$t('ai_tooltip_recg_fps_level') },
        { key: 'repeatInterval', label: this.$t('ai_repeat_interval'), tooltip: this.$t('ai_tooltip_repeat_interval') },
        { key: 'staySec', label: this.$t('ai_stay_sec'), tooltip: this.$t('ai_tooltip_stay_sec') },
      ],
    }
  },
  computed: {
    ...mapState('aibox', ['aiBoxes']),
    ...mapGetters(['timezone']),
    ...mapGetters('aibox', ['aiBoxTask']),
    cfgMode() {
      if (this.aiBoxTask.config.mode === 1) return 'or'
      else return 'md'
    },
    levelList() {
      if (this.aiBoxTask.config.mode === 1) return this.orList
      else return this.mdList
    },
    updateTime() {
      return moment(this.aiBoxTask.configUpdatedTime).tz(this.timezone).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    }
  },
  mounted() {
  },
  methods: {
    groupByGroupName(objArr) {
      return objArr.reduce((r, a) => {
        r[a.groupName] = r[a.groupName] || []
        r[a.groupName].push(a)
        return r
      }, Object.create(null))
    },
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.recognition-view {
  display: flex;
  flex-direction: column;
  width: calc(100% - 380px);
  height: 100%;
  padding: 0px 36px 12px 28px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #ffffff;
}

.title {
  font-weight: 500;
  color: #FFE99F;
  margin-bottom: 8px;
}

.adv {
  padding-top: 12px;
  border-top: 1px solid #4A5C78;
}

.container-center {
  width: 100%;
  height: calc((100% - 190px) * 0.6);
  display: flex;
  margin-bottom: 12px;
}

.image {
  width: 70%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  outline: #4A5C78 1px solid;
}

.mode {
  width: 30%;
  height: 100%;
  overflow: overlay;
  margin-left: 16px;
  background: #4A5C7833;
  border-radius: 8px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 36px;
  margin-bottom: 12px;
}

.item .label {
  display: flex;
  align-items: center;
}

.label img {
  margin-right: 8px;
}

.item .value {
  font-size: 20px;
  line-height: 30px;
  position: relative;
}

.value img {
  position: absolute;
  left: calc(100% + 12px);
  top: 5px;
}

.set-time {
  padding-right: 0;
}

.container-bottom {
  width: 100%;
  height: calc((100% - 190px) * 0.4);
  display: flex;
}

.receiver {
  width: 70%;
  padding: 12px 0 12px 12px;
  background: #4A5C7833;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.receiver-content {
  height: calc(100% - 48px);
  overflow: overlay;
}

.notice-setter {
  width: 30%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.notice, .setter {
  height: calc(50% - 6px);
  background: #4A5C7833;
  border-radius: 8px;
  padding: 10px 0 8px 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.notice {
  margin-bottom: 12px;
}

.notice-content, .setter-content {
  height: calc(100% - 48px);
  overflow: overlay;
}

.notice-content {
  word-wrap: break-word;
  padding-right: 16px;
}

.wrap-names {
  display: flex;
  flex-wrap: wrap;
}

.name {
  margin-right: 30px;
  margin-bottom: 4px;
}

.setter-content .name {
  margin-bottom: 0;
}

.group {
  color: #FFC600;
}

</style>