<template>
  <div v-if="isSettingReady" class="recognition-fr">
    <div class="container-canvas">
      <VideoRoi />
    </div>
    <div class="container-setting">
      <FrSetting />
    </div>
    <div class="container-tags">
      <FrTags />
    </div>
    <div class="container-receiver">
      <Receiver />
    </div>
    <div class="container-fr-note">
      <div v-if="mode === 'edit'" class="wrap-edit-roi" :class="{ disabled: !canSetting }">
        <div v-if="!isEditRoi" class="edit-roi" @click="editRoi">
          <img src="@/assets/icons/Crop.svg" alt="">
          <span>{{ $t('ai_roi_set')/*調整辨識範圍*/ }}</span>
        </div>
        <div v-else class="edit-roi" @click="lockRoi">
          <img src="@/assets/icons/lock.svg" alt="">
          <span>{{ $t('ai_roi_lock')/*鎖定辨識範圍*/ }}</span>
        </div>
        <div v-if="isEditRoi" class="reset-roi" @click="resetRoi">
          <img src="@/assets/icons/refresh-white.svg" alt="">
        </div>
      </div>
      
      <Note v-model="note" :mode="mode" :disabled="isEditRoi" />
    </div>
    <div class="container-editor" :class="{ end: mode === 'edit' }">
      <Editor v-if="mode === 'view'" />
      <div v-else class="actions" :class="{ disabled: isEditRoi }">
        <div class="cancel-btn" @click="cancelTaskEdit">{{ $t('cancel')/*取消*/ }}</div>
        <div class="confirm-btn" :class="{ disabled: !isModified }" @click="handleTaskEdit">{{ $t('confirm')/*確認*/ }}</div>
      </div>
    </div>
  </div>
  <div v-else class="recognition-fr">
    <Loading :active.sync="isLoading"
      color="#FFC600"
      background="transparent"
      :width="80"
      :height="80"
      :opacity="0.1"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage">
    </Loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { apiGetUserByUid } from '@/api/index.js'

export default {
  name: 'RecognitionFR',
  components: {
    Loading,
    VideoRoi: () => import('./base/VideoRoi.vue'),
    FrSetting: () => import('./fr/FrSetting.vue'),
    FrTags: () => import('./fr/FrTags.vue'),
    Receiver: () => import('./fr/Receiver.vue'),
    Note: () => import('./fr/Note.vue'),
    Editor: () => import('./fr/Editor.vue'),
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
    }
  },
  computed: {
    ...mapState('aiboxFr', [
      'mode', 
      'isEditRoi', 
      'currDefaultSetting', 
      'frNote',
    ]),
    ...mapGetters('aiboxFr', [
      'userId', 
      'isSettingReady',
      'isSettingSrcModified',
      'isSettingModified',
      'isNoteModified',
      'isSubscribersModified',
      'isNotifyFilterModeModified',
    ]),
    canSetting() {
      // defaultSetting = 0: userSetting 設備客製/可編輯, 1: defaultSetting 系統預設/不可編輯
      return this.currDefaultSetting === 0
    },
    isModified() {
      return this.isSettingSrcModified || this.isSettingModified || 
        this.isNoteModified || this.isSubscribersModified ||
        this.isNotifyFilterModeModified
    },
    note: {
      get() {
        return this.frNote
      },
      set(val) {
        this.updateFrNote(val)
      }
    }
  },
  mounted() {
    this.onInit()  
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations('aibox', [ 
      'updateParamAiboxPortal', 
      'updateShowAiboxPortal',
    ]),
    ...mapMutations('aiboxFr', [
      'resetState',
      'updateMode',
      'updateFrNote',
      'updateIsEditRoi',
      'updateROI',
    ]),
    ...mapActions(['getFrTagList']),
    ...mapActions('aiboxFr', [
      'getDefaultSetting',
      'getUserSetting',
      'getUserInfo',
      'getUserSubscribers',
      'initSetting',
      'editUser',
      'editUserSetting',
      'handleUserSubscribers',
      'onUpdateCurrDefaultSetting',
    ]),
    async onInit() {
      try {
        this.isLoading = true
        this.getFrTagList()
        this.getUserSubscribers()
        await this.getUserInfo() // get defaultSetting, frNote
        await this.getDefaultSetting()
        await this.getUserSetting()

        // -------
        // 若為系統預設先暫時設定為設備客製，不能切換到系統預設
        if (this.currDefaultSetting === 1) {
          this.onUpdateCurrDefaultSetting(0)
          await this.editUser() // 參數來源, frNote
        }
        // -------

        this.initSetting()
      } catch (err) {
        console.log('err = ', err)
      } finally {
        this.isLoading = false
      }
    },
    async getUserByUid() {
      const res = await apiGetUserByUid(this.userId)
      console.log('res = ', res)
    },
    // async editUser() {
    //   const data = {
    //     id: this.userId,
    //     frNote: '備註測試..'
    //   } 
    //   const res = await apiEditUser(data)
    //   console.log('res = ', res)
    // },
    cancelTaskEdit() {
      if (!this.isModified) {
        this.updateMode('view')
        return
      }
      // 出現cancelEdit跳窗詢問，確認後的動作為切換模式到檢視模式
      this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'cancelFrEdit' })
      this.updateShowAiboxPortal(true)
    },
    async handleTaskEdit() {
      // 'isSettingSrcModified',
      // 'isSettingModified',
      // 'isNoteModified',
      // 'isSubscribersModified',
      
      await this.editUserSetting()
      await this.handleUserSubscribers()
      await this.editUser() // 參數來源, frNote
      this.updateMode('view')
    },
    editRoi() {
      this.updateIsEditRoi(true)
    },
    lockRoi() {
      this.updateIsEditRoi(false)
    },
    resetRoi() {
      this.updateROI({
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      })
    },
    onCancel() {},
  }
}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.recognition-fr {
  display: grid;
  grid-template-columns: 68% 32%;
  grid-template-rows: 50% 12.4% 18.3% 15.3%;
  grid-template-areas: 
    "canvas setting"
    "tags remark"
    "receiver remark"
    "receiver editor";
  grid-gap: 12px 16px;
  padding: 16px 36px 12px 20px;
  background: #282942;
  position: relative;
}

.container-canvas {
  grid-area: canvas;
  background: #4A5C7833;
  border-radius: 8px;
}

.container-setting {
  grid-area: setting;
}

.container-tags {
  grid-area: tags;
  background: #4A5C7833;
  border-radius: 8px;
}

.container-receiver {
  grid-area: receiver;
  background: #4A5C7833;
  border-radius: 8px;
}

.container-fr-note {
  grid-area: remark;
  .wrap-edit-roi {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 12px;
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  .edit-roi {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    padding: 4px 12px;
    background: #4A5C78;
    border-radius: 8px;
    cursor: pointer;
    
    img {
      margin-right: 8px;
    }
  }
  .reset-roi {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: #4A5C7880;
    cursor: pointer;
    &:hover {
      background: #4A5C78;
    }
  }
}

.container-editor {
  grid-area: editor;
  &.end {
    display: flex;
    align-items: flex-end;
  }
}

.actions {
  width: 100%;
  height: 64px;
  background: #4A5C7833;
  border-radius: 8px;
  display: flex;
  font-size: 24px;
  padding: 12px 12px 0 12px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  .cancel-btn {
    flex: 1;
    color: #FFC600;
    border: 1px solid #FFC600;
    border-radius: 9px;
    text-align: center;
    margin-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    &:hover {
      background: #FFC60033;
    }
  }
  .confirm-btn {
    flex: 1;
    background: #FFC600;
    color: #282942;
    border-radius: 9px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    &:hover {
      background: #FFB703;
    }
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}


</style>