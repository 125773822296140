<template>
  <div class="input-range">
    <span v-if="type === 'level'">{{ $t(`ai_level_${min}`) }}</span>
    <span v-else-if="type === 'motion'">{{ $t(`ai_motion_level_${min}`) }}</span>
    <span v-else>{{ min }}</span>
    <input
      type="range"
      :min="min"
      :max="max"
      class="range"
      :style="rangeStyle"
      step="1"
      v-model="range"
      :disabled="disabled"
    />
    <span v-if="type === 'level'" class="max">{{ $t(`ai_level_${max}`) }}</span>
    <span v-else-if="type === 'motion'">{{ $t(`ai_motion_level_${max}`) }}</span>
    <span v-else>{{ max }}</span>
  </div>
</template>

<script>
export default {
  name: 'InputRange',
  props: {
    min: { required: true },
    max: { required: true },
    value: { required: true },
    type: {
      default: 'level', // level, value, motion
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    range: {
      get() {
        return Number(this.value)
      },
      set(val) {
        this.$emit('input', Number(val))
      }
    },
    rangeStyle() {
      return {
        '--range': (this.range - this.min) / (this.max - this.min) * 100 + '%'
      }
    },
  },
}
</script>

<style scoped>
.input-range {
  display: flex;
  align-items: center;
}

span {
  width: 42px;
  font-size: 16px;
  line-height: 24px;
  margin-right: 7px;
  text-align: center;
  white-space: nowrap;
  color: #9D9D9D;
}

.max {
  text-align: right;
  margin-left: 7px;
}

.wrap-input {
  width: 100%;
  position: relative;
  outline: 1px solid pink;
}

.divider {
  position: absolute;
  width: 3px;
  height: 6px;
  left: 25%;
  top: 18px;
  background: #282942;
}

.range {
  width: 100%;
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0 2px;
  background: none;
  position: relative;
  /* z-index: 2; */
}

.range::-webkit-slider-runnable-track {
  background-color: #4A5C78;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(
    to right,
    #FFC600,
    #FFC600 var(--range),
    #4A5C78 var(--range),
    #4A5C78 100%
  );
}

/* .range::-webkit-slider-runnable-track {
  background-color: #4A5C78;
  height: 6px;
  border-radius: 3px;
  background: linear-gradient(
    to right,
    #FFC600,
    #FFC600 24.5%,
    #282942 24.5%,
    #282942 26%,
    #FFC600 26%,
    #FFC600 49.5%,
    #4A5C78 49.5%,
    #4A5C78 51%,
    #FFC600 51%,
    #FFC600 74.5%,
    #4A5C78 74.5%,
    #4A5C78 100%,
    #FFC600 76%,
    #FFC600 100%
  );
} */

.range[disabled]::-webkit-slider-runnable-track {
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-moz-range-thumb {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: none;
  background: none;
  background-color: #ffffff;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 50%;
  background-color: #ffffff;
  height: 16px;
  width: 16px;
  margin-top: -6px;
  cursor: pointer;
}

.range[disabled]::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 1px solid #d7dbdd;
  cursor: default;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range:hover::-webkit-slider-thumb {
  background: radial-gradient(circle at 65% 30%, white 2px, rgb(235, 231, 18) 3%, rgb(127, 127, 141) 80%, rgb(18, 235, 235) 100%);

}
</style>