<template>
  <div class="recognition-list">
    <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="deviceName" placement="bottom">
      <div class="device-name">
        {{ deviceName }}
      </div>
    </el-tooltip>
    <template v-if="deviceModelId === 2000 || deviceModelId === 2100 || deviceModelId === 2200 || this.isAiCam">
      <div class="header local">
        <div class="reg-title"><img src="@/assets/icons/Camera.svg" alt=""> {{ $t('ai_local_recognition')/*本機辨識*/ }}</div>
      </div>
      <div class="local-recog">
        <TaskCardFr v-if="deviceModelId === 2000" />
        <TaskCardLpr v-if="deviceModelId === 2100 || deviceModelId === 2200 || this.isAiCam" />
      </div>
    </template>
    
    <div class="header" tabindex="0" @focusout="showRecognitionOptions = false">
      <div class="reg-title"><img src="@/assets/icons/cloud.svg" alt=""> {{ $t('ai_could_recognition')/*雲端辨識*/ }}</div>
      <div class="plus" @click="toggleRecognitionOptions"><img src="@/assets/icons/plus.svg" alt=""></div>
      <transition>
        <div v-show="showRecognitionOptions" class="recog-options">
          <div v-for="option in plusRecogOptions" :key="option.ai" class="option" @click="handleAddTask(option.ai)">
            <img src="@/assets/icons/OR.svg" alt="">
            {{ option.label }}
          </div>
        </div>
      </transition>
    </div>
    <div class="cards">
      <TaskCardOr v-for="task in aiBoxTasks" :key="task.id" :data="task" />
      <!-- <div v-for="item in aiBoxTasks" :key="item.id" class="recognition-card" 
        :class="{'recog-selected': item.id === taskId }" @click="viewTask(item.id)">
        <div class="title">
          <img src="@/assets/icons/OR.svg" alt="">
          <div class="name">{{ $t('ai_object_recognition')/*物件辨識*/ }}</div>
        </div>
        <div v-if="item.id === -99" class="status">{{ $t('ai_task_add') }}</div>
        <div v-else-if="mode === 'edit' && item.id >= 0 && item.id === taskId" class="status">{{ $t('ai_task_edit') }}</div>
        <div v-else-if="item.status === 0" class="status">{{ $t(`ai_status_${item.status}`) }}</div>
        <div v-else class="status error">{{ $t(`ai_status_${item.status}`) }}</div>
        <div class="item">
          <div class="label">{{ $t('ai_box')/*辨識盒*/ }}</div>
          <div class="value">{{ item.aiboxName }}</div>
        </div>
        <div class="item">
          <div class="label">{{ $t('ai_update_time')/*更新時間*/ }}</div>
          <div class="value time" :class="{ orange: item.timeSinceLastUpdated > 30 && item.timeSinceLastUpdated <= 60,
            red: item.timeSinceLastUpdated > 60 }">{{ timeAgo(item.updatedTime, item.timeSinceLastUpdated) }}</div>
          <div v-if="item.id === taskId" class="icons">
            <div v-if="mode === 'view'" class="icon" @click.stop="goEditMode">
              <img src="@/assets/icons/pen.svg" alt="">
            </div>
            <div class="icon" @click="handleDelete"><img src="@/assets/icons/TrashCan.svg" alt=""></div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { diffSecs, timeAgo } from '@/utils/lib.js'

export default {
  name: 'RecognitionList',
  components: {
    // RecognitionCard: () => import('@/components/AiBox/list/RecognitionCard.vue'),
    TaskCardOr: () => import('@/components/AiBox/list/TaskCardOr.vue'),
    TaskCardFr: () => import('@/components/AiBox/list/TaskCardFr.vue'),
    TaskCardLpr: () => import('@/components/AiBox/list/TaskCardLpr.vue'),
  },
  data() {
    return {
      showRecognitionOptions: false,
      plusRecogOptions: [
        // { ai: 'lpr', label: this.$t('ai_license_plate_recognition'), icon: 'whiteCar.svg' },
        { ai: 'or', label: this.$t('ai_object_recognition'), icon: 'tag.svg' }
      ],
      frTask: {
        ai: 'fr',
        id: 'test99',
        aiboxName: 'test',
        updatedTime: '2020-10-10 10:10:10',
        timeSinceLastUpdated: 10,
        status: 0
      },
    }
  },
  computed: {
    ...mapState('aibox', ['mode', 'taskId', 'aiBoxTasks']),
    ...mapGetters('aibox', ['deviceName', 'aiBoxTask']),
    ...mapGetters('aiboxFr', ['deviceModelId']),
    ...mapGetters('aiboxLpr', ['isAiCam'])
  },
  methods: {
    ...mapMutations('aibox', ['updateMode', 'updateAiMode', 'updateTaskId', 'updateShowAiboxPortal', 'updateParamAiboxPortal']),
    ...mapActions('aibox', ['getAiBoxes', 'initialAiBoxTask']),
    diffSecs,
    timeAgo,
    toggleRecognitionOptions() {
      this.showRecognitionOptions = !this.showRecognitionOptions
    },
    handleAddTask(ai) {
      this.initialAiBoxTask(ai)
      this.showRecognitionOptions = false
    },
    viewTask(taskId) {
      if (this.mode === 'edit') {
        this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'switchTask', taskId: taskId })
        this.updateShowAiboxPortal(true)
      } else {
        this.updateTaskId(taskId)
        this.getAiBoxes(this.aiBoxTask.ai) // 取得辨識盒資源
        this.updateMode('view')
      }
    },
    goEditMode() {
      this.updateMode('edit')
    },
    handleDelete() {
      this.updateParamAiboxPortal({ info: 'deleteTask' })
      this.updateShowAiboxPortal(true)
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.recognition-list {
  width: 380px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background: #282942;
  border-right: 1px solid #4A5C78;
}

.device-name {
  background: #4A5C78;
  font-size: 30px;
  line-height: 42px;
  padding: 14px 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  position: relative;
  &.local img {
    width: 24px;
    height: 24px;
  }
}

.local-recog {
  padding: 6px 28px;
  margin-bottom: 4px;
}

.reg-title {
  display: flex;
  align-items: center;
}

.plus {
  width: 36px;
  height: 36px;
  background: #ffffff1a;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.recog-options {
  width: 200px;
  position: absolute;
  left: calc(100% - 16px);
  padding: 6px 0;
  background: #151B35;
  border: 1px solid #4A5C78;
  border-radius: 9px;
  z-index: 12;
}

.option {
  padding: 6px 32px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.option:hover {
  background: #4A5C78;
}

.cards {
  height: calc(100% - 150px);
  overflow: overlay;
  padding: 8px 18px 8px 28px;
}

.recognition-card {
  background: #4A5C7866;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.recog-selected {
  background: #4A5C78;
}

.title {
  width: 60%;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  display: flex;
  justify-content: start;
}

.reg-title img, .title img, .option img {
  margin-right: 12px;
  position: relative;
  top: 2px;
}

.title .name {
  width: 60%;
}

.status {
  position: absolute;
  top: 12px;
  right: 0;
  max-width: 150px;
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  padding: 6px 12px;
  background: #6E7D93;
  border-radius: 9px 0px 0px 9px;
}

.error {
  background: #F94144;
}

.item {
  display: flex;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 4px;
  position: relative;
}

.item .label {
  width: 40%;
}

.item .value {
  width: 60%;
  word-break: break-all;
}

.time {
  color: #8CC24D;
}

.orange {
  color: #D8A848;
}

.red {
  color: #F94144;
}

.icons {
  display: flex;
  position: absolute;
  right: 0px;
  top: -3px;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon:hover {
  background: #FFFFFF33;
}

.icon img {
  width: 20px;
  height: 20px;
}

.icons .icon:first-of-type {
  margin-right: 4px;
}

</style>