<template>
  <div class="move-step">
    <div class="wheel">
      <!-- 由12點鐘方向開始2398741，中間home為5 -->
      <!-- excellent為球機，支援move斜向及home -->
      <!-- poor為箱型，關閉move斜向及home，不支援preset -->
      <div class="arc" @click="runStep(2)">
        <div class="icon"></div>
      </div>
      <div class="arc" :class="{ disable: !isExcellent }" @click="runStep(3)"></div>
      <div class="arc" @click="runStep(6)"><div class="icon"></div></div>
      <div class="arc" :class="{ disable: !isExcellent }" @click="runStep(9)"></div>
      <div class="arc" @click="runStep(8)"><div class="icon"></div></div>
      <div class="arc" :class="{ disable: !isExcellent }" @click="runStep(7)"></div>
      <div class="arc" @click="runStep(4)"><div class="icon"></div></div>
      <div class="arc" :class="{ disable: !isExcellent }" @click="runStep(1)"></div>
      <!-- <div class="circle disable"></div> -->
      <!-- <div class="circle" :class="{ disable: !isExcellent }" @click="runStep(5)">
        <div class="home-icon" :class="{ disable: !isExcellent }"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getPtzStatus } from '@/api/index.js'

export default {
  name: 'MoveStep',
  data() {
    return {
      poorNoSupport: [ 1, 3, 5, 7, 9 ], // poor為箱型，關閉move斜向及home，不支援preset
    }
  },
  computed: {
    ...mapState('ptz', ['step', 'pt']),
    ...mapGetters('ptz', ['ptzDeviceId']),
    isExcellent() {
      return this.pt === 'excellent'
    },
  },
  methods: {
    runStep(move) {
      if (!this.isExcellent && this.poorNoSupport.includes(move)) return
      this.runStepApi(parseInt(move), parseInt(this.step))
    },
    async runStepApi(move, step) {
      let payload = {"step": step, "move": move, "continuous": false }
      if (move == 5) payload = {"move": move } // 回 HOME
      const params = {
        "url": "/api/ptz",
        "target": "put",
        "payload": payload,
        "sn": this.ptzDeviceId,
      }
      const res = await getPtzStatus(params)
      //console.log(params, res.status, res.statusText)
      if (res.status == 200) {
        //console.log(params, res.data)
        //this.$message.success(`${res.status} ${res.statusText} 移動成功！`)
      } else {
        // this.$message.warning(`${res.status} ${res.statusText} 移動失敗！`)
        this.$notify({
          type: 'warning',
          message: `${res.status} ${res.statusText} 移動失敗！`
        })
      }
    },
  }
}
</script>

<style scoped>
.move-step {
  width: 100%;
  padding-top: 8%;
  box-sizing: border-box;
  background: #363d59;
  border-radius: 9px;  
  color: #ffffff;
  position: relative;
}

.wheel {
  width: 76%;
  /* square */
  height: 0;
  padding-bottom: 76%;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.arc {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
  background-image: radial-gradient(circle at 0% 100%, transparent, transparent 29.5%, #282942 30%, #282942 67.5%, transparent 68%, transparent);
  transition-property: transform, opacity;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, -0.4, 0.7, -0.3);
  -webkit-clip-path: polygon(0 0, 0 98%, 98% 48%, 98% 0);
  clip-path: polygon(0 0, 0 98%, 98% 48%, 98% 0);
  opacity: 1;
  transform: scale(1) rotate(0);
}

.arc:not(.disable) {
  cursor: pointer;
}
.arc:not(.disable):hover {
  background-image: radial-gradient(circle at 0% 100%, transparent, transparent 29.5%, #FFC600 30%, #FFC600 67.5%, transparent 68%, transparent);
}
.arc:not(.disable):active {
  background-image: radial-gradient(circle at 0% 100%, transparent, transparent 29.5%, #FFD133 30%, #FFD133 67.5%, transparent 68%, transparent);
}

.arc:nth-child(odd) .icon {
  width: 34%;
  height: 34%;
  position: absolute;
  left: 18%;
  top: 22%;
}

.arc:nth-child(even) {
  -webkit-clip-path: polygon(0 0, 48% 0, 0 98%);
	clip-path: polygon(0 0, 48% 0, 0 98%);
} 

.arc:nth-child(1) {
  transform: scale(1) rotate(-30deg);
}
.arc:nth-child(1) .icon {
  transform: rotate(30deg);
  background-image: url('../../../assets/icons/step-up.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.arc:nth-child(1):hover .icon {
  background-image: url('../../../assets/icons/step-up-hover.svg');
}

.arc:nth-child(2) {
  transform: scale(1) rotate(33deg);
}

.arc:nth-child(3) {
  transform: scale(1) rotate(60deg);
}
.arc:nth-child(3) .icon {
  transform: rotate(-60deg);
  background-image: url('../../../assets/icons/step-right.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.arc:nth-child(3):hover .icon {
  background-image: url('../../../assets/icons/step-right-hover.svg');
}

.arc:nth-child(4) {
  transform: scale(1) rotate(123deg);
}

.arc:nth-child(5) {
  transform: scale(1) rotate(150deg);
}
.arc:nth-child(5) .icon {
  transform: rotate(-150deg);
  background-image: url('../../../assets/icons/step-down.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.arc:nth-child(5):hover .icon {
  background-image: url('../../../assets/icons/step-down-hover.svg');
}

.arc:nth-child(6) {
  transform: scale(1) rotate(213deg);
}

.arc:nth-child(7) {
  transform: scale(1) rotate(240deg);
}
.arc:nth-child(7) .icon {
  transform: rotate(-240deg);
  background-image: url('../../../assets/icons/step-left.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}
.arc:nth-child(7):hover .icon {
  background-image: url('../../../assets/icons/step-left-hover.svg');
}

.arc:nth-child(8) {
  transform: scale(1) rotate(303deg);
}

.circle {
  position: absolute;
  left: 31%;
  top: 31%;
  width: 38%;
  height: 38%;
  border-radius: 50%;
  background: #282942;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle:not(.disable):hover {
  background: #FFC600;
  cursor: pointer;
}

.circle:not(.disable):active {
  background: #FFD133;
}

.home-icon:not(.disable) {
  width: 25%;
  height: 25%;
  background-image: url('../../../assets/icons/home.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.circle:not(.disable):hover .home-icon {
  background-image: url('../../../assets/icons/home-hover.svg');
}

</style>