<template>
  <div class="top-bar" :class="topClass">
    <div class="icon-bar" :class="{ 'r-close': (!RightMenuOpen && TopMenuHeightLevel > 1) || (!LeftMenuOpen && !RightMenuOpen) }">
      <div class="icon-bar-left">
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('expand_device_list')/*展開設備列表*/" placement="bottom">
          <div
            v-if="!LeftMenuOpen"
            class="icon left-menu-toggle"
            @click="toggleLeftBar"
          >
            <img src="@/assets/icons/double-right.svg" />
          </div>
        </el-tooltip>
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="this.TopMenuHeightLevel==1 ? $t('expand_the_play')/*展開播放視窗*/ : $t('collapse_the_play')/*收合播放視窗*/" placement="bottom">
          <div class="icon" @click="toggleTopBar">
            <img
              :src="topArrowUrl"
              :class="{ 'arrow-r-close': !RightMenuOpen }"
            />
          </div>
        </el-tooltip>

        <div class="video-step">
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('zoom_out_the_play')/*縮小播放視窗*/" placement="bottom">
            <button :disable="TopMenuHeightLevel === 1" @click="closeTopMenu">
              <img src="@/assets/icons/calling-glist-up.svg" />
            </button>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('enlarge_the_play')/*放大播放視窗*/" placement="bottom">
            <button :disable="TopMenuHeightLevel == 4" @click="openTopMenu">
              <img src="@/assets/icons/calling-glist-dn.svg" />
            </button>
          </el-tooltip>
        </div>

        <div class="video-view-mode">
          <el-tooltip v-if="permissionV2.liveView > 0" popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('mode_1x1')/*1x1模式*/" placement="bottom">
            <img :src="modeOneUrl" @click="changeVideoViewMode(1)" />
          </el-tooltip>
          <el-tooltip v-if="permissionV2.liveView > 0" popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('mode_2x2')/*2x2模式*/" placement="bottom">
            <img :src="modeTwoUrl" @click="changeVideoViewMode(2)" />
          </el-tooltip>
          <el-tooltip v-if="permissionV2.liveView > 0" popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('mode_3x3')/*3x3模式*/" placement="bottom">
            <img :src="modeThreeUrl" @click="changeVideoViewMode(3)" />
          </el-tooltip>
          <el-tooltip v-if="permissionV2.liveView > 0" popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('mode_4x4')/*4x4模式*/" placement="bottom">
            <img :src="modeFourUrl" @click="changeVideoViewMode(4)" />
          </el-tooltip>
        </div>
        <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :content="$t('reorder_device_list')/*重新排序*/" placement="bottom">
          <button class="page-resort" :disabled="selectedUsers.length <= 1" @click="onPageResort()">
            <img src="@/assets/icons/refresh-white.svg" />
          </button>
        </el-tooltip>
      </div>
      <div class="icon-bar-right">
        <div class="pagination">
          <button class="page-prev" :disabled="currentPage <= 1" @click="goPrev()">
            <img src="@/assets/icons/arrow-left.svg" />
          </button>
          <!-- <span v-if="pageCount <= 0" class="page-item">
            {{ currentPage }}
          </span> -->
          <PageSelect
            :pageCount="pageCount"
            :currentPage.sync="currentPage"
          ></PageSelect>
          <img class="line" src="@/assets/icons/line.svg" alt="" />
          <span class="page-item">{{ pageCount }}</span>
          <button class="page-next" :disabled="currentPage >= pageCount" @click="goNext()">
            <img src="@/assets/icons/arrow-right.svg" />
          </button>
        </div>
        <div class="topbar-setting" :class="{ active: showSettingBox }" @click="toggleSettingBox">
          <img src="@/assets/icons/topbar-setting.svg" alt="" />
        </div>
      </div>
      <div v-if="showSettingBox" class="topbar-setting-box">
        <div class="close">
          <img src="@/assets/icons/close.svg" alt="" @click="closeSettingBox" />
        </div>
        <ToggleSwitch :suffix="$t('top_bar_keep_login')/*保持登入*/" v-model="isKeepLogin" />
        <ToggleSwitch :suffix="$t('top_bar_online_devices')/*上線設備*/" v-model="isShowOnline" />
      </div>
    </div>
    <div class="main-content" v-if="TopMenuHeightLevel != 1">
      <LiveEvent v-if="videoViewMode === 1" :currentPage="currentPage" :pageCount="pageCount" />
      <MultipleView
        v-else
        :multipleViewMode="videoViewMode"
        :currentPage="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import LiveEvent from './TopBar/LiveEvent.vue'
import MultipleView from './TopBar/MultipleView.vue'
import PageSelect from './TopBar/PageSelect.vue'
import ToggleSwitch from '@/components/Base/ToggleSwitch.vue'

export default {
  name: 'Top',
  components: { LiveEvent, MultipleView, PageSelect, ToggleSwitch },
  data() {
    return {
      preTopMenuHeightLevel: 1,
      preMultipleViewMode: 1,
      currentPage: 0,
      showSettingBox: false,
    }
  },
  computed: {
    ...mapState([
      'TopMenuHeightLevel',
      'videoViewMode',
      'LeftMenuOpen',
      'RightMenuOpen',
      'LiveEventOn',
      'selectedUsers',
      'liveList',
      'connectionList',
      'trackUser',
      'liveVideoUrl',
      'notAutomaticLogout',
      'showMapOnlineDevices',
      'permissionV2',
      'singleUrlUserID'
    ]),
    isKeepLogin: {
      get() {
        return this.notAutomaticLogout
      },
      set(value) {
        this.updateNotAutomaticLogout(value)
      }
    },
    isShowOnline: {
      get() {
        return this.showMapOnlineDevices
      },
      set(value) {
        this.updateShowMapOnlineDevices(value)
      }
    },
    topArrowUrl() {
      if (this.TopMenuHeightLevel == 1) {
        return require('@/assets/icons/double-bottom.svg')
      }
      return require('@/assets/icons/double-top.svg')
    },
    modeOneUrl() {
      if (this.videoViewMode === 1) {
        return require('@/assets/icons/oneOn.svg')
      }
      return require('@/assets/icons/oneOff.svg')
    },
    modeTwoUrl() {
      if (this.videoViewMode == 2) {
        return require('@/assets/icons/twotwoOn.svg')
      }
      return require('@/assets/icons/twotwoOff.svg')
    },
    modeThreeUrl() {
      if (this.videoViewMode == 3) {
        return require('@/assets/icons/threethreeOn.svg')
      }
      return require('@/assets/icons/threethreeOff.svg')
    },
    modeFourUrl() {
      if (this.videoViewMode == 4) {
        return require('@/assets/icons/fourfourOn.svg')
      }
      return require('@/assets/icons/fourfourOff.svg')
    },
    topClass() {
      if (!this.LeftMenuOpen && !this.RightMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'top-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'top-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return 'top-three'
        }
        return 'top-four'
      }
      if (this.LeftMenuOpen && this.RightMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'top-lr-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'top-lr-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return 'top-lr-three'
        }
        return 'top-lr-four'
      }
      if (this.LeftMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'top-l-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'top-l-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return 'top-l-three'
        }
        return 'top-l-four'
      }
      if (this.RightMenuOpen) {
        if (this.TopMenuHeightLevel == 1) {
          return 'top-r-one'
        }
        if (this.TopMenuHeightLevel == 2) {
          return 'top-r-two'
        }
        if (this.TopMenuHeightLevel == 3) {
          return 'top-r-three'
        }
        return 'top-r-four'
      }
      return 'none'
    },
    pageSize() {
      return this.videoViewMode * this.videoViewMode
    },
    pageCount() {
      return Math.ceil(this.selectedUsers.length / this.pageSize)
    },
  },
  watch: {
    TopMenuHeightLevel(newV, oldV) {
      if(oldV == 1) { // 如果打開topbar
        // 如果沒有liveList id = null
        // 如果沒有選取的使用者 id = liveList第一個影片的userID
        // 如果有選取的使用者 id  = 匹配上的第一個userID 都沒有匹配的話 id = null
        // 22.02.07 tingyu 把單路userID放到vuex變數
        //   this.$store.commit('updateSingleUrlUserID', this.getUserId())

        // 收合/展開播放視窗 前後要維持同一個設備
        if (this.currentPage <= 0) {
          this.setSingleUserId()
        }
      }

      // 關閉video視窗，清空videoMarker, videoPath等資料
      if (newV == 1) {
        this.resetVideoGpsMarkerPath()
      }

      // 只有2/3模式可以開啟PTZ控制視窗，其餘模式要關閉
      if (newV !== 3) this.updateShowPtzPanel(false)
    },
    videoViewMode() {
      if (this.videoViewMode === 1) {
        this.noLiveEventVideoSetting()
      }
      // 切換多路模式時關閉PTZ設定
      if (this.videoViewMode > 1)
        this.updateShowPtzPanel(false)

      // 更新 currentPage (設定 currentPage)
      this.setCurrentPageBySingleUrlUserID('W.videoViewMode')
    },
    pageCount(nVal, oVal) {
      if (!oVal && nVal > 0) {
        // 無 -> 有
        this.currentPage = 1
      } else if (!oVal > 0 && !nVal) {
        // 有 -> 無
        this.currentPage = 0
      } else /*if (nVal && nVal < this.currentPage)*/ {
        // 更新 currentPage (設定 currentPage)
        this.setCurrentPageBySingleUrlUserID('W.pageCount')
      }
    },
    currentPage(/*nVal, oVal*/) {
      // get the first device (設定 singleUrlUserID)
      const firstUserIdx =
        Math.pow(this.videoViewMode, 2) * (this.currentPage - 1)
      if (this.selectedUsers[firstUserIdx]) {
        this.$store.commit(
          'updateSingleUrlUserID',
          this.selectedUsers[firstUserIdx].id
        )
      }
    },
    // singleUrlUserID(/*nVal*/) {
    //   console.log(`[TopBar.W.singleUrlUserID] singleUrlUserID:`, this.singleUrlUserID)
    //   // this.setCurrentPageBySingleUrlUserID('W.singleUrlUserID')
    // },
    selectedUsers: {
      deep: true,
      handler(/*nVal, oVal*/) {
        // console.log(`[TopBar.W.selectedUsers] nVal, oVal:`, nVal, oVal)
        // 處理 第一個設備
        if (this.videoViewMode !== 1) {
          const firstUserId = this.selectedUsers.map(({ id }) => id)[0]

          if (firstUserId !== this.singleUrlUserID) {
            this.$store.commit('updateSingleUrlUserID', firstUserId)
          }
        }
      }
    }
  },
  mounted() {
    if (this.pageCount > 0) {
      this.currentPage = 1
    }
  },
  beforeDestroy() {
    this.updateShowPtzPanel(false) // 離開Dashboard頁面
  },
  methods: {
    ...mapMutations([
      'updateNotAutomaticLogout',
      'updateShowMapOnlineDevices',
      'swapSelectedUsers'
    ]),
    ...mapMutations('ptz', ['updateShowPtzPanel']),
    ...mapActions(['resetVideoGpsMarkerPath']),
    toggleLeftBar() {
      this.$store.commit('updateLeftMenuOpen', !this.LeftMenuOpen)
    },
    toggleTopBar() {
      if (this.TopMenuHeightLevel == 1) {
        // topba如果是關閉狀態 會呈現向下箭頭icon 按下去要打開到最大
        this.$store.commit(
          'updateTopMenuHeightLevel',
          this.preTopMenuHeightLevel == 1 ? 4 : this.preTopMenuHeightLevel
        )
        return
      }
      // topba如果是打開狀態 會呈現向上箭頭icon 按下去要全部關掉
      this.preTopMenuHeightLevel = this.TopMenuHeightLevel // 紀錄關閉前的高度
      this.$store.commit('updateTopMenuHeightLevel', 1)
    },
    closeTopMenu() {
      if (this.TopMenuHeightLevel > 1) {
        let num = this.TopMenuHeightLevel - 1
        if (this.videoViewMode > 1 && num == 2) {
          this.$store.commit('updateVideoViewMode', 1) // 1/3模式切換回單路
        }
        this.$store.commit('updateTopMenuHeightLevel', num)
      } else {
        this.$store.commit('updateTopMenuHeightLevel', 1)
      }
    },
    openTopMenu() {
      if (this.TopMenuHeightLevel < 4) {
        let num = this.TopMenuHeightLevel + 1
        if (num == 2) { // 1/3模式沒有多路切換到單路
          this.$store.commit('updateVideoViewMode', 1)
        }
        this.$store.commit('updateTopMenuHeightLevel', num)
      } else {
        this.$store.commit('updateTopMenuHeightLevel', 4)
      }
    },
    changeVideoViewMode(mode) {
      this.$store.commit('updateVideoViewMode', mode)
      if (mode == 1) {
        // 切換到單路模式 就關掉多路模式的東西
        if (this.TopMenuHeightLevel == 1)
          this.$store.commit('updateTopMenuHeightLevel', 2)
      } else {
        if (this.TopMenuHeightLevel == 1 || this.TopMenuHeightLevel == 2)
          this.$store.commit('updateTopMenuHeightLevel', 3)
      }
      this.currentPage = 1
    },
    goPrev() {
      if (this.currentPage > 1) this.currentPage -= 1
      this.setSingleUrlUserIDBaseCurrentPage()
    },
    goNext() {
      if (this.currentPage < this.pageCount) this.currentPage += 1
      this.setSingleUrlUserIDBaseCurrentPage()
    },
    setSingleUrlUserIDBaseCurrentPage() {
      // console.log(`[setSingleUrlUserIDBaseCurrentPage] selectedUsers:`, this.selectedUsers.map((u) => u.id))
      // NxN 切換成 1x1 時, 1x1 須顯示 NxN 某 page 的第一個設備
      if (this.currentPage < 0) return

      const deviceIdx = this.currentPage * this.pageSize - (this.pageSize - 1) - 1
      if (deviceIdx < 0) return

      const dev = this.selectedUsers[deviceIdx]
      if (!dev) return

      this.$store.commit('updateSingleUrlUserID', dev.id)
      // console.log(`[setSingleUrlUserIDBaseCurrentPage] dev.id:`, dev.id)
    },
    onPageResort() {
      if (this.selectedUsers.length <= 1) return

      const tmpSelectedUsers = this.selectedUsers.map((user) => {
        const isConnected =
          this.connectionList.find(
            ({ userAccount }) => userAccount === user.id
          ) !== undefined
            ? true
            : false
        const isLive =
          this.liveList.find(({ id }) => id === user.id) !== undefined
            ? true
            : false

        return { ...user, isConnected, isLive }
      })

      tmpSelectedUsers.forEach((tmpUser, idx) => {
        const { isLive } = tmpUser

        if (!isLive) {
          // 找最後一個 live(connected) 遞補在沒有影像的位置 (用補洞的方式)
          const pool = JSON.parse(JSON.stringify(tmpSelectedUsers))
            .splice(idx + 1)
            .reverse()
          // 先找 live
          let lastAvailableUser = pool.find((avaUser) => avaUser.isLive)
          // 再找 connected
          if (!lastAvailableUser) {
            lastAvailableUser = pool.find((avaUser) => avaUser.isConnected)
          }

          if (lastAvailableUser) {
            const dragIndex = idx
            const dropIndex = this.selectedUsers.findIndex(
              (user) => user.id === lastAvailableUser.id
            )

            if (dropIndex >= 0) {
              // swap tmpSelectedUsers
              const tmp = tmpSelectedUsers[dragIndex]
              tmpSelectedUsers[dragIndex] = tmpSelectedUsers[dropIndex]
              tmpSelectedUsers[dropIndex] = tmp
              // swap selectedUsers
              this.swapSelectedUsers({ dragIndex, dropIndex })
            }
          }
        }
      })
    },
    getUserId() {
      if(!this.liveList.length) return null
      if(!this.selectedUsers.length) return this.liveList[0].id
      for (let i = 0; i < this.selectedUsers.length; i++) {
        let live = this.liveList.find(
          (item) => item.id == this.selectedUsers[i].id
        )
        if (live) {
          return live.id
        }
      }
      return null
    },
    setSingleUserId() {
      /**
       * 如果有trackUser，表示已從左側或右側打開video，也同步設定了singleUrlUserID, 則不需重設singleUrlUserID
       * 如果trackUser = {}, liveList.length = 0
       *     ==> singleUrlUserID = null
       * 如果trackUser = {}，liveList.length > 0 
       *     ==> 比對左側帳號中第一個有串流的帳號(取groupId最小者)  ==> 設定trackUser, signleUrlUserID
       * 2022.02.09 Tracy
       */
      if (Object.keys(this.trackUser).length == 0) {
        if (this.liveList.length == 0) {
          this.$store.commit('updateSingleUrlUserID', null)
        } else {
          let userId = null
          if (this.selectedUsers && this.selectedUsers.length > 0) {
            // let minGroupId = this.selectedUsers[0].groupId
            userId = this.selectedUsers[0].id
          } else {
            let minGroupId = this.liveList[0].groupId
            let liveId = this.liveList[0].id
            this.liveList.forEach(live => {
              if (live.groupId < minGroupId) {
                minGroupId = live.groupId
                liveId = live.id
              }
            })

            userId = liveId
          }
          this.$store.commit('updateSingleUrlUserID', userId)
        }
      }
    },
    noLiveEventVideoSetting() {
      if (
        this.TopMenuHeightLevel == 1 ||
        this.liveVideoUrl ||
        this.videoViewMode > 1
      )
        return

      // 下箭頭點開1/3視窗要開啟第一個有勾選並LIVE的直播，如果沒有就黑色畫面顯示"No Streaming Available”
      for (let i = 0; i < this.selectedUsers.length; i++) {
        let live = this.liveList.find(
          (item) => item.id == this.selectedUsers[i].id
        )
        if (live) {
          this.$store.commit('updateLiveEventOn', {
            sts: true,
            url: live.mseUrl,
            origin: ''
          })
          break
        }
      }

      if (this.liveVideoUrl == '') {
        this.$store.commit('updateLiveEventOn', {
          sts: true,
          url: '',
          origin: ''
        })
      }
    },
    toggleSettingBox() {
      this.showSettingBox = !this.showSettingBox
    },
    closeSettingBox() {
      this.showSettingBox = false
    },
    setCurrentPageBySingleUrlUserID () {
      const currUserIdx = this.selectedUsers.findIndex(
        (user) => user.id === this.singleUrlUserID
      )

      // get currentPage (設定 currentPage)
      if (currUserIdx >= 0) {
        this.currentPage = Math.ceil(
          (currUserIdx + 1) / Math.pow(this.videoViewMode, 2)
        )
      }
    }
  }
}
</script>

<style lang="scss">
.video-pagination .el-pager li {
  background: transparent;
  color: $color_FFF;
}

.video-pagination .btn-next {
  background: #343b5e !important;
  color: $color_FFF !important;
}

.video-pagination .btn-prev {
  background: #343b5e !important;
  color: $color_FFF !important;
}

.video-pagination .el-pager li:not(disabled).active {
  background: #4361ee;
  color: $color_FFF;
}

.video-pagination .el-pager li.btn-quickprev,
.video-pagination .el-pager li.btn-quicknext {
  color: $color_FFF;
}
</style>

<style lang="scss" scoped>
.top-bar {
  width: 100%;
  background-color: $color_39425D;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
}

.top-one {
  grid-area: start / left-close / second / end;
}

.top-two {
  grid-area: start / left-close / onethird / end;
}

.top-three {
  grid-area: start / left-close / twothird / end;
}

.top-four {
  grid-area: start / left-close / end / end;
}

.top-r-one {
  grid-area: start / left-close / second / right-open;
}

.top-r-two {
  grid-area: start / left-close / onethird / right-open;
}

.top-r-three {
  grid-area: start / left-close / twothird / right-open;
}

.top-r-four {
  grid-area: start / left-close / end / right-open;
}

.top-l-one {
  grid-area: start / left-open / second / right-close;
}

.top-l-two {
  grid-area: start / left-open / onethird / end;
}

.top-l-three {
  grid-area: start / left-open / twothird / end;
}

.top-l-four {
  grid-area: start / left-open / end / end;
}

.top-lr-one {
  grid-area: start / left-open / second / right-open;
}

.top-lr-two {
  grid-area: start / left-open / onethird / right-open;
}

.top-lr-three {
  grid-area: start / left-open / twothird / right-open;
}

.top-lr-four {
  grid-area: start / left-open / end / right-open;
}

.icon-bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: px2rem(12);
}

.r-close {
  width: calc(100% - px2rem(100));
}

.icon-bar-left {
  display: flex;
  align-items: center;
  min-height: px2rem(50);
  height: px2rem(50);
  column-gap: px2rem(16);
}

.icon-bar-right {
  display: flex;
  align-items: center;
  // column-gap: px2rem(16);
  // margin-right: 1rem;
}

.arrow-r-close {
  z-index: 1;
}

.main-content {
  height: calc(100% - px2rem(50));
}

.icon {
  width: px2rem(32);
  height: px2rem(32);
  border-radius: px2rem(8);
  background: $color_FFF_20;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: $color_FFF_50;
  }
}

.left-menu-toggle {
  position: relative;
  margin-right: px2rem(16);
  height: 60%;

  &:after {
    content: '';
    position: absolute;
    // background: $color_FFF_50;
    width: 1px;
    height: 100%;
    background: $color_FFF;
    top: 0;
    right: px2rem(-16);
  }
}

.video-step {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: px2rem(8);
  margin-right: px2rem(16);
  height: 60%;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: $color_FFF;
    top: 0;
    right: px2rem(-16);
  }

  button {
    border-radius: px2rem(8);
    width: px2rem(24);
    height: px2rem(24);
    cursor: pointer;
    background-color: $color_FFF_20;
    &:hover {
      background: $color_FFF_50;
    }
    img {
      width: px2rem(16);
      height: px2rem(20);
    }
  }
}

.video-view-mode {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: px2rem(8);
  margin-right: px2rem(16);
  height: 60%;

  img {
    cursor: pointer;
  }
}

.page-resort {
  display: flex;
  height: 60%;

  &:before {
    content: '';
    position: relative;
    // background-color: $color_FFF_50;
    width: 1px;
    height: 100%;
    background: $color_FFF;
    top: 0;
    left: px2rem(-16);
  }

  img {
    width: px2rem(24);
    height: px2rem(24);
    cursor: pointer;
  }

  &:disabled {
    img {
      cursor: unset; // not-allowed;
      opacity: 0.2;
    }
  }
}

.hide {
  opacity: 0.2;
  cursor: unset; // not-allowed;
}

.pagination {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: px2rem(8);
  margin-right: px2rem(16);
  height: 60%;
  font-size: px2rem(20);
  font-weight: 400;

  .page-item {
    color: #ffffff;
    line-height: 1;
    padding-left: px2rem(10);
  }

  .page-prev,
  .page-next {
    // margin-right: px2rem(10);
    &:disabled {
      img {
        @include disabled;
      }
    }
  }

  .line {
    padding-left: px2rem(8);
  }
  .page-item {
    &:first-of-type {
      // cursor: pointer;
    }
  }

  .disabled {
    cursor: unset; //not-allowed;
    opacity: 0.2;
  }

}

.topbar-setting {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2rem(32);
  height: px2rem(32);
  border-radius: px2rem(8);

  &:hover {
    cursor: pointer;
    background: $color_FFF_10;
  }
  &:active,
  &.active {
    background: $color_FFF_50;
  }
  img {
    width: px2rem(24);
    height: px2rem(24);
  }
}

.topbar-setting-box {
  position: absolute;
  width: px2rem(240);
  right: 0;
  top: px2rem(50);
  z-index: 4;
  padding: px2rem(16) px2rem(16) px2rem(20) px2rem(20);
  background: #282942;
  border: 1px solid #4A5C78;
  border-radius: 0rem 0rem px2rem(6) px2rem(6);
  /* transition: all 0.3s ease-in-out; */
}

.topbar-setting-box .close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: px2rem(6);
}

.topbar-setting-box .close img {
  width: px2rem(16);
  cursor: pointer;
}

.topbar-setting-box .close + div {
  margin-bottom: px2rem(20);
}
</style>
