<template>
  <div class="multiple-view-wrap">
    <div
      class="block"
      :class="[
        blockClass,
        { 'drag-over': index === dragIndex, 'drop-over': index === dropIndex }
      ]"
      v-for="(item, index) in videoList"
      :key="index"
      :draggable="draggable"
      @dragstart="dragStart(index)"
      @dragenter.prevent="dragEnter(index)"
      @dragover.prevent="dragOver"
      @drop="drop(index)"
      @mouseleave="dropEnd"
    >
      <Video
        :index="item.index"
        :userId="item.id"
        :mseUrl="item.mseUrl"
        :videoTitle="item.videoTitle"
        :isLive="item.isLive"
        :callingUserIdx="callingUserIdx"
        :disabled="callingUserIdx && callingUserIdx !== item.index"
        @test="onFunc"
        @stopdrag="onStopDrag /*拖拉音量時, 不應該整個影像視窗一起拖動*/"
        @muteAll="onMuteAll"
      ></Video>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Video from './Video.vue'

export default {
  name: 'MultipleView',
  components: { Video },
  props: ['multipleViewMode', 'currentPage'],
  data() {
    return {
      videoList: [],
      obj: {
        2: 4,
        3: 9,
        4: 16
      },
      dragIndex: '',
      dropIndex: '',
      draggable: true, // 拖拉音量時, 不應該整個影像視窗一起拖動
      callingUserIdx: null, // Number => 紀錄那的設備觸發打電話
    }
  },
  computed: {
    ...mapState([
      // 'callMode',
      'liveList',
      'selectedUsers',
      'dragUser',
      'userList'
    ]),
    blockClass() {
      if (this.multipleViewMode == 2) {
        return 'blockTwo'
      }
      if (this.multipleViewMode == 3) {
        return 'blockThree'
      }
      return 'blockFour'
    }
  },
  watch: {
    multipleViewMode() {
      this.getVideoList()
    },
    selectedUsers: {
      deep: true,
      handler() {
        this.getVideoList()
      }
    },
    // 'selectedUsers.length'() {
    //   this.getVideoList()
    // },
    'liveList.length'() {
      this.getVideoList()
    },
    currentPage() {
      this.getVideoList()
    },
    // callMode(nVal, oVal) {
    //   // 在 Video 處理
    // }
  },
  mounted() {
    this.getVideoList()
  },
  methods: {
    getVideoList() {
      this.videoList = Array(this.obj[this.multipleViewMode])
        .fill()
        .map((item, index) => {
          let idx =
            this.obj[this.multipleViewMode] * (this.currentPage - 1) + index
          if (this.selectedUsers[idx]) {
            let live = this.liveList.find(
              (item) => item.id == this.selectedUsers[idx].id
            )
            let mseUrl = live ? live.mseUrl : null
            // live = null
            // 從 userList 取得 user.index, 讓 NxN 可以通話(因為通話是認 user.index)
            let user = this.userList.find((u) => u.id === this.selectedUsers[idx].id)
            return {
              id: this.selectedUsers[idx].id,
              videoTitle: this.selectedUsers[idx].label,
              mseUrl: mseUrl,
              isLive: live ? true : false,
              index: user.index
            }
          } else return { videoTitle: '', mseUrl: null }
        })

      // console.log(`[Multi.getVideoList] videoList:`, this.videoList) // 含空的
    },
    dragStart(index) {
      if (!this.draggable) return
      this.dragIndex = this.obj[this.multipleViewMode] * (this.currentPage - 1) + index
    },
    dragEnter(index) {
      if (!this.draggable) return
      this.dropIndex = index
    },
    dragOver() {},
    swapVideo(dragIndex, dropIndex) {
      let payload = {
        dragIndex: dragIndex,
        dropIndex: dropIndex
      }
      this.$store.commit('swapSelectedUsers', payload)
      this.getVideoList()
    },
    drop(index) {
      this.dropIndex = this.obj[this.multipleViewMode] * (this.currentPage - 1) + index
      // dragIndex!==''表示在多路視窗進行互換，dragIndex, dropIndex必須對應到設備
      if (this.dragIndex !== '') {
        if (this.dragIndex < this.selectedUsers.length && this.dropIndex < this.selectedUsers.length) {
          this.swapVideo(this.dragIndex, this.dropIndex)
        } else {
          // this.$message({
          //   message: this.$t('mode_nxn_switch_hint'),
          //   type: 'warning',
          //   duration: 1600
          // })
          this.$notify({
            type: 'warning',
            message: this.$t('mode_nxn_switch_hint') // 請拖放有設備的視窗進行互換！
          })
        }
      } else {
        // check dragUser is include in selectedUsers?
        let idx = this.selectedUsers.findIndex(user => user.id === this.dragUser.id)
        if (idx >= 0 && idx !== this.dropIndex && this.dropIndex < this.selectedUsers.length) {
          this.swapVideo(idx, this.dropIndex)
        } else {
          if (this.dropIndex >= this.selectedUsers.length) {
            this.$store.commit('addSelectedUser', this.dragUser)
          } else {
            let payload = {
              insertIndex: this.dropIndex,
              user: this.dragUser
            }
            this.$store.commit('insertSelectedUser', payload)
            this.$store.commit('setDragUser', {})
          }
        }
      }
      this.dragIndex = ''
      this.dropIndex = ''
    },
    dropEnd() {
      // Dashboard - 分割畫面虛線框異常
      if (this.dragIndex != '' && this.dropIndex !== '') {
        this.dragIndex = ''
        this.dropIndex = ''
      }
    },
    onFunc(value) {
      // console.log(`[onFunc] value:`, value)
      this.draggable = !value
    },
    onStopDrag(value) {
      // console.log(`[onStopDrag] value:`, value)
      this.draggable = !value
    },
    onMuteAll(callingUserIdx) {
      this.callingUserIdx = callingUserIdx
    }
  }
}
</script>

<style lang="scss" scoped>
$GAP: 0.5rem;
.multiple-view-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: $GAP;
  height: 100%;
  box-sizing: border-box;
  // padding: px2rem(2);
  gap: $GAP;
}

.block {
  box-sizing: border-box;
  cursor: move; // fallback if grab cursor is unsupported
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  // background-color: #010101;
}

.block:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.blockTwo {
  // width: calc(100% / 2 - 10px);
  // height: calc(100% / 2 - 10px);
  width: calc((100% - $GAP * 2) / 2);
  height: calc((100% - $GAP * 2) / 2);
}

.blockThree {
  // width: calc(100% / 3 - 10px);
  // height: calc(100% / 3 - 10px);
  width: calc((100% - $GAP * 2) / 3);
  height: calc((100% - $GAP * 2) / 3);
}

.blockFour {
  // width: calc(100% / 4 - 10px);
  // height: calc(100% / 4 - 10px);
  width: calc(100% / 4 - $GAP);
  height: calc(100% / 4 - $GAP);
}

.drag-over {
  border: dashed 2px #fff;
}

.drop-over {
  border: dashed 2px #FFC600;
}
</style>
