<template>
  <portal to="ai-box-modal">
    <div class="wrap-ai-box"></div>
    <div class="container-ai-box">
      <div class="close-icon" @click="closeModal">
        <img src="@/assets/icons/clear.svg" alt="">
      </div>
        <RecognitionList />
        <RecognitionFR class="content" v-if="aiMode === 'fr'"/>
        <RecognitionSetting class="content" v-else-if="aiMode === 'lpr'" />
        <template v-else>
          <RecognitionView v-if="mode ==='view' && (taskId >= 0 || taskId === -99)" />
          <RecognitionEdit class="content" v-else-if="mode === 'edit'" />
        </template>
        <AiboxPortal v-if="showAiboxPortal" />
    </div>
  </portal>
</template>

<script>
import RecognitionFR from '@/components/AiBox/RecognitionFR.vue'
import RecognitionSetting from '@/components/AiBox/RecognitionSetting.vue'
import RecognitionList from '@/components/AiBox/RecognitionList.vue'
import RecognitionEdit from '@/components/AiBox/RecognitionEdit.vue'
import RecognitionView from '@/components/AiBox/RecognitionView.vue'
import AiboxPortal from '@/components/AiBox/AiboxPortal.vue'
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'AiBox',
  components: { RecognitionFR, RecognitionSetting, RecognitionList, RecognitionEdit, RecognitionView, AiboxPortal },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('aibox', ['aiMode', 'mode', 'taskId', 'showAiboxPortal']),
  },
  mounted() {
    // this.getUserTree()
    this.getUserTreeList()
    this.getAiBoxTasks()
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations('aibox', ['updateShowAiBox', 'updateParamAiboxPortal', 'updateShowAiboxPortal', 'resetState']),
    ...mapActions('aibox', ['getAiBoxTasks', 'getUserTree', 'getUserTreeList']),
    closeModal() {
      if (this.mode === 'edit') {
        this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'closeModal' })
        this.updateShowAiboxPortal(true)
      } else
        this.updateShowAiBox(false)
    },
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.wrap-ai-box {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000b3;
  z-index: 6;
}

.container-ai-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96.6%;
  height: 92.6%;
  background: #282942;
  border-radius: 4px;
  z-index: 6;
  display: flex;
}

.close-icon {
  width: 54px;
  height: 54px;
  background: #6E7D93;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -24px;
  top: -27px;
  z-index: 7;
  cursor: pointer;
}

.content {
  width: calc(100% - 380px);
  height: 100%;
}

.fade-enter-active {
  transition: all 5.5s;
}

.fade-leave {
  opacity: 0;
}

</style>